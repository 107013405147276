unit Zemljevid;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Web, System.DateUtils,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms,
  XData.Web.Connection, Vcl.Grids, WEBLib.Buttons, WEBLib.WebCtrls;

type
  TfrmZemljevid = class(TForm)
    wdsPotPodrobno: TXDataWebDataSet;
    dsPotPodrobno: TDataSource;
    panFooter: TPanel;
    btnSelect: TButton;
    geoLoc: TGeoLocation;
    tmrLocate: TTimer;
    btnRefresh: TButton;
    mapMain: TGoogleMaps;
    procedure WebFormCreate(Sender: TObject);
    procedure geoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
    procedure tmrLocateTimer(Sender: TObject);
    procedure btnSelectClick(Sender: TObject);
    procedure mapMain2MapInitialized(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnRefreshClick(Sender: TObject);
    procedure mapMainMapZoom(Sender: TObject; ZoomLevel: Integer);
    procedure SetTarget;
  private
    { Private declarations }
    function VrniPozicijo(LatLon: double; PremikM: integer): double;
  public
    { Public declarations }
    FNalogId: integer;

    FLat: double;
    FLon: double;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings;

procedure TfrmZemljevid.btnRefreshClick(Sender: TObject);
begin
  tmrLocateTimer(nil);
  tmrLocate.Enabled := false;
  tmrLocate.Enabled := true;
end;

procedure TfrmZemljevid.btnSelectClick(Sender: TObject);
begin
  frmMain.ShowPrevious;
end;

procedure TfrmZemljevid.mapMain2MapInitialized(Sender: TObject);
begin
  mapMain.SetZoom(AppSettings.DefaultZoom);
  tmrLocateTimer(nil);
  tmrLocate.Enabled := true;
end;

procedure TfrmZemljevid.mapMainMapZoom(Sender: TObject; ZoomLevel: Integer);
begin
//  modMain.SetVar('Location.Zoom', ZoomLevel.ToString);
  AppSettings.DefaultZoom := ZoomLevel;
end;

procedure TfrmZemljevid.SetTarget;
begin
  mapMain.AddMarker(FLat, FLon, 'Cilj');
//  mapMain.ShowDirections(0, 0, FLon, FLat);
end;

procedure TfrmZemljevid.tmrLocateTimer(Sender: TObject);
begin
  modMain.log('Refreshing geoloction...');
  geoLoc.GetGeolocation;
end;

procedure TfrmZemljevid.geoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
var Flt, Srt: string;
    vozilo: string;
begin
  mapMain.BeginUpdate;

//  mapMain.ClearCircles;
//  mapMain.AddCircle(Lat, Lon, 200, clInfoBk,0,2,0.3);
  vozilo := 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANZJREFUSEvtlNENAiEQRIdOtBOtRK1ErUQ70U60E71JmAs5ISMEfoz7d8cyj90dCBgcYbA+fgewAXABsGpsWbETWrgBIKQ1LOAVlWtnYvdJ0CYWSrP7egFS/gnAWT9GAJ4ADtNM74T0AkjnOImyAkLWIwC0Oe1OR15ZSe8KeGhCHnJlL0Dp/oQcIO0jy5wdkVGRTb8GtN7kasA2OkG9dOBS/twiCehNoo9pNX7TzxTIhc1fvj37aLNUjDDOIhc2fwmgxejhXbSbG7LNr3093Sw+1v8A27I3Nx01GW789uAAAAAASUVORK5CYII=';

  mapMain.ClearMarkers;
  mapMain.AddMarker(Lat, Lon, vozilo, modMain.VoziloRegSt);

  modMain.Log('Position: Lat='+Lat.ToString+', Lon='+Lon.ToString);

//  mapMain.SetCenter(Lat, Lon);

  mapMain.ShowDirections(Lon, Lat, FLon, FLat);

  mapMain.EndUpdate;
end;

function TfrmZemljevid.VrniPozicijo(LatLon: double; PremikM: integer): double;
var earth: double;
    pi: double;
    m: double;
begin
  earth := 6378.137;  // radij zamlje v km

  asm
    pi = Math.PI;
  end;

  m := (1 / ((2 * pi / 360) * earth)) / 1000;  // 1 meter v stopinjah

  Result := LatLon + (PremikM * m);
end;

procedure TfrmZemljevid.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  tmrLocate.Enabled := false;
  Action := caFree;
end;

procedure TfrmZemljevid.WebFormCreate(Sender: TObject);
begin
  mapMain.Options.DefaultLatitude := AppSettings.DefaultLat;
  mapMain.Options.DefaultLongitude:= AppSettings.DefaultLon;
  mapMain.Options.DefaultZoomLevel:= AppSettings.DefaultZoom;

  tmrLocate.Interval := 1000 * 10;  // v skundah

  //tmrLocate.Interval := 10;
end;

procedure TfrmZemljevid.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnRefresh := TButton.Create(Self);
  mapMain := TGoogleMaps.Create(Self);
  wdsPotPodrobno := TXDataWebDataSet.Create(Self);
  dsPotPodrobno := TDataSource.Create(Self);
  geoLoc := TGeoLocation.Create(Self);
  tmrLocate := TTimer.Create(Self);

  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnRefresh.BeforeLoadDFMValues;
  mapMain.BeforeLoadDFMValues;
  wdsPotPodrobno.BeforeLoadDFMValues;
  dsPotPodrobno.BeforeLoadDFMValues;
  geoLoc.BeforeLoadDFMValues;
  tmrLocate.BeforeLoadDFMValues;
  try
    Name := 'frmZemljevid';
    Width := 847;
    Height := 865;
    Align := alClient;
    Caption := 'Lokacije';
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 745;
    panFooter.Width := 847;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.Padding.Top := 5;
    panFooter.TabOrder := 0;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 672;
    btnSelect.Top := 10;
    btnSelect.Width := 165;
    btnSelect.Height := 107;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-xxl btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'btnSelectClick');
    btnRefresh.SetParentComponent(panFooter);
    btnRefresh.Name := 'btnRefresh';
    btnRefresh.AlignWithMargins := True;
    btnRefresh.Left := 10;
    btnRefresh.Top := 8;
    btnRefresh.Width := 102;
    btnRefresh.Height := 109;
    btnRefresh.Margins.Left := 10;
    btnRefresh.Margins.Right := 10;
    btnRefresh.Align := alLeft;
    btnRefresh.Caption := '<i class="material-icons" style="font-size:40px;">refresh</i>';
    btnRefresh.ChildOrder := 1;
    btnRefresh.ElementClassName := 'btn btn-xxl btn-primary';
    btnRefresh.ElementFont := efCSS;
    btnRefresh.HeightPercent := 100.000000000000000000;
    btnRefresh.WidthPercent := 100.000000000000000000;
    SetEvent(btnRefresh, Self, 'OnClick', 'btnRefreshClick');
    mapMain.SetParentComponent(Self);
    mapMain.Name := 'mapMain';
    mapMain.Left := 0;
    mapMain.Top := 0;
    mapMain.Width := 847;
    mapMain.Height := 745;
    mapMain.Align := alClient;
    mapMain.APIKey := 'AIzaSyAvcd34ShYOvU1IFBq4JAJ2an032gl3MFo';
    mapMain.ChildOrder := 2;
    mapMain.Options.DefaultLatitude := -34.397000000000000000;
    mapMain.Options.DefaultLongitude := 150.644000000000000000;
    SetEvent(mapMain, Self, 'OnMapLoaded', 'mapMain2MapInitialized');
    SetEvent(mapMain, Self, 'OnMapZoom', 'mapMainMapZoom');
    wdsPotPodrobno.SetParentComponent(Self);
    wdsPotPodrobno.Name := 'wdsPotPodrobno';
    wdsPotPodrobno.EntitySetName := 'PrevozniNalogi';
    wdsPotPodrobno.Connection := modMain.connServer;
    wdsPotPodrobno.SubpropsDepth := 2;
    wdsPotPodrobno.Left := 104;
    wdsPotPodrobno.Top := 216;
    dsPotPodrobno.SetParentComponent(Self);
    dsPotPodrobno.Name := 'dsPotPodrobno';
    dsPotPodrobno.DataSet := wdsPotPodrobno;
    dsPotPodrobno.Left := 104;
    dsPotPodrobno.Top := 272;
    geoLoc.SetParentComponent(Self);
    geoLoc.Name := 'geoLoc';
    geoLoc.HighPrecision := True;
    geoLoc.TimeOut := 3000;
    SetEvent(geoLoc, Self, 'OnGeolocation', 'geoLocGeolocation');
    geoLoc.Left := 104;
    geoLoc.Top := 392;
    tmrLocate.SetParentComponent(Self);
    tmrLocate.Name := 'tmrLocate';
    tmrLocate.Enabled := False;
    tmrLocate.Interval := 30000;
    SetEvent(tmrLocate, Self, 'OnTimer', 'tmrLocateTimer');
    tmrLocate.Left := 108;
    tmrLocate.Top := 328;
  finally
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnRefresh.AfterLoadDFMValues;
    mapMain.AfterLoadDFMValues;
    wdsPotPodrobno.AfterLoadDFMValues;
    dsPotPodrobno.AfterLoadDFMValues;
    geoLoc.AfterLoadDFMValues;
    tmrLocate.AfterLoadDFMValues;
  end;
end;

end.
