{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.WebCtrls;

{$DEFINE NOPP}

interface

uses
  Classes, WEBLib.Controls, WEBLib.Graphics, SysUtils, Web, JS, WEBLib.Menus;

type
  TBrowserSandboxType = (stAllowForms, stAllowModals, stAllowOrientationLock, stAllowPointerLock, stAllowPopups, stAllowPopupsToEscapeSandbox,
    stAllowPresentation, stAllowSameOrigin, stAllowScripts, stAllowTopNavigation, stAllowTopNavigationByUserActivation);

  TBrowserSandboxTypes = set of TBrowserSandboxType;

  TBrowserReferrerPolicy = (rfNone, rfNoReferrer, rfNoReferrerWhenDowngrade, rfOrigin, rfOriginWhenCrossOrigin, rfUnsafeUrl);

  TBrowserLoadEvent = procedure(Sender: TObject; AEvent: TJSEvent) of object;

  TBrowserControl = class(TCustomControl)
  private
    FURL: string;
    FSandbox: TBrowserSandboxTypes;
    FReferrerPolicy: TBrowserReferrerPolicy;
    FLoadPtr: pointer;
    FOnLoad: TBrowserLoadEvent;
    procedure SetURL(const Value: string);
    procedure SetSandbox(const Value: TBrowserSandboxTypes);
    procedure SetReferrerPolicy(const Value: TBrowserReferrerPolicy);
    procedure DoLoad(Event: TJSEvent);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElementVisual; override;
    procedure BindEvents; override;
    procedure UnBindEvents; override;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
  public
    procedure CreateInitialize; override;
    procedure Navigate(const AURL: string);
    function CurrentURL: string;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BorderStyle;
    property ReferrerPolicy: TBrowserReferrerPolicy read FReferrerPolicy write SetReferrerPolicy;
    property Sandbox: TBrowserSandboxTypes read FSandbox write SetSandbox;
    property URL: string read FURL write SetURL;
    property Visible;
    property OnClick;
    property OnDblClick;
    property OnLoad: TBrowserLoadEvent read FOnLoad write FOnLoad;
    property OnMouseDown;
    property OnMouseMove;
    property OnMouseUp;
  end;

  TWebBrowserControl = class(TBrowserControl);

  TYoutube = class(TCustomControl)
  private
    FVideoID: string;
    FAllowFullScreen: boolean;
    FAutoPlay: boolean;
    procedure SetAllowFullScreen(const Value: boolean);
  protected
    procedure SetVideoID(const Value: string);
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure UpdateElementVisual; override;
  public
    procedure CreateInitialize; override;
  published
    property Align;
    property AllowFullScreen: boolean read FAllowFullScreen write SetAllowFullScreen;
    property AutoPlay: boolean read FAutoPlay write FAutoPlay;
    property Center;
    property VideoID: string read FVideoID write SetVideoID;
  end;

  TWebYoutube = class(TYoutube);

  TMapClickEvent = procedure(Sender: TObject; Lon, Lat: double) of object;
  TMapZoomEvent = procedure(Sender: TObject; ZoomLevel: integer) of object;
  TMapMarkerClickEvent = procedure(Sender: TObject; AIndex: integer; AMarker: TJSObjectRecord) of object;
  TMapPolygonClickEvent = procedure(Sender: TObject; AIndex: integer; APolygon: TJSObjectRecord) of object;
  TMapPolylineClickEvent = procedure(Sender: TObject; AIndex: integer; APolyline: TJSObjectRecord) of object;
  TMapCircleClickEvent = procedure(Sender: TObject; AIndex: integer; ACircle: TJSObjectRecord) of object;
  TMapRectangleClickEvent = procedure(Sender: TObject; AIndex: integer; ARectangle: TJSObjectRecord) of object;
  TMapKMLClickEvent = procedure(Sender: TObject; AIndex: integer; AKML: TJSObjectRecord) of object;

  TGoogleMarkerColor = (mcDefault, mcRed, mcBlue, mcGreen, mcPurple, mcYellow);
  TGoogleMarkerShape = (msPin, msPinDot, msFlag, msBookmark, msFlagSmall, msHome, msFavorite, msStar, msCustom);
  TGoogleTravelMode = (tmDriving, tmWalking, tmBicycling, tmTransit);
  TGoogleMapType = (mtDefault, mtSatellite, mtHybrid, mtTerrain);
  TGoogleMapStyle = (mstDefault, mstNightMode, mstCustom);

  TGoogleMaps = class;

  TGoogleMapsOptions = class(TPersistent)
  private
    FOwner: TGoogleMaps;
    FCustomStyle: TStringList;
    FMapStyle: TGoogleMapStyle;
    FDefaultLongitude: Double;
    FDefaultLatitude: Double;
    FDefaultZoomLevel: Integer;
    procedure SetCustomStyle(const Value: TStringList);
    procedure SetMapStyle(const Value: TGoogleMapStyle);
    procedure SetDefaultLatitude(const Value: Double);
    procedure SetDefaultLongitude(const Value: Double);
    procedure SetDefaultZoomLevel(const Value: Integer);
  public
    constructor Create(AGoogleMaps: TGoogleMaps);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property CustomStyle: TStringList read FCustomStyle write SetCustomStyle;
    property MapStyle: TGoogleMapStyle read FMapStyle write SetMapStyle default mstDefault;
    property DefaultLatitude: Double read FDefaultLatitude write SetDefaultLatitude default -34.397;
    property DefaultLongitude: Double read FDefaultLongitude write SetDefaultLongitude default 150.644;
    property DefaultZoomLevel: Integer read FDefaultZoomLevel write SetDefaultZoomLevel default 8;
  end;

  TGoogleMapsRender = (mrRaster, mrVector);

  TGoogleMaps = class(TCustomControl)
  private
    FUpdateCount: integer;
    FReq: TJSXMLHttpRequest;
    FAPIKey: string;
    FOldAPIKey: string;
    FMap: TJSHTMLElement;
    FDirectionsService: TJSElement;
    FDirectionsDisplay: TJSElement;
    FCode: boolean;
    FBound: boolean;
    FOnMapClick: TMapClickEvent;
    FOnMapDblClick: TMapClickEvent;
    FOnMapZoom: TMapZoomEvent;
    FOnMapPan: TMapClickEvent;
    FOnGeoCoded: TMapClickEvent;
    FOnMapIdle: TNotifyEvent;
    FOnMapLoaded: TNotifyEvent;
    FOnMarkerClick: TMapMarkerClickEvent;
    FOnPolygonClick: TMapPolygonClickEvent;
    FOnPolylineClick: TMapPolylineClickEvent;
    FOnCircleClick: TMapCircleClickEvent;
    FOnRectangleClick: TMapRectangleClickEvent;
    FOnKMLClick: TMapKMLClickEvent;
    FOptions: TGoogleMapsOptions;
    FMapID: string;
    FMapRender: TGoogleMapsRender;
    procedure SetAPIKey(const Value: string);
    function GetMarker(AIndex: integer): TJSObject;
    function GetCircle(AIndex: integer): TJSObject;
    function GetRectangle(AIndex: integer): TJSObject;
    function GetPolygon(AIndex: integer): TJSObject;
    function GetPolyline(AIndex: integer): TJSObject;
    procedure SetMapID(const Value: string);
    procedure SetMapRender(const Value: TGoogleMapsRender);
  protected
    function GetMap: TJSHTMLElement;
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
    procedure SetControlCursor(const Value: TCursor); override;
    procedure BindEvents; override;

    procedure HandleMapClick(e: TJSEvent);
    procedure HandleMapDblClick(e: TJSEvent);
    procedure HandleMapPan(e: TJSEvent);
    procedure HandleMapZoom(e: TJSEvent);
    procedure HandleMapIdle(e: TJSEvent);
    procedure HandleMarkerClick(e: TJSEvent);
    procedure HandlePolygonClick(e: TJSEvent);
    procedure HandlePolylineClick(e: TJSEvent);
    procedure HandleCircleClick(e: TJSEvent);
    procedure HandleRectangleClick(e: TJSEvent);
    procedure HandleKMLClick(e: TJSEvent);
    function HandleResponse(Event: TEventListenerEvent): boolean;
    function HandleAbort(Event: TEventListenerEvent): boolean;
    function TravelModeStr(ATravelMode: TGoogleTravelMode): string;
    function DoLoaded(Event: TEventListenerEvent): boolean; virtual;
    procedure Loaded; override;
    function GoogleLoaded: boolean;
  public
    procedure CreateInitialize; override;
    procedure SetCenter(Lat, Lon: Double);
    function GetCenter(var Lat, Lon: Double): Boolean;
    function GetBounds(var NorthEastLat, NorthEastLon, SouthWestLat, SouthWestLon: Double): Boolean;
    procedure SetDoubleClickZoom(AValue: Boolean);
    procedure SetDraggable(AValue: Boolean);
    procedure SetScrollWheel(AValue: Boolean);
    procedure SetMapType(AMapType: TGoogleMapType = mtDefault);
    procedure SetZoom(Zoom: integer);
    procedure SetHeading(AHeading: double);
    procedure SetTilt(ATilt: double);
    procedure PanTo(Lat, Lon: double);
    procedure PanToBounds(Lat1, Lon1, Lat2, Lon2: double);
    procedure AddGPX(AGPX: string; AColor: TColor = clRed; AWidth: integer = 2; AOpacity: Double = 1);
    procedure AddKML(Url: string; ZoomToBounds: Boolean = true);
    procedure AddPolyLine(Points: TJSArray; AColor: TColor = clRed;AWidth: integer = 2; AOpacity: Double = 1);
    procedure AddPolygon(Points: TJSArray; AFillColor: TColor = clRed; AStrokeColor: TColor = clBlack; AWidth: Integer = 2; AOpacity: Double = 1);
    procedure AddCircle(Lat, Lon: double; Radius: Integer; AFillColor: TColor = clRed; AStrokeColor: TColor = clBlack; AWidth: Integer = 2; AOpacity: double = 1);
    procedure AddRectangle(NorthEastLat, NorthEastLon, SouthWestLat, SouthWestLon: double; AFillColor: TColor = clRed; AStrokeColor: TColor = clBlack; AWidth: integer = 2; AOpacity: double = 1);
    procedure AddMarker(Lat,Lon: Double; Title: string = ''); overload;
    procedure AddMarker(Lat,Lon: Double; Color: TGoogleMarkerColor; Title: string = ''); overload;
    procedure AddMarker(Lat,Lon: Double; Color: TColor; PinLetter: string; Title: string = ''); overload;
    procedure AddMarker(Lat,Lon: Double; PinIcon: string; Title: string; XOffset: integer = 0; YOffset: integer = 0); overload;
    procedure AddMarker(Lat,Lon: Double; Shape: TGoogleMarkerShape; Color: TColor; Title: string = ''); overload;
    procedure AddMarker(Lat,Lon: Double; Shape: TGoogleMarkerShape; Color: TColor = clRed; BorderColor: TColor = clBlack; Scale: Double = 1; CustomShape: string = ''; Title: string = ''); overload;
    procedure SetMarkerTitle(AIndex: Integer; ATitle: string);
    procedure SetMarkerLocation(AIndex: Integer; Lat, Lon: Double);
    procedure SetMarkerIcon(AIndex: Integer; Url: string);
    procedure SetCircleCenter(AIndex: Integer; Lat, Lon: Double);
    procedure SetCircleRadius(AIndex, Radius: Integer);
    procedure SetCircleColors(AIndex: Integer; AFillColor, AStrokeColor: TColor);
    procedure SetRectangleLocation(AIndex: Integer; NorthEastLat, NorthEastLon, SouthWestLat, SouthWestLon: Double);
    procedure SetRectangleColors(AIndex: Integer; AFillColor, AStrokeColor: TColor);
    procedure SetPolylineColor(AIndex: Integer; AColor: TColor);
    procedure SetPolylinePoints(AIndex: Integer; Points: TJSArray);
    procedure SetPolygonColors(AIndex: Integer; AFillColor, AStrokeColor: TColor);
    procedure SetPolygonPoints(AIndex: Integer; Points: TJSArray);
    procedure FitBounds(LatMin, LonMin, LatMax, LonMax: Double);
    procedure ClearMarkers;
    procedure ClearKMLs;
    procedure ClearPolylines;
    procedure ClearPolygons;
    procedure ClearCircles;
    procedure ClearRectangles;
    procedure ShowDirections(Source, Destination: string; ATravelMode: TGoogleTravelMode = tmDriving; WayPoints: TStringList = nil; OptimizeWayPoints: Boolean = False; AvoidHighways: Boolean = False; AvoidTolls: Boolean = False); overload;
    procedure ShowDirections(SourceLon, SourceLat, DestLon, DestLat: Double; ATravelMode: TGoogleTravelMode = tmDriving; WayPoints: TStringList = nil; OptimizeWayPoints: Boolean = False; AvoidHighways: Boolean = False; AvoidTolls: Boolean = False); overload;
    procedure ShowStreetView(Lat, Lon: Double; Heading: Integer = 0; Zoom: Integer = 0; Pitch: Integer = 0);
    procedure HideStreetView;
    procedure MoveMarker(AIndex: integer; NewLat, NewLon: double);
    procedure RemoveDirections;
    procedure RemoveMarker(AIndex: Integer);
    procedure RemovePolygon(AIndex: Integer);
    procedure RemovePolyline(AIndex: Integer);
    procedure RemoveCircle(AIndex: Integer);
    procedure RemoveRectangle(AIndex: Integer);
    procedure GeoCode(const Address: string);
    function GetCoord(Lon,Lat: double): JSValue;
    function Distance(Lon1,Lat1,Lon2,Lat2: double): double;
    function GetBBox(Lon1,Lat1,Lon2,Lat2: double): JSValue;
    property Markers[AIndex: Integer]: TJSObject read GetMarker;
    property Polygons[AIndex: Integer]: TJSObject read GetPolygon;
    property Polylines[AIndex: Integer]: TJSObject read GetPolyline;
    property Circles[AIndex: Integer]: TJSObject read GetCircle;
    property Rectangles[AIndex: Integer]: TJSObject read GetRectangle;
    property MapElement: TJSHTMLElement read GetMap;
  published
    property Align;
    property APIKey: string read FAPIKey write SetAPIKey;
    property Center;
    property MapID: string read FMapID write SetMapID;
    property MapRender: TGoogleMapsRender read FMapRender write SetMapRender default mrRaster;
    property Options: TGoogleMapsOptions read FOptions write FOptions;
    property OnGeoCoded: TMapClickEvent read FOnGeoCoded write FOnGeoCoded;
    property OnKeyDown;
    property OnKeyUp;
    property OnKeyPress;
    property OnMapClick: TMapClickEvent read FOnMapClick write FOnMapClick;
    property OnMapDblClick: TMapClickEvent read FOnMapDblClick write FOnMapDblClick;
    property OnMapIdle: TNotifyEvent read FOnMapIdle write FOnMapIdle;
    property OnMapPan: TMapClickEvent read FOnMapPan write FOnMapPan;
    property OnMapLoaded: TNotifyEvent read FOnMapLoaded write FOnMapLoaded;
    property OnMapZoom: TMapZoomEvent read FOnMapZoom write FOnMapZoom;
    property OnMarkerClick: TMapMarkerClickEvent read FOnMarkerClick write FOnMarkerClick;
    property OnPolygonClick: TMapPolygonClickEvent read FOnPolygonClick write FOnPolygonClick;
    property OnPolylineClick: TMapPolylineClickEvent read FOnPolylineClick write FOnPolylineClick;
    property OnCircleClick: TMapCircleClickEvent read FOnCircleClick write FOnCircleClick;
    property OnRectangleClick: TMapRectangleClickEvent read FOnRectangleClick write FOnRectangleClick;
    property OnKMLClick: TMapKMLClickEvent read FOnKMLClick write FOnKMLClick;
  end;

  TWebGoogleMaps = class(TGoogleMaps);

  TLeafletMaps = class;

  TLeafletMarkerEvent = procedure(Sender: TObject; Lon, Lat: double; ID: string) of object;

  TLeafletZoom = (lzTopLeft, lzTopRight, lzBottomLeft, lzBottomRight, lzNone);

  TLeafletMapsOptions = class(TPersistent)
  private
    FOwner: TLeafletMaps;
    FDefaultLongitude: Double;
    FZoomControl: TLeafletZoom;
    FDefaultZoomLevel: Integer;
    FDefaultLatitude: Double;
    procedure SetDefaultLatitude(const Value: Double);
    procedure SetDefaultLongitude(const Value: Double);
    procedure SetDefaultZoomLevel(const Value: Integer);
    procedure SetZoomControl(const Value: TLeafletZoom);

  public
    constructor Create(AOwner: TLeafletMaps);
    procedure Assign(Source: TPersistent); override;

  published
    property ZoomControl: TLeafletZoom read FZoomControl write SetZoomControl default lzTopLeft;
    property DefaultLatitude: Double read FDefaultLatitude write SetDefaultLatitude default -34.397;
    property DefaultLongitude: Double read FDefaultLongitude write SetDefaultLongitude default 150.644;
    property DefaultZoomLevel: Integer read FDefaultZoomLevel write SetDefaultZoomLevel default 13;
  end;

  TLeafletMaps = class(TCustomControl)
  private
    FMap: TJSHTMLElement;
    FMapObject: JSValue;
    FMarkerGroup: JSValue;
    FZoomControl: JSValue;
    FCircles: TList;
    FRectangles: TList;
    FPolygons: TList;
    FPolyLines: TList;
    FMapClickPtr: pointer;
    FMapDblClickPtr: pointer;
    FMapPanPtr: pointer;
    FMapZoomPtr: pointer;
    FMarkerClickPtr: pointer;
    FOnMapClick: TMapClickEvent;
    FOnMapDblClick: TMapClickEvent;
    FOnMapPan: TMapClickEvent;
    FOnMapZoom: TMapZoomEvent;
    FOnMarkerClick: TLeafletMarkerEvent;
    FOptions: TLeafletMapsOptions;
    procedure SetOptions(const Value: TLeafletMapsOptions);
  protected
    function GetMap: TJSHTMLElement;
    function CreateElement: TJSElement; override;
    procedure BindEvents; override;
    procedure InitScript; override;

    function HandleDoMouseDown(Event: TJSMouseEvent): Boolean; override;
    function HandleDoMouseUp(Event: TJSMouseEvent): Boolean; override;
    function HandleDoMouseMove(Event: TJSMouseEvent): Boolean; override;

    procedure DoHandleMapClick(AEvent: JSValue);
    procedure DoHandleMapDblClick(AEvent: JSValue);
    procedure DoHandleMapPan(AEvent: JSValue);
    procedure DoHandleMapZoom(AEvent: JSValue);
    procedure DoHandleMarkerclick(AEvent: JSValue);
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
    procedure SetBoundsInt(X, Y, AWidth, AHeight: Integer); override;
    procedure SetZoomControl(const Value: TLeafletZoom);
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure SetCenter(Lat, Lon: Double);
    function GetCenter(var Lat, Lon: Double): Boolean;
    procedure SetZoom(Zoom: integer);
    procedure AddMarker(Lat,Lon: Double; ID: string = ''; Title: string = ''; Popup: string = ''); overload;
    procedure ClearMarkers;
    procedure AddPolyLine(Points: TJSArray; AColor: TColor = clRed;AWidth: integer = 2; AOpacity: Double = 1);
    procedure AddPolygon(Points: TJSArray; AFillColor: TColor = clRed; AStrokeColor: TColor = clBlack; AWidth: Integer = 2; AOpacity: Double = 1);
    procedure AddCircle(Lat, Lon: double; Radius: Integer; AFillColor: TColor = clRed; AStrokeColor: TColor = clBlack; AWidth: Integer = 2; AOpacity: double = 1);
    procedure AddRectangle(NorthEastLat, NorthEastLon, SouthWestLat, SouthWestLon: double; AFillColor: TColor = clRed; AStrokeColor: TColor = clBlack; AWidth: integer = 2; AOpacity: double = 1);
    procedure ClearPolylines;
    procedure ClearPolygons;
    procedure ClearCircles;
    procedure ClearRectangles;
    procedure LatLonToXY(Lat, Lon: double; var X, Y: integer);
    procedure XYToLatLon(X, Y: integer; var Lat, Lon: double);
    function Distance(Lon1,Lat1,Lon2,Lat2: double): double;
  published
    property Center;
    property Options: TLeafletMapsOptions read FOptions write SetOptions;
    property OnKeyUp;
    property OnKeyDown;
    property OnKeyPress;
    property OnMapClick: TMapClickEvent read FOnMapClick write FOnMapClick;
    property OnMapDblClick: TMapClickEvent read FOnMapDblClick write FOnMapDblClick;
    property OnMapPan: TMapClickEvent read FOnMapPan write FOnMapPan;
    property OnMapZoom: TMapZoomEvent read FOnMapZoom write FOnMapZoom;
    property OnMarkerClick: TLeafletMarkerEvent read FOnMarkerClick write FOnMarkerClick;
    property OnMouseDown;
    property OnMouseMove;
    property OnMouseUp;
  end;

  TWebLeafletMaps = class(TLeafletMaps);

  TDriveView = (dvList, dvGrid);

  TGoogleDrive = class(TCustomControl)
  private
    FView: TDriveView;
    FFolderID: string;
    FFrameHandle: TJSElement;
    procedure SetFolderID(const Value: string);
    procedure SetView(const Value: TDriveView);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
  public
    procedure CreateInitialize; override;
  published
    property FolderID: string read FFolderID write SetFolderID;
    property View: TDriveView read FView write SetView;
  end;

  TWebGoogleDrive = class(TGoogleDrive);

  TTwitterFeed = class(TCustomControl)
  private
    FUpdatedFeed: boolean;
    FFeed: string;
    FFeedLinkText: string;
    procedure SetFeed(const Value: string);
    procedure SetFeedLinkText(const Value: string);
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
  public
    procedure CreateInitialize; override;
  published
    property Center;
    property Feed: string read FFeed write SetFeed;
    property FeedLinkText: string read FFeedLinkText write SetFeedLinkText;
    property Visible;
  end;

  TWebTwitterFeed = class(TTwitterFeed);

  THTMLDiv = class(TCustomControl)
  private
    FHTML: TStrings;
    FOldText: string;
    procedure SetHTML(const Value: TStrings);
  protected
    procedure UpdateElementData; override;
    procedure HTMLChanged(Sender: TObject);
    function CreateElement: TJSElement; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Center;
    property ChildOrder;
    property DragMode;
    property ElementClassName;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property HeightPercent;
    property HeightStyle;
    property HTML: TStrings read FHTML write SetHTML;
    property Role;
    property Visible;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnTouchStart;
    property OnTouchEnd;
    property OnTouchMove;
    property OnTouchCancel;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebHTMLDiv = class(THTMLDiv);

  THTMLSpan = class(TCustomControl)
  private
    FHTML: TStrings;
    FOldText: string;
    procedure SetHTML(const Value: TStrings);
  protected
    procedure UpdateElementData; override;
    procedure HTMLChanged(Sender: TObject);
    function CreateElement: TJSElement; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Center;
    property ChildOrder;
    property DragMode;
    property ElementClassName;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property HeightPercent;
    property HeightStyle;
    property HTML: TStrings read FHTML write SetHTML;
    property Role;
    property Visible;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnTouchStart;
    property OnTouchEnd;
    property OnTouchMove;
    property OnTouchCancel;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebHTMLSpan = class(THTMLSpan);

  THTMLAnchor = class(TCustomControl)
  private
    FHref: string;
    FTarget: string;
    procedure SetHref(const Value: string);
    procedure SetTarget(const Value: string);
  protected
    procedure SetCaption(const Value: string); override;
    function HandleDoClick(Event: TJSMouseEvent): Boolean; override;
    function CreateElement: TJSElement; override;
    procedure UpdateElementData; override;
  public
    procedure CreateInitialize; override;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property Caption;
    property Center;
    property ChildOrder;
    property ElementClassName;
    property ElementFont;
    property ElementID;
    property ElementPosition;
    property HeightPercent;
    property HeightStyle;
    property HRef: string read FHref write SetHref;
    property Target: string read FTarget write SetTarget;
    property Role;
    property Visible;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnDblClick;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
  end;

  TWebHTMLAnchor = class(THTMLAnchor);

  TURLValidatorEvent = procedure(Sender: TObject; IsValid: boolean) of object;

  TURLValidator = class(TComponent)
  private
    FURL: string;
    FOnValidated: TURLValidatorEvent;
  protected
    procedure HandleResult(IsValid: boolean);
  public
    procedure Validate;
  published
    property URL: string read FURL write FURL;
    property OnValidated: TURLValidatorEvent read FOnValidated write FOnValidated;
  end;

  TWebURLValidator = class(TURLValidator);


  TJSHTMLFileArray = array of TJSHTMLFile;

  TShare = class(TComponent)
  private
    FTitle: string;
    FText: string;
    FURL: string;
  public
    function Share(ATitle,AText,AURL: string): boolean; virtual overload;
    function Share(ATitle,AText,AURL: string; AFiles: TJSHTMLFileArray): boolean; virtual overload;
    function CanShareFiles: boolean;
    function Execute: boolean;
  published
    property Title: string read FTitle write FTitle;
    property Text: string read FText write FText;
    property URL: string read FURL write FURL;
  end;

  TWebShare = class(TShare);

  TDeviceOrientationError = (oeDenied, oeNotSupported);

  TDeviceOrientationEvent = procedure(Sender: TObject; Heading: double) of object;
  TDeviceOrientationErrorEvent = procedure(Sender: TObject; AError: TDeviceOrientationError) of object;

  TDeviceOrientation = class(TComponent)
  private
    FOnHeadingChange: TDeviceOrientationEvent;
    FOnInitialized: TNotifyEvent;
    FOnError: TDeviceOrientationErrorEvent;
    FStarted: boolean;
  protected
    FOrientationPtr: pointer;
    function DoHandleOrientationEvent(e: TJSEvent): boolean;
  public
    function Enabled: boolean;
    procedure Start;
    property Started: boolean read FStarted;
  published
    property OnHeadingChange: TDeviceOrientationEvent read FOnHeadingChange write FOnHeadingChange;
    property OnInitialized: TNotifyEvent read FOnInitialized write FOnInitialized;
    property OnError: TDeviceOrientationErrorEvent read FOnError write FOnError;
  end;

  TWebDeviceOrientation = class(TDeviceOrientation);

  TSpeechSynthesis = class(TComponent)
  private
    FVolume: single;
    FPitch: single;
    FRate: single;
    FVoices: TStrings;
    FOnVoicesReady: TNotifyEvent;
    FVoice: string;
    procedure SetVolume(const Value: single);
    procedure SetPitch(const Value: single);
    procedure SetRate(const Value: single);
    procedure SetVoice(const Value: string);
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Speak(AText: string);
    procedure Cancel;
    function GetVoices: TStrings;
    property Voices: TStrings read FVoices;
    function IsSpeaking: boolean;
    function Supported: boolean;
  published
    property Pitch: single read FPitch write SetPitch;
    property Rate: single read FRate write SetRate;
    property Voice: string read FVoice write SetVoice;
    property Volume: single read FVolume write SetVolume;
    property OnVoicesReady: TNotifyEvent read FOnVoicesReady write FOnVoicesReady;
  end;

  TWebSpeechSynthesis = class(TSpeechSynthesis);

  TConsoleLog = class(TWebCustomControl)
  private
    FLog: TJSHTMLElement;
    FInit: boolean;
    FAutoScroll: boolean;
  protected
    function CreateElement: TJSElement; override;
    procedure UpdateElement; override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure Clear;
  published
    property AutoScroll: boolean read FAutoScroll write FAutoScroll default true;
  end;

  TWebConsoleLog = class(TConsoleLog);

function GPXToCoordinates(AGPXData: string): TJSArray;

implementation

uses
  WEBLib.Dialogs, WEBLib.WebTools;

const
  TWITTERSCRIPTID = 'twitterscriptid';
  LMapNotCreated = 'Map not created';


{ TYouTube }

function TYoutube.CreateElement: TJSElement;
var
//  LLabel: TJSHTMLElement;
  LDiv: TJSHTMLElement;
begin
  if (csDesigning in ComponentState) then
  begin
    LDiv := TJSHTMLElement(document.createElement('DIV'));
    {
    LLabel := TJSHTMLElement(document.createElement('DIV'));
    LLabel.innerHTML := 'TWebYouTube';
    BorderStyle := bsSingle;
    LLabel['align'] := 'center';
    LLabel.style.setProperty('border','1px solid gray');
    LLabel.style.setProperty('vertical-align','middle');
    LLabel.style.setProperty('display','table-cell');
    Result.appendChild(LLabel);
    }

    LDiv.style.setProperty('background-color','slategray');
    LDiv.style.setProperty('background-repeat','no-repeat');
    LDiv.style.setProperty('background-position-y','center');
    LDiv.style.setProperty('background-image',
       'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAuNDc5OTk5OTU5NDY4ODQyIDE2OS43MDk4Njkz'+
       'ODQ3NjYgMTE5LjA4NTYyODA5MjI4OSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PG'+
       'RlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJkZWYwIiB4MT0iMC41IiB4Mj0iMC41IiB5MT0iMSIgeTI9IjMuMTAwNzJFLTA2Ij48c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiNG'+
       'RjAwMDAiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNGRjZCMDAiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48Zz48cGF0aCBkPSJNMzEuMDI5MywxLjc3NzM0QzEzLj'+
       'E0MjcsMi45OTQ2Nyw0LjE1NDY3LDYuMTIsMS40ODgsMjguNzkzM0wxLjQ4OCwyOC43OTMzQzAuNTA2NjY3LDM3LjEzNiwwLjAwOTMzMzMzLDQ4LjQwMTMsMCw1OS42OTg3TDAsNjAu'+
       'MzQ1M0MwLjAwOTMzMzMzLDcxLjYyOTMsMC41MDQsODIuODg5MywxLjQ4OCw5MS4yNTA3TDEuNDg4LDkxLjI1MDdDNC4xNTQ2NywxMTMuOTI3LDEzLjE0MjcsMTE3LjA1MiwzMS4wMj'+
       'kzLDExOC4yNjdMMzEuMDI5MywxMTguMjY3QzU2LjQ3NzMsMTIwLDExMy4yNTEsMTE5Ljk5NywxMzguNjgsMTE4LjI2N0wxMzguNjgsMTE4LjI2N0MxNTYuNTY4LDExNy4wNTIsMTY1'+
       'LjU1NSwxMTMuOTI3LDE2OC4yMjMsOTEuMjUwN0wxNjguMjIzLDkxLjI1MDdDMTcwLjIwMyw3NC40MTczLDE3MC4yMDgsNDUuNjg2NywxNjguMjIzLDI4Ljc5MzNMMTY4LjIyMywyOC'+
       '43OTMzQzE2NS41NTUsNi4xMTg2OCwxNTYuNTY4LDIuOTk0NjcsMTM4LjY4LDEuNzc3MzRMMTM4LjY4LDEuNzc3MzRDMTI1Ljk2MywwLjkxMjAxLDEwNS4zOTcsMC40ODAwMDMsODQu'+
       'ODQsMC40ODAwMDNMODQuODQsMC40ODAwMDNDNjQuMjkyLDAuNDc4Njc2LDQzLjc1MDcsMC45MTIwMSwzMS4wMjkzLDEuNzc3MzR6IiBmaWxsPSJ1cmwoI2RlZjApIi8+PHBhdGggZD'+
       '0iTTEwOC40MjUsNjAuMDIyN0w2Mi4yNDE5LDkxLjk0NjcgNjIuMjQxOSwyOC4wOTg3IDEwOC40MjUsNjAuMDIyN3oiIGZpbGw9IiNGRkZGRkYiLz48L2c+PC9zdmc+)');
    LDiv.style.setProperty('background-position-x','center');
    LDiv.style.setProperty('background-size','128px');
    Result := LDiv;
  end
  else
  begin
    Result := document.createElement('IFRAME');
    Result.setAttribute('id', GetID);
    Result.setAttribute('frameborder','0');
  end;
end;

procedure TYoutube.UpdateElement;
var
  FURL: string;
begin
  inherited;

  if IsUpdating then
    Exit;

  if (csDesigning in ComponentState) then
  begin
    if Assigned(ElementHandle) then
    begin
      TJSHTMLElement(ElementHandle).style.setProperty('background-color','slategray');
    end;
  end;

  if Assigned(Container) then
  begin
    if FVideoID <> '' then
    begin
      FURL := 'https://www.youtube.com/embed/' + FVideoID;
      if AutoPlay then
        FURL := FURL + '?autoplay=1';
      Container.setAttribute('src', FURL);
    end;
    if FAllowFullScreen then
      Container.setAttribute('allowfullscreen','allowfullscreen')
    else
      Container.removeAttribute('allowfullscreen');
  end;
end;

procedure TYoutube.UpdateElementVisual;
begin
  inherited;

  if IsUpdating then
    Exit;

  if (csDesigning in ComponentState) then
  begin
    if Assigned(ElementHandle) then
    begin
      TJSHTMLElement(ElementHandle).style.setProperty('background-color','slategray');
    end;
  end;

end;

procedure TYoutube.CreateInitialize;
begin
  inherited;
  Width := 400;
  Height := 300;
end;

procedure TYoutube.SetAllowFullScreen(const Value: boolean);
begin
  FAllowFullScreen := Value;
  UpdateElement;
end;

procedure TYoutube.SetVideoID(const Value: string);
begin
  FVideoID := Value;
  UpdateElement;
end;

{ TGoogleMaps }

{$HINTS OFF}
procedure TGoogleMaps.AddPolyLine(Points: TJSArray; AColor: TColor = clRed; AWidth: integer = 2; AOpacity: double = 1);
var
  map: TJSHTMLElement;
  clr: string;
  ptr: Pointer;
begin
  ptr := @HandlePolylineClick;
  map := GetMap;
  clr := ColorToHTML(AColor);

  asm
    var poly = new google.maps.Polyline({
      map: map,
  	  path: Points,
	    strokeColor: clr,
  	  strokeOpacity: AOpacity,
  	  strokeWeight: AWidth
  	});

    poly.idx = this.FPolylines.length;
    poly.addListener('click', function() { ptr(poly); } );
    this.FPolylines.push(poly);
  end;
end;

procedure TGoogleMaps.AddRectangle(NorthEastLat, NorthEastLon, SouthWestLat,
  SouthWestLon: double; AFillColor, AStrokeColor: TColor; AWidth: integer;
  AOpacity: double);
var
  map: TJSHTMLElement;
  clrF,clrS: string;
  ptr: Pointer;
begin
  ptr := @HandleRectangleClick;
  map := GetMap;
  clrF := ColorToHTML(AFillColor);
  clrS := ColorToHTML(AStrokeColor);

  asm
    var NELatLng = {lat: NorthEastLat, lng: NorthEastLon};
    var SWLatLng = {lat: SouthWestLat, lng: SouthWestLon};
    var rect = new google.maps.Rectangle({
      map: map,
  	  bounds: new google.maps.LatLngBounds(NELatLng, SWLatLng),
      fillColor: clrF,
      fillOpacity: AOpacity,
	    strokeColor: clrS,
  	  strokeOpacity: AOpacity,
  	  strokeWeight: AWidth
  	});

    rect.idx = this.FRectangles.length;
    rect.addListener('click', function() { ptr(rect); } );
    this.FRectangles.push(rect);
  end;
end;

procedure TGoogleMaps.AddPolygon(Points: TJSArray; AFillColor: TColor = clRed; AStrokeColor: TColor = clBlack; AWidth: integer = 2; AOpacity: double = 1);
var
  map: TJSHTMLElement;
  clrF,clrS: string;
  ptr: Pointer;
begin
  ptr := @HandlePolygonClick;
  map := GetMap;
  clrF := ColorToHTML(AFillColor);
  clrS := ColorToHTML(AStrokeColor);

  asm
    var poly = new google.maps.Polygon({
      map: map,
  	  paths: Points,
      fillColor: clrF,
      fillOpacity: AOpacity,
	    strokeColor: clrS,
  	  strokeOpacity: AOpacity,
  	  strokeWeight: AWidth
  	});

    poly.idx = this.FPolygons.length;
    poly.addListener('click', function() { ptr(poly); } );
    this.FPolygons.push(poly);
  end;
end;

procedure TGoogleMaps.AddCircle(Lat, Lon: double; Radius: Integer;
  AFillColor, AStrokeColor: TColor; AWidth: integer; AOpacity: double);
var
  map: TJSHTMLElement;
  clrF,clrS: string;
  ptr: Pointer;
begin
  ptr := @HandleCircleClick;
  map := GetMap;
  clrF := ColorToHTML(AFillColor);
  clrS := ColorToHTML(AStrokeColor);

  asm
    var myLatLng = {lat: Lat, lng: Lon};
    var circle = new google.maps.Circle({
      map: map,
  	  center: myLatLng,
      radius: Radius,
      fillColor: clrF,
      fillOpacity: AOpacity,
	    strokeColor: clrS,
  	  strokeOpacity: AOpacity,
  	  strokeWeight: AWidth
  	});

    circle.idx = this.FCircles.length;
    circle.addListener('click', function() { ptr(circle); } );
    this.FCircles.push(circle);
  end;
end;

procedure TGoogleMaps.AddMarker(Lat, Lon: double; Title: string = '');
var
  map: TJSHTMLElement;
  ptr: Pointer;
begin
  ptr := @HandleMarkerClick;
  map := GetMap;
  asm
    var myLatLng = {lat: Lat, lng: Lon};
    var marker = new google.maps.Marker({
          position: myLatLng,
          map: map,
          title: Title
        });
    marker.idx = this.FMarkers.length;
    marker.addListener('click', function() { ptr(marker); } );
    this.FMarkers.push(marker);
  end;
end;

procedure TGoogleMaps.AddMarker(Lat, Lon: double; Color: TGoogleMarkerColor; Title: string);
var
  map: TJSHTMLElement;
  clr: string;
  ptr: Pointer;
begin
  ptr := @HandleMarkerClick;

  case Color of
  mcDefault: clr := '';
  mcRed: clr := 'red-dot.png';
  mcGreen: clr := 'green-dot.png';
  mcYellow: clr := 'yellow-dot.png';
  mcPurple: clr := 'purple-dot.png';
  mcBlue: clr := 'blue-dot.png';
  end;

  map := GetMap;
  asm
    var myLatLng = {lat: Lat, lng: Lon};

    var marker = new google.maps.Marker({
          position: myLatLng,
          map: map,
          title: Title
        });

    if (clr != "") {
      marker.setIcon('http://maps.google.com/mapfiles/ms/icons/' + clr);
    }
    marker.idx = this.FMarkers.length;
    marker.addListener('click', function() { ptr(marker); } );

    this.FMarkers.push(marker);
  end;
end;

procedure TGoogleMaps.AddMarker(Lat, Lon: double; Color: TColor; PinLetter: string; Title: string);
var
  map: TJSHTMLElement;
  clr,url: string;
  ch: string;
  ptr: Pointer;
begin
  ptr := @HandleMarkerClick;

  clr := ColorToHTML(Color);
  delete(clr,1,1);
  map := GetMap;
  asm
    var myLatLng = {lat: Lat, lng: Lon};

    url = "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld="+ PinLetter + "|" + clr;

    var pinImage = new google.maps.MarkerImage(url);

    var marker = new google.maps.Marker({
          position: myLatLng,
          map: map,
          title: Title,
          icon: pinImage
        });
    marker.idx = this.FMarkers.length;
    marker.addListener('click', function() { ptr(marker); } );

    this.FMarkers.push(marker);
  end;
end;

procedure TGoogleMaps.AddMarker(Lat, Lon: double; PinIcon, Title: string; XOffset: integer = 0; YOffset: integer = 0);
var
  map: TJSHTMLElement;
  ptr: Pointer;
begin
  ptr := @HandleMarkerClick;

  map := GetMap;
  asm
    var pinImage = new google.maps.MarkerImage(PinIcon, null, null,new google.maps.Point(XOffset, YOffset));
    var myLatLng = {lat: Lat, lng: Lon};
    var marker = new google.maps.Marker({
          position: myLatLng,
          map: map,
          title: Title,
          icon: pinImage
        });
    marker.idx = this.FMarkers.length;
    marker.addListener('click', function() { ptr(marker); } );

    this.FMarkers.push(marker);
  end;
end;

procedure TGoogleMaps.AddMarker(Lat, Lon: double; Shape: TGoogleMarkerShape;
  Color: TColor; Title: string);
 begin
   AddMarker(Lat, Lon, Shape, Color, clBlack, 1, Title);
 end;

procedure TGoogleMaps.AddGPX(AGPX: string; AColor: TColor; AWidth: integer;
  AOpacity: double);
var
  Points: TJSArray;
  LatMax,LonMax,LatMin,LonMin: double;
begin
  Points := TJSArray.new;
  asm
    var parser = new DOMParser();
    var doc = parser.parseFromString(AGPX, 'text/xml');
    var trk = doc.getElementsByTagName("trk")[0];
    var name = trk.getElementsByTagName("name")[0];
    var trkseg = doc.getElementsByTagName("trkseg")[0];
    var i = trkseg.getElementsByTagName("trkpt").length;
    var trkpt;
    var j;
    var lat;
    var lon;
    var bounds = new google.maps.LatLngBounds ();

    for (j= 0; j < i; j++)
    {
      trkpt = trkseg.getElementsByTagName("trkpt")[j];
      lat = trkpt.getAttribute('lat');
      lon = trkpt.getAttribute('lon');

      var p = new google.maps.LatLng(lat, lon);
  	  Points.push(p);
      bounds.extend(p);
    }
    LatMax = bounds.getNorthEast().lat();
    LonMax = bounds.getNorthEast().lng();

    LatMin = bounds.getSouthWest().lat();
    LonMin = bounds.getSouthWest().lng();
  end;

  AddPolyLine(Points, AColor, AWidth, AOpacity);
  FitBounds(LatMin, LonMin, LatMax, LonMax);
end;

procedure TGoogleMaps.AddKML(Url: string; ZoomToBounds: Boolean);
var
  map: TJSHTMLElement;
  clr: string;
  ptr: Pointer;
begin
  ptr := @HandleKMLClick;
  map := GetMap;

  asm
    var kml = new google.maps.KmlLayer(Url, {
      map: map,
      clickable: true,
      preserveViewport: !ZoomToBounds
  	});

    kml.idx = this.FKMLs.length;
    kml.addListener('click', function() { ptr(kml); } );
    this.FKMLs.push(kml);
  end;
end;

procedure TGoogleMaps.AddMarker(Lat, Lon: double; Shape: TGoogleMarkerShape;
  Color: TColor; BorderColor: TColor; Scale: Double; CustomShape: string; Title: string);
var
  map: TJSHTMLElement;
  clr: string;
  bclr: string;
  pth: string;
  scl: string;
  ptr: pointer;
begin
  ptr := @HandleMarkerClick;
  map := GetMap;
  clr := ColorToHTML(Color);
  bclr := ColorToHTML(BorderColor);
  scl := StringReplace(FloatToStr(Scale), ',', '.', []);

  case Shape of
  msPin: pth:= 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z';
  msPinDot: pth := 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0';
  msFlag:  pth := 'M 0,0 -1,-2 V -43 H 1 V -2 z M 1,-40 H 30 V -20 H 1 z';
  msBookmark: pth := 'M17 3h-10c-1.1 0-1.99.9-1.99 2l-.01 16 7-3 7 3v-16c0-1.1-.9-2-2-2z';
  msFlagSmall: pth := 'M14.4 6l-.4-2h-9v17h2v-7h5.6l.4 2h7v-10z';
  msHome: pth := 'M10 20v-6h4v6h5v-8h3l-10-9-10 9h3v8z';
  msFavorite: pth := 'M12 21.35l-1.45-1.32c-5.15-4.67-8.55-7.75-8.55-11.53 0-3.08 2.42-5.5 5.5-5.5 1.74 0 3.41.81 4.5 2.09 1.09-1.28 2.76-2.09 4.5-2.09 3.08 0 5.5 2.42 5.5 5.5 0 3.78-3.4 6.86-8.55 11.54l-1.45 1.31z';
  msStar: pth := 'M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z';
  msCustom: pth := CustomShape;
  end;

  asm
    function pinSymbol(color, bcolor, scale) {
    return {
        path: pth,
        fillColor: color,
        fillOpacity: 1,
        strokeColor: bcolor,
        strokeWeight: 1,
        scale: parseFloat(scale),
        };
    }
    var myLatLng = {lat: Lat, lng: Lon};
    var marker = new google.maps.Marker({
          position: myLatLng,
          map: map,
          title: Title,
          icon: pinSymbol(clr, bclr, scl)
        });
    marker.idx = this.FMarkers.length;
    marker.addListener('click', function() { ptr(marker); } );

    this.FMarkers.push(marker);
  end;
end;

procedure TGoogleMaps.BindEvents;
var
  map: TJSHTMLElement;
  ptr1,ptr2,ptr3,ptr4,ptr5: pointer;
  dirsvc, dirdispl: TJSElement;
begin
  inherited;

  if IsUpdating then
    Exit;

  map := GetMap;

  if Assigned(map) and not FBound and not (csDesigning in ComponentState) then
  begin
    ptr1 := @HandleMapClick;
    ptr2 := @HandleMapDblClick;
    ptr3 := @HandleMapPan;
    ptr4 := @HandleMapZoom;
    ptr5 := @HandleMapIdle;

    asm
      this.FMarkers = [];
      this.FPolygons = [];
      this.FPolylines = [];
      this.FCircles = [];
      this.FRectangles = [];
      this.FKMLs = [];
    end;


    asm
      dirsvc = new google.maps.DirectionsService;
      dirdispl = new google.maps.DirectionsRenderer;
      dirdispl.setMap(map);
      map.addListener('click', ptr1);
      map.addListener('dblclick', ptr2);
      map.addListener('center_changed',ptr3);
      map.addListener('zoom_changed',ptr4);
      map.addListener('tilesloaded',ptr5);
    end;

    FDirectionsService := dirsvc;
    FDirectionsDisplay := dirdispl;

    FBound := true;
  end;
end;
{$HINTS ON}


procedure TGoogleMaps.ClearCircles;
begin
  asm
    for (var i = 0; i < this.FCircles.length; i++) {
          this.FCircles[i].setMap(null);
          }
    this.FMarkers = [];
  end;
end;

procedure TGoogleMaps.ClearKMLs;
begin
  asm
    for (var i = 0; i < this.FKMLs.length; i++) {
          this.FKMLs[i].setMap(null);
          }
    this.FKMLs = [];
  end;
end;

procedure TGoogleMaps.ClearMarkers;
begin
  asm
    for (var i = 0; i < this.FMarkers.length; i++) {
          this.FMarkers[i].setMap(null);
          }
    this.FMarkers = [];
  end;
end;

procedure TGoogleMaps.ClearPolygons;
begin
  asm
    for (var i = 0; i < this.FPolygons.length; i++) {
          this.FPolygons[i].setMap(null);
          }
    this.FPolyLines = [];
  end;
end;

procedure TGoogleMaps.ClearPolyLines;
begin
  asm
    for (var i = 0; i < this.FPolylines.length; i++) {
          this.FPolylines[i].setMap(null);
          }
    this.FPolyLines = [];
  end;
end;

procedure TGoogleMaps.ClearRectangles;
begin
  asm
    for (var i = 0; i < this.FRectangles.length; i++) {
          this.FRectangles[i].setMap(null);
          }
    this.FRectangles = [];
  end;
end;

function TGoogleMaps.CreateElement: TJSElement;
begin
  FDirectionsService := nil;
  FDirectionsDisplay := nil;

  Result := document.createElement('DIV');
  Result.setAttribute('id', GetID);
  FMap := nil;
end;

procedure TGoogleMaps.CreateInitialize;
begin
  inherited;
  FOptions := TGoogleMapsOptions.Create(Self);
  Width := 400;
  Height := 300;
end;

function TGoogleMaps.Distance(Lon1, Lat1, Lon2, Lat2: double): double;
begin
  Result := 0;
  asm
    var R = 3958.8; // Radius of the Earth in miles
    var rlat1 = Lat1 * (Math.PI/180); // Convert degrees to radians
    var rlat2 = Lat2 * (Math.PI/180); // Convert degrees to radians
    var difflat = rlat2-rlat1; // Radian difference (latitudes)
    var difflon = (Lon2-Lon1) * (Math.PI/180); // Radian difference (longitudes)
    var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
    Result = d;
  end;
end;

function TGoogleMaps.DoLoaded(Event: TEventListenerEvent): boolean;
begin
  BindEvents;
  Result := true;

  Options.SetMapStyle(Options.MapStyle);

  if Cursor <> crDefault then
    SetControlCursor(Cursor);

  if Assigned(OnMapLoaded) then
    OnMapLoaded(Self);
end;

{$HINTS OFF}
procedure TGoogleMaps.FitBounds(LatMin, LonMin, LatMax, LonMax: double);
var
  map: TJSHTMLElement;
begin
  map := GetMap;

  if Assigned(map) and GoogleLoaded then
  begin
    asm
      var ne = new google.maps.LatLng(LatMax, LonMax);
      var sw = new google.maps.LatLng(LatMin, LonMin);
      var bounds = new google.maps.LatLngBounds(sw,ne);
      map.fitBounds(bounds);
    end;
  end;
end;

procedure TGoogleMaps.Loaded;
begin
  inherited;
end;

procedure TGoogleMaps.MoveMarker(AIndex: integer; NewLat, NewLon: double);
var
  marker: JSValue;
begin
  marker := Markers[AIndex];
  asm
    var latlng = new google.maps.LatLng(NewLat,NewLon);
    marker.setPosition(latlng);
  end;
end;
{$HINTS ON}


procedure TGoogleMaps.GeoCode(const Address: string);
var
  FURL: string;
begin
  FReq := TJSXMLHttpRequest.new;
  FReq.addEventListener('load', @HandleResponse);
  FReq.addEventListener('abort',@HandleAbort);

  FURL := 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURIComponent(Address) +'&key=' + APIKey;
  FReq.open('GET', FURL);
  FReq.send;
end;

{$HINTS OFF}
procedure TGoogleMaps.PanTo(Lat, Lon: double);
var
  map: TJSHTMLElement;
begin
  map := GetMap;
  if Assigned(map) and GoogleLoaded then
  begin
    asm
      map.panTo(new google.maps.LatLng(Lat, Lon));
    end;
  end;
end;

procedure TGoogleMaps.PanToBounds(Lat1, Lon1, Lat2, Lon2: double);
var
  bounds: JSValue;
  map: TJSHTMLElement;
begin
  map := GetMap;
  if Assigned(map) and GoogleLoaded then
  begin
    bounds := GetBBox(Lon1,Lat1,Lon2,Lat2);
    asm
      map.panToBounds(bounds);
    end;
  end;
end;

function TGoogleMaps.GetMap: TJSHTMLElement;
var
  map: TJSHTMLElement;
  id: string;
begin
  if Assigned(FMap) then
    Result := FMap
  else
  begin
    map := nil;

    id := GetID;
    asm
      var el = document.getElementById(id);
      if (el != null) {
        map = el.gMap;
      }
    end;

    Result := map;
    if Assigned(map) then
      FMap := Result;
  end;
end;

function TGoogleMaps.GetBounds(var NorthEastLat, NorthEastLon, SouthWestLat,
  SouthWestLon: Double): Boolean;
var
  neLon, neLat, swLon, swLat: String;
begin
  Result := false;
  NorthEastLon := -1;
  NorthEastLat := -1;
  SouthWestLon := -1;
  SouthWestLat := -1;

  asm
    neLon = map.getBounds().getNorthEast().lng().toString();
    neLat = map.getBounds().getNorthEast().lat().toString();
    swLon = map.getBounds().getSouthWest().lng().toString();
    swLat = map.getBounds().getSouthWest().lat().toString();
  end;

  if (neLon <> '') and (neLat <> '') and (swLon <> '') and (swLat <> '') then
  begin
    NorthEastLon := StrToFloat(swLon);
    NorthEastLat := StrToFloat(swLat);
    SouthWestLon := StrToFloat(swLon);
    SouthWestLat := StrToFloat(swLat);
    Result := true;
  end;
end;

function TGoogleMaps.GetBBox(Lon1, Lat1, Lon2, Lat2: double): JSValue;
var
  SW,NE: JSValue;
begin
  SW := GetCoord(Lon1,Lat1);
  NE := GetCoord(Lon2,Lat2);

  asm
    Result = new google.maps.LatLngBounds(SW,NE);
  end;
end;

function TGoogleMaps.GetCenter(var Lat, Lon: Double): Boolean;
var
  sLon, sLat: String;
begin
  Result := false;
  Lon := -1;
  Lat := -1;

  asm
    sLon = map.getCenter().lng().toString();
    sLat = map.getCenter().lat().toString();
  end;

  if (sLon <> '') and (sLat <> '') then
  begin
    Lon := StrToFloat(sLon);
    Lat := StrToFloat(sLat);
    Result := true;
  end;
end;

function TGoogleMaps.GetCircle(AIndex: integer): TJSObject;
var
  res: TJSObject;
begin
  asm
    res = this.FCircles[AIndex];
  end;

  Result := res;
end;

function TGoogleMaps.GetCoord(Lon, Lat: double): JSValue;
begin
  asm
    Result = new google.maps.LatLng(Lat,Lon);
  end;
end;

function TGoogleMaps.GetMarker(AIndex: integer): TJSObject;
var
  res: TJSObject;
begin
  asm
    res = this.FMarkers[AIndex];
  end;

  Result := res;
end;

function TGoogleMaps.GetPolygon(AIndex: integer): TJSObject;
var
  res: TJSObject;
begin
  asm
    res = this.FPolygon[AIndex];
  end;

  Result := res;
end;

function TGoogleMaps.GetPolyline(AIndex: integer): TJSObject;
var
  res: TJSObject;
begin
  asm
    res = this.FPolyline[AIndex];
  end;

  Result := res;
end;

function TGoogleMaps.GetRectangle(AIndex: integer): TJSObject;
var
  res: TJSObject;
begin
  asm
    res = this.FGetRectangle[AIndex];
  end;

  Result := res;
end;

function TGoogleMaps.GoogleLoaded: boolean;
var
  res: boolean;
begin
  res := false;

  asm
    if (typeof google === 'object' && typeof google.maps === 'object')
    {
      res = true;
    }
  end;

  Result := res;
end;

function TGoogleMaps.HandleAbort(Event: TEventListenerEvent): boolean;
begin
  //
  Result := true;
end;

procedure TGoogleMaps.HandleCircleClick(e: TJSEvent);
var
  idx: integer;
  LObjRec: TJSObjectRecord;
begin
  asm
    idx = e.idx;
  end;

  if Assigned(OnCircleClick) then
  begin
    LObjRec.jsobject := TJSObject(e);
    OnCircleClick(Self, idx, LObjRec);
  end;
end;

procedure TGoogleMaps.HandleKMLClick(e: TJSEvent);
var
  idx: integer;
  LObjRec: TJSObjectRecord;
begin
  asm
    idx = e.idx;
  end;

  if Assigned(OnKMLClick) then
  begin
    LObjRec.jsobject := TJSObject(e);
    OnKMLClick(Self, idx, LObjRec);
  end;
end;

procedure TGoogleMaps.HandleRectangleClick(e: TJSEvent);
var
  idx: integer;
  LObjRec: TJSObjectRecord;
begin
  asm
    idx = e.idx;
  end;

  if Assigned(OnRectangleClick) then
  begin
    LObjRec.jsobject := TJSObject(e);
    OnRectangleClick(Self, idx, LObjRec);
  end;
end;

procedure TGoogleMaps.HandleMapClick(e: TJSEvent);
var
  lon,lat: double;
begin
  asm
    lon = e.latLng.lng();
    lat = e.latLng.lat();
  end;

  if Assigned(OnMapClick) then
    OnMapClick(Self, lon, lat);
end;

procedure TGoogleMaps.HandleMapDblClick(e: TJSEvent);
var
  lon,lat: double;
begin
  asm
    lon = e.latLng.lng();
    lat = e.latLng.lat();
  end;

  if Assigned(OnMapDblClick) then
    OnMapDblClick(Self, lon, lat);
end;

procedure TGoogleMaps.HandleMapIdle(e: TJSEvent);
begin
  if Assigned(OnMapIdle) then
    OnMapIdle(Self);
end;

procedure TGoogleMaps.HandleMapPan(e: TJSEvent);
var
  lon,lat: double;
begin
  asm
    var c = map.getCenter();
    lon = c.lng();
    lat = c.lat();
  end;

  if Assigned(OnMapPan) then
    OnMapPan(Self, lon, lat);
end;

procedure TGoogleMaps.HandleMapZoom(e: TJSEvent);
var
  map: TJSHTMLElement;
  zoom: integer;
begin
  map := GetMap;
  asm
    zoom = map.getZoom();
  end;
  if Assigned(OnMapZoom) then
    OnMapZoom(Self, zoom);
end;

procedure TGoogleMaps.HandleMarkerClick(e: TJSEvent);
var
  idx: integer;
  LObjRec: TJSObjectRecord;
begin
  asm
    idx = e.idx;
  end;

  if Assigned(OnMarkerClick) then
  begin
    LObjRec.jsobject := TJSObject(e);
    OnMarkerClick(Self, idx, LObjRec);
  end;
end;


procedure TGoogleMaps.HandlePolygonClick(e: TJSEvent);
var
  idx: integer;
  LObjRec: TJSObjectRecord;
begin
  asm
    idx = e.idx;
  end;

  if Assigned(OnPolygonClick) then
  begin
    LObjRec.jsobject := TJSObject(e);
    OnPolygonClick(Self, idx, LObjRec);
  end;
end;

procedure TGoogleMaps.HandlePolylineClick(e: TJSEvent);
var
  idx: integer;
  LObjRec: TJSObjectRecord;
begin
  asm
    idx = e.idx;
  end;

  if Assigned(OnPolylineClick) then
  begin
    LObjRec.jsobject := TJSObject(e);
    OnPolylineClick(Self, idx, LObjRec);
  end;
end;

function TGoogleMaps.HandleResponse(Event: TEventListenerEvent): boolean;
var
  fnd: boolean;
  reslat, reslon: double;
begin
  Result := true;
  fnd := false;

  asm
    var s = Event.target.responseText;
    var js = JSON.parse(s);
    if (js.status == "OK") {
      fnd = true;
      var lat = js.results[0].geometry.location.lat;
      var lng = js.results[0].geometry.location.lng;
      reslon = lng;
      reslat = lat;
    }
  end;

  if fnd and Assigned(OnGeoCoded) then
  begin
    OnGeoCoded(Self, reslon, reslat);
  end;
end;

procedure TGoogleMaps.RemoveDirections;
var
  dirdispl: TJSElement;
begin
  if not Assigned(FDirectionsDisplay) then
    BindEvents;

  dirdispl := FDirectionsDisplay;

  asm
    dirdispl.setMap(null);
  end;
end;
{$HINTS ON}


procedure TGoogleMaps.SetAPIKey(const Value: string);
begin
  if FAPIKey <> Value then
  begin
    FAPIKey := Value;
    UpdateElement;
  end;
end;

{$HINTS OFF}
procedure TGoogleMaps.SetCenter(Lat, Lon: double);
var
  map: TJSHTMLElement;
begin
  map := GetMap;
  if Assigned(map) then
  begin
    asm
      map.setCenter(new google.maps.LatLng(Lat, Lon));
    end;
  end;
end;

procedure TGoogleMaps.SetDoubleClickZoom(AValue: Boolean);
var
  map: TJSHTMLElement;
begin
  map := GetMap;
  if Assigned(map) then
  begin
    asm
      map.setOptions( {disableDoubleClickZoom:!AValue} );
    end;
  end;
end;

procedure TGoogleMaps.SetDraggable(AValue: Boolean);
var
  map: TJSHTMLElement;
begin
  map := GetMap;
  if Assigned(map) then
  begin
    asm
      map.setOptions( {draggable:AValue} );
    end;
  end;
end;

procedure TGoogleMaps.SetHeading(AHeading: double);
var
  map: TJSHTMLElement;
begin
  map := GetMap;
  if Assigned(map) then
  begin
    asm
      map.setHeading(AHeading);
    end;
  end;
end;

procedure TGoogleMaps.SetMapID(const Value: string);
begin
  if FMapID <> Value then
  begin
    FMapID := Value;
    UpdateElement;
  end;
end;

procedure TGoogleMaps.SetMapRender(const Value: TGoogleMapsRender);
begin
  if FMapRender <> Value then
  begin
    FMapRender := Value;
    UpdateElement;
  end;
end;

procedure TGoogleMaps.SetMapType(AMapType: TGoogleMapType);
var
  map: TJSHTMLElement;
  smt: string;
begin
  map := GetMap;

  case AMapType of
    mtDefault: smt := 'roadmap';
    mtSatellite: smt := 'satellite';
    mtHybrid: smt := 'hybrid';
    mtTerrain: smt := 'terrain';
  end;

  asm
    map.setMapTypeId(smt);
  end;
end;

procedure TGoogleMaps.RemoveCircle(AIndex: Integer);
begin
  asm
    if (AIndex < this.FPolygons.length)
    {
      this.FCircles[AIndex].setMap(null);
      this.FCircles.splice(AIndex, 1);
    }
  end;
end;


procedure TGoogleMaps.RemoveMarker(AIndex: Integer);
begin
  asm
    if (AIndex < this.FMarkers.length)
    {
      this.FMarkers[AIndex].setMap(null);
      this.FMarkers.splice(AIndex, 1);
    }
  end;
end;

procedure TGoogleMaps.RemovePolygon(AIndex: Integer);
begin
  asm
    if (AIndex < this.FPolygons.length)
    {
      this.FPolygons[AIndex].setMap(null);
      this.FPolygons.splice(AIndex, 1);
    }
  end;
end;

procedure TGoogleMaps.RemovePolyline(AIndex: Integer);
begin
  asm
    if (AIndex < this.FPolylines.length)
    {
      this.FPolylines[AIndex].setMap(null);
      this.FPolylines.splice(AIndex, 1);
    }
  end;
end;

procedure TGoogleMaps.RemoveRectangle(AIndex: Integer);
begin
  asm
    if (AIndex < this.FPolygons.length)
    {
      this.FRectangles[AIndex].setMap(null);
      this.FRectangles.splice(AIndex, 1);
    }
  end;
end;

procedure TGoogleMaps.SetMarkerIcon(AIndex: Integer; Url: string);
begin
  asm
    if (AIndex < this.FMarkers.length)
    {
      this.FMarkers[AIndex].setIcon(Url);
    }
  end;
end;

procedure TGoogleMaps.SetMarkerLocation(AIndex: Integer; Lat, Lon: Double);
begin
  asm
    if (AIndex < this.FMarkers.length)
    {
      this.FMarkers[AIndex].setPosition(new google.maps.LatLng(Lat, Lon));
    }
  end;
end;

procedure TGoogleMaps.SetMarkerTitle(AIndex: Integer; ATitle: string);
begin
  asm
    if (AIndex < this.FMarkers.length)
    {
      this.FMarkers[AIndex].setTitle(ATitle);
    }
  end;
end;

procedure TGoogleMaps.SetPolygonColors(AIndex: Integer; AFillColor,
  AStrokeColor: TColor);
var
  clrF, clrS: string;
begin
  clrF := ColorToHTML(AFillColor);
  clrS := ColorToHTML(AStrokeColor);
  asm
    if (AIndex < this.FPolygons.length)
    {
      this.FPolygons[AIndex].setOptions({ fillColor: clrF, strokeColor: clrS });
    }
  end;
end;

procedure TGoogleMaps.SetPolygonPoints(AIndex: Integer; Points: TJSArray);
begin
  asm
    if (AIndex < this.FPolygons.length)
    {
      this.FPolygons[AIndex].setOptions({ path: Points });
    }
  end;
end;

procedure TGoogleMaps.SetPolylineColor(AIndex: Integer; AColor: TColor);
var
  clr: string;
begin
  clr := ColorToHTML(AColor);
  asm
    if (AIndex < this.FPolylines.length)
    {
      this.FPolylines[AIndex].setOptions({ strokeColor: clr });
    }
  end;
end;

procedure TGoogleMaps.SetPolylinePoints(AIndex: Integer; Points: TJSArray);
begin
  asm
    if (AIndex < this.FPolylines.length)
    {
      this.FPolylines[AIndex].setOptions({ path: Points });
    }
  end;
end;

procedure TGoogleMaps.SetRectangleColors(AIndex: Integer; AFillColor,
  AStrokeColor: TColor);
var
  clrF, clrS: string;
begin
  clrF := ColorToHTML(AFillColor);
  clrS := ColorToHTML(AStrokeColor);
  asm
    if (AIndex < this.FRectangles.length)
    {
      this.FRectangles[AIndex].setOptions({ fillColor: clrF, strokeColor: clrS });
    }
  end;
end;

procedure TGoogleMaps.SetRectangleLocation(AIndex: Integer; NorthEastLat,
  NorthEastLon, SouthWestLat, SouthWestLon: double);
begin
  asm
    if (AIndex < this.FRectangles.length)
    {
      var NELatLng = {lat: NorthEastLat, lng: NorthEastLon};
      var SWLatLng = {lat: SouthWestLat, lng: SouthWestLon};
      this.FRectangles[AIndex].setOptions({ bounds: new google.maps.LatLngBounds(NELatLng, SWLatLng) });
    }
  end;
end;

procedure TGoogleMaps.SetCircleCenter(AIndex: Integer; Lat, Lon: Double);
begin
  asm
    if (AIndex < this.FCircles.length)
    {
      this.FCircles[AIndex].setCenter(new google.maps.LatLng(Lat, Lon));
    }
  end;
end;

procedure TGoogleMaps.SetCircleColors(AIndex: Integer; AFillColor,
  AStrokeColor: TColor);
var
  clrF, clrS: string;
begin
  clrF := ColorToHTML(AFillColor);
  clrS := ColorToHTML(AStrokeColor);
  asm
    if (AIndex < this.FCircles.length)
    {
      this.FCircles[AIndex].setOptions({ fillColor: clrF, strokeColor: clrS });
    }
  end;
end;

procedure TGoogleMaps.SetCircleRadius(AIndex, Radius: Integer);
begin
  asm
    if (AIndex < this.FCircles.length)
    {
      this.FCircles[AIndex].setRadius(Radius);
    }
  end;
end;

procedure TGoogleMaps.SetControlCursor(const Value: TCursor);
var
  map: TJSHTMLElement;
  s: string;
begin
  inherited;

  map := GetMap;
  if Value = crDefault then
    s := ''''''
  else
    s := GetHTMLCursorName(Value);

  if Assigned(map) then
  begin
    asm
      map.setOptions({draggableCursor: s});
    end;
  end;
end;

procedure TGoogleMaps.SetScrollWheel(AValue: Boolean);
var
  map: TJSHTMLElement;
begin
  map := GetMap;
  asm
    map.setOptions( {scrollWheel:AValue} );
  end;
end;

procedure TGoogleMaps.SetTilt(ATilt: double);
var
  map: TJSHTMLElement;
begin
  map := GetMap;
  if Assigned(map) then
  begin
    asm
      map.setTitle(ATilt);
    end;
  end;
end;

procedure TGoogleMaps.SetZoom(Zoom: Integer);
var
  map: TJSHTMLElement;
begin
  map := GetMap;
  if Assigned(map) then
  begin
    asm
      map.setZoom(Zoom);
    end;
  end;
end;

procedure TGoogleMaps.ShowDirections(SourceLon, SourceLat, DestLon,
  DestLat: double; ATravelMode: TGoogleTravelMode = tmDriving;
  WayPoints: TStringList = nil; OptimizeWayPoints: Boolean = False;
  AvoidHighways: Boolean = False; AvoidTolls: Boolean = False);
var
  dirsvc,dirdispl,map: TJSElement;
  tm: string;
  wp: TJSArray;
  I: Integer;
begin
  if not Assigned(FDirectionsDisplay) then
    BindEvents;

  dirsvc := FDirectionsService;
  dirdispl := FDirectionsDisplay;
  map := GetMap;
  tm := TravelModeStr(ATravelMode);
  wp := TJSArray.New;
  if WayPoints <> nil then
  begin
    for I := 0 to WayPoints.Count - 1 do
      wp.Push(New(['location', WayPoints[I]]));
  end;

  asm
    var sourcelatlon = new google.maps.LatLng(SourceLat, SourceLon);
    var destlatlon = new google.maps.LatLng(DestLat, DestLon);

    dirdispl.setMap(map);
    dirsvc.route({
          origin: sourcelatlon,
          destination: destlatlon,
          waypoints: wp,
          optimizeWaypoints: OptimizeWayPoints,
          avoidHighways: AvoidHighways,
          avoidTolls: AvoidTolls,
          travelMode: tm
        }, function(response, status) {
          if (status === 'OK') {
            dirdispl.setDirections(response);
          } else {
            window.alert('Directions request failed due to ' + status);
          }
        });
  end;
end;

procedure TGoogleMaps.HideStreetView;
var
  map: TJSElement;
begin
  map := GetMap;
  asm
    map.streetView.setVisible(false);
  end;
end;

procedure TGoogleMaps.ShowStreetView(Lat, Lon: Double; Heading, Zoom,
  Pitch: Integer);
var
  map: TJSElement;
  mapid: string;
begin
  map := GetMap;
  mapid := GetID;
  asm
    var LatLng = {lat: Lat, lng: Lon};
    var panorama = new google.maps.StreetViewPanorama(
        document.getElementById(mapid), {
          position: LatLng,
          pov: {
            heading: Heading,
            zoom: Zoom,
            pitch: Pitch
          }
        });
    map.setStreetView(panorama);
  end;
end;

procedure TGoogleMaps.ShowDirections(Source, Destination: string;
  ATravelMode: TGoogleTravelMode; WayPoints: TStringList; OptimizeWayPoints,
  AvoidHighways, AvoidTolls: Boolean);
var
  dirsvc,dirdispl,map: TJSElement;
  tm: string;
  wp: TJSArray;
  I: Integer;
begin
  if not Assigned(FDirectionsDisplay) then
    BindEvents;

  dirsvc := FDirectionsService;
  dirdispl := FDirectionsDisplay;
  map := GetMap;
  tm := TravelModeStr(ATravelMode);
  wp := TJSArray.New;
  if WayPoints <> nil then
  begin
    for I := 0 to WayPoints.Count - 1 do
      wp.Push(New(['location', WayPoints[I]]));
  end;

  asm
    dirdispl.setMap(map);

    dirsvc.route({
          origin: Source,
          destination: Destination,
          waypoints: wp,
          optimizeWaypoints: OptimizeWayPoints,
          avoidHighways: AvoidHighways,
          avoidTolls: AvoidTolls,
          travelMode: tm
        }, function(response, status) {
          if (status === 'OK') {
            dirdispl.setDirections(response);
          } else {
            window.alert('Directions request failed due to ' + status);
          }
        });
  end;
end;

function TGoogleMaps.TravelModeStr(ATravelMode: TGoogleTravelMode): string;
begin
  Result := 'DRIVING';
  case ATravelMode of
    tmDriving: Result := 'DRIVING';
    tmWalking: Result := 'WALKING';
    tmBicycling: Result := 'BICYCLING';
    tmTransit: Result := 'TRANSIT';
  end;
end;

procedure TGoogleMaps.UpdateElement;
var
  map: TJSHTMLElement;
  id: string;
  srcurl: string;
  scriptsrc: string;
  mapidstr: string;
  mapbeta: string;
  scr,scro: TJSElement;
  sp: TJSHTMLElement;

begin
  inherited;

  if IsUpdating then
    Exit;

  if FUpdateCount > 0 then
    Exit;

  if IsLinked and (csLoading in ComponentState) then
    Exit;

  if ((not FCode) and (FAPIKey <> '')) or (FAPIKey <> FOldAPIKey) then
  begin
    FCode := true;
    id := GetID;
    FOldAPIKey := FAPIKey;

    scr := document.getElementById('scrgooglemaps');
    if Assigned(scr) then
    begin
      document.head.removeChild(scr);
    end;

    scro := document.getElementById('scrogooglemaps');
    if Assigned(scro) then
    begin
      document.head.removeChild(scro);
    end;

    if (MapRender = mrVector) and (MapID <> '') then
      mapbeta := 'v=beta&';

    mapidstr := '';
    if MapID <> '' then
      mapidstr := 'mapId:'''+MapID+''','+#13#10;

    srcurl := 'https://maps.googleapis.com/maps/api/js?'+mapbeta+'key=' + FAPIKey + '&callback=initMap'+id;

    scriptsrc := 'var gmapserror = false;'+#13#10+
         'function initMap'+id+'() { '+#13#10+
         'var el = document.getElementById("'+id+'");'+#13#10+
         'if (el == null) { alert("Google Maps DIV element not found!"); return; }'+#13#10+
         'map = new google.maps.Map(el, {'+#13#10+
         'center: {lat: ' + StringReplace(FloatToStr(Options.DefaultLatitude), ',', '.', [rfReplaceAll]) + ', lng: ' + StringReplace(FloatToStr(Options.DefaultLongitude), ',', '.', [rfReplaceAll]) + '},'+#13#10+
         mapidstr+
         'zoom: ' + IntToStr(Options.DefaultZoomLevel) + '});'+#13#10+
         'el.gMap = map;'+#13#10+
         '}';

    scr := document.createElement('script');
    scr.addEventListener('load',@DoLoaded);
    asm
      scr.defer = true;
      scr.async = true;
      scr.src = srcurl;
      scr.type = 'text/javascript';
    end;

    document.head.appendChild(scr);
    scr['id'] := 'scrgooglemaps';

    scro := document.createElement('script');
    TJSHTMLElement(scro).innerHTML := scriptsrc;
    document.head.appendChild(scro);
    scro['id'] := 'scrogooglemaps';

  end
  else if Assigned(ElementHandle) then
  begin
    ElementHandle.style.setProperty('border', '1px solid gray');
    ElementHandle.style.setProperty('background-color', '#eee');
    if (ElementHandle.childElementCount = 0) then
    begin
      sp := TJSHTMLElement(document.createElement('SPAN'));

      srcurl := '<br>Set the Google Maps JavaScript API key via WebGoogleMaps.APIKey in order to see the map.<br>'+
                'Request the key via <a href="https://developers.google.com/maps/documentation/javascript/get-api-key">this page</a>';
      sp.innerHTML := srcurl;
      ElementHandle.appendChild(sp);
    end;
  end;
end;
{$HINTS ON}

{ TTwitterFeed }

function TTwitterFeed.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

procedure TTwitterFeed.CreateInitialize;
begin
  inherited;
  Width := 400;
  Height := 300;
end;

procedure TTwitterFeed.SetFeed(const Value: string);
begin
  FFeed := Value;
  FUpdatedFeed := false;
  UpdateElement;
end;

procedure TTwitterFeed.SetFeedLinkText(const Value: string);
begin
  FFeedLinkText := Value;
  UpdateElement;
end;

{$HINTS OFF}
procedure TTwitterFeed.UpdateElement;
var
  srcurl,img: string;
  script: TJSElement;
begin
  inherited;

  if Assigned(ElementHandle) and not IsUpdating then
  begin
    srcurl := 'https://platform.twitter.com/widgets.js';

    script := document.getElementById(TWITTERSCRIPTID);

    if not Assigned(script) then
    begin
      script := document.createElement('script');
      script['id'] := TWITTERSCRIPTID;
      asm
        script.async = true;
      end;
      script['type'] := 'text/javascript';
      script['charset'] := 'utf-8';
      script['src'] := srcurl;
      document.head.appendChild(script);
    end;
  end;

  if Assigned(ElementHandle) and not IsUpdating and not FUpdatedFeed then
  begin
    if (Feed <> '') and not (csDesigning in ComponentState) then
    begin
      srcurl := '<a class="twitter-timeline" href="https://twitter.com/' + Feed +'" data-chrome="nofooter noborders">'+ FeedLinkText + '</a>';

      BorderStyle := bsNone;
      ElementHandle.innerHTML := srcurl;
      asm
        if (typeof twttr !== 'undefined') {
          twttr.widgets.load() }
      end;
      FUpdatedFeed := true;
    end
    else
    begin
      img := 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIg0KICAgd2lkdGg9IjMwMCINCiAgIGhlaWdodD0iMzAwIj4NCjxwYX'+
             'RoIGQ9Im0gMjUwLDg3Ljk3NCBjIC03LjM1OCwzLjI2NCAtMTUuMjY3LDUuNDY5IC0yMy41NjYsNi40NjEgOC40NzEsLTUuMDc4IDE0Ljk3OCwtMTMuMTE5IDE4LjA0MSwtMjIuNzAxIC03LjkyOSw0LjcwMyAtMTYuNzEsOC4xMTcgLTI2LjA1Ny'+
             'w5Ljk1NyAtNy40ODQsLTcuOTc1IC0xOC4xNDgsLTEyLjk1NyAtMjkuOTUsLTEyLjk1NyAtMjIuNjYsMCAtNDEuMDMzLDE4LjM3MSAtNDEuMDMzLDQxLjAzMSAwLDMuMjE2IDAuMzYzLDYuMzQ4IDEuMDYyLDkuMzUxIC0zNC4xMDIsLTEuNzExIC'+
             '02NC4zMzYsLTE4LjA0NyAtODQuNTc0LC00Mi44NzIgLTMuNTMyLDYuMDYgLTUuNTU2LDEzLjEwOCAtNS41NTYsMjAuNjI4IDAsMTQuMjM2IDcuMjQ0LDI2Ljc5NSAxOC4yNTQsMzQuMTUzIC02LjcyNiwtMC4yMTMgLTEzLjA1MywtMi4wNTkgLT'+
             'E4LjU4NSwtNS4xMzIgLTAuMDA0LDAuMTcxIC0wLjAwNCwwLjM0MyAtMC4wMDQsMC41MTYgMCwxOS44OCAxNC4xNDQsMzYuNDY0IDMyLjkxNSw0MC4yMzQgLTMuNDQzLDAuOTM4IC03LjA2OCwxLjQzOSAtMTAuODEsMS40MzkgLTIuNjQ0LDAgLT'+
             'UuMjE0LC0wLjI1OCAtNy43MiwtMC43MzYgNS4yMjIsMTYuMzAxIDIwLjM3NSwyOC4xNjUgMzguMzMxLDI4LjQ5NSAtMTQuMDQzLDExLjAwNiAtMzEuNzM1LDE3LjU2NSAtNTAuOTYsMTcuNTY1IC0zLjMxMiwwIC02LjU3OCwtMC4xOTQgLTkuNz'+
             'g4LC0wLjU3NCAxOC4xNTksMTEuNjQzIDM5LjcyNywxOC40MzcgNjIuODk5LDE4LjQzNyA3NS40NzMsMCAxMTYuNzQ2LC02Mi41MjQgMTE2Ljc0NiwtMTE2Ljc0NyAwLC0xLjc3OSAtMC4wNCwtMy41NDggLTAuMTE5LC01LjMwOSA4LjAxNywtNS'+
             '43ODQgMTQuOTczLC0xMy4wMTEgMjAuNDc0LC0yMS4yMzkgeiIgc3R5bGU9ImZpbGw6IzNhYWFlMSIgLz4NCjwvc3ZnPg==';

      RenderDesigning(ClassName, Container, Self, True, img);
    end;
  end;

  if Assigned(ElementHandle)  then
    ElementHandle.style.setProperty('overflow-y','auto');
end;
{$HINTS ON}

{ TBrowserControl }

procedure TBrowserControl.BindEvents;
var
  eh: TJSEventTarget;
begin
  inherited;
  if Assigned(ElementBindHandle) then
  begin
    eh := ElementBindHandle;
    eh.addEventListener('load', FLoadPtr);
  end;
end;

procedure TBrowserControl.ClearMethodPointers;
begin
  inherited;
  FLoadPtr := nil;
end;

function TBrowserControl.CreateElement: TJSElement;
begin
  Result := document.createElement('IFRAME');
end;

procedure TBrowserControl.CreateInitialize;
begin
  inherited;
  Width := 400;
  Height := 300;
end;

{$HINTS OFF}
function TBrowserControl.CurrentURL: string;
var
  url: string;
  el: TJSElement;
begin
  el := ElementHandle;

  asm
    url = el.contentWindow.location;
  end;

  Result := url;
end;

procedure TBrowserControl.DoLoad(Event: TJSEvent);
begin
  if Assigned(OnLoad) then
    OnLoad(Self, Event);
end;

procedure TBrowserControl.GetMethodPointers;
begin
  inherited;
  FLoadPtr := @DoLoad;
end;

{$HINTS ON}

procedure TBrowserControl.Navigate(const AURL: string);
begin
  URL := AURL;
end;

procedure TBrowserControl.SetReferrerPolicy(
  const Value: TBrowserReferrerPolicy);
begin
  FReferrerPolicy := Value;
  UpdateElementVisual;
end;

procedure TBrowserControl.SetSandbox(const Value: TBrowserSandboxTypes);
begin
  FSandbox := Value;
  UpdateElementVisual;
end;

procedure TBrowserControl.SetURL(const Value: string);
var
  el: TJSHTMLElement;
begin
  FURL := Value;
  el := ElementHandle;
  el.setAttribute('src',Value);
end;

procedure TBrowserControl.UnBindEvents;
var
  eh: TJSEventTarget;
begin
  inherited;
  if Assigned(ElementBindHandle) then
  begin
    eh := ElementBindHandle;
    eh.removeEventListener('load', FLoadPtr);
  end;
end;

procedure TBrowserControl.UpdateElementVisual;
var
  SandboxStr: string;

  procedure AppendStr(var s: string; appstr: string);
  begin
    if s = '' then
      s := appstr
    else
      s := s + ' ' + appstr;
  end;

begin
  inherited;


  if BorderStyle = bsSingle then
    ElementHandle.style.setProperty('border','1px solid silver')
  else
    ElementHandle.style.setProperty('border','0px');

  SandboxStr := '';

  if stAllowForms in Sandbox then
    AppendStr(SandboxStr, 'allow-forms');
  if stAllowModals in Sandbox then
    AppendStr(SandboxStr, 'allow-modals');
  if stAllowOrientationLock in Sandbox then
    AppendStr(SandboxStr, 'allow-orientation-lock');
  if stAllowPointerLock in Sandbox then
    AppendStr(SandboxStr, 'allow-pointer-lock');
  if stAllowPopups in Sandbox then
    AppendStr(SandboxStr, 'allow-popups');
  if stAllowPopupsToEscapeSandbox in Sandbox then
    AppendStr(SandboxStr, 'allow-popups-to-escape-sandbox');
  if stAllowPresentation in Sandbox then
    AppendStr(SandboxStr, 'allow-presentation');
  if stAllowSameOrigin in Sandbox then
    AppendStr(SandboxStr, 'allow-same-origin');
  if stAllowScripts in  Sandbox then
    AppendStr(SandboxStr, 'allow-scripts');
  if stAllowTopNavigation in  Sandbox then
    AppendStr(SandboxStr, 'allow-top-navigation');
  if stAllowTopNavigationByUserActivation in  Sandbox then
    AppendStr(SandboxStr, 'allow-top-navigation-by-user-activation');

  if SandboxStr <> '' then
  begin
    ElementHandle.setAttribute('sandbox',SandboxStr);
  end;

  case ReferrerPolicy of
    rfNoReferrer: ElementHandle.setAttribute('referrerpolicy','no-referrer');
    rfNoReferrerWhenDowngrade: ElementHandle.setAttribute('referrerpolicy','no-referrer-when-downgrade');
    rfOrigin: ElementHandle.setAttribute('referrerpolicy','origin');
    rfOriginWhenCrossOrigin: ElementHandle.setAttribute('referrerpolicy','origin-when-cross-origin');
    rfUnsafeUrl: ElementHandle.setAttribute('referrerpolicy','unsafe-url');
  end;
end;

{ TGoogleDrive }

function TGoogleDrive.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
  FFrameHandle := document.createElement('IFRAME');
  Result.appendChild(FFrameHandle);
  FFrameHandle.setAttribute('frameborder','0');
end;

procedure TGoogleDrive.CreateInitialize;
begin
  inherited;
  Width := 400;
  Height := 300;
end;

procedure TGoogleDrive.SetFolderID(const Value: string);
begin
  if (FFolderID <> Value) then
  begin
    FFolderID := Value;
    UpdateElement;
  end;
end;

procedure TGoogleDrive.SetView(const Value: TDriveView);
begin
  if (FView <> Value) then
  begin
    FView := Value;
    UpdateElement;
  end;
end;

procedure TGoogleDrive.UpdateElement;
var
  vs,img: string;
begin
  inherited;

  case View of
  dvList: vs := 'list';
  dvGrid: vs := 'grid';
  end;

  if Assigned(ElementHandle) then
  begin
    if FolderID <> '' then
    begin
      FFrameHandle.setAttribute('src','https://drive.google.com/embeddedfolderview?id='+ FolderID + '&embedded=true#' + vs);
      TJSHTMLElement(FFrameHandle).style.setProperty('border','0');
    end
    else
//      if (csDesigning in ComponentState) then
      begin
        img := 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGV'+
               'uYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEyOCAxMjgiIGlkPSJTb2NpYWxfSWNvbnMiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDEyOCAxMjgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1s'+
               'bnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIGlkPSJfeDMzX19zdHJva2UiPjxnIGlkPSJHb29nbGVfRHJpdmUiPjxyZWN0IGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBoZWlnaHQ9I'+
               'jEyOCIgd2lkdGg9IjEyOCIvPjxnIGlkPSJHb29nbGVfRHJpdmVfMV8iPjxwb2x5Z29uIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iIzM3NzdFMyIgZmlsbC1ydWxlPSJldmVub2RkIiBwb2ludHM9IjIxLjMzNSwxMjAgNDIuNjY2LDgyLjY2NyAxMjgsODIuNj'+
               'Y3IDEwNi42NjYsMTIwICAgICAgICAgIi8+PHBvbHlnb24gY2xpcC1ydWxlPSJldmVub2RkIiBmaWxsPSIjRkZDRjYzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHBvaW50cz0iODUuMzM1LDgyLjY2NyAxMjgsODIuNjY3IDg1LjMzNSw4IDQyLjY2Niw4ICAgICIvPjx'+
               'wb2x5Z29uIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iIzExQTg2MSIgZmlsbC1ydWxlPSJldmVub2RkIiBwb2ludHM9IjAsODIuNjY3IDIxLjMzNSwxMjAgNjQsNDUuMzMzIDQyLjY2Niw4ICAgICIvPjwvZz48L2c+PC9nPjwvc3ZnPg==';

        RenderDesigning(ClassName, Container, Self, True, Img);
      end;
  end;
end;


{ THTMLDiv }

function THTMLDiv.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

procedure THTMLDiv.CreateInitialize;
begin
  FHTML := TStringList.Create;
  FHTML.SkipLastLineBreak := true;
  (FHTML as TStringList).OnChange := HTMLChanged;

  inherited;

  ControlStyle := ControlStyle + [csAcceptsControls];
  ClipChildren := false;
  TabStop := false;

  if (csDesigning in ComponentState) then
  begin
    Width := 240;
    Height := 160;
  end;
end;

destructor THTMLDiv.Destroy;
begin
  FHTML.Free;
  inherited;
end;

procedure THTMLDiv.HTMLChanged(Sender: TObject);
begin
  UpdateElementData;
end;

procedure THTMLDiv.SetHTML(const Value: TStrings);
begin
  FHTML.Assign(Value);
end;

procedure THTMLDiv.UpdateElementData;
begin
  inherited;
  if Assigned(ElementHandle) then
  begin
    if (FOldText <> '') or (FHTML.Text <> '') then
      ElementHandle.innerHTML := FHTML.Text;
    FOldText := FHTML.Text;
  end
  else
  begin
    if not IsLinked and (csDesigning in ComponentState) then
      RenderDesigning(ClassName, Container, Self, True);
  end;
end;

{ THTMLSpan }

function THTMLSpan.CreateElement: TJSElement;
begin
  Result := document.createElement('SPAN');
end;

procedure THTMLSpan.CreateInitialize;
begin
  FHTML := TStringList.Create;
  (FHTML as TStringList).OnChange := HTMLChanged;

  inherited;

  ControlStyle := ControlStyle + [csAcceptsControls];
  TabStop := false;
  ClipChildren := false;

  if (csDesigning in ComponentState) then
  begin
    Width := 240;
    Height := 160;
  end;
end;

destructor THTMLSpan.Destroy;
begin
  FHTML.Free;
  inherited;
end;

procedure THTMLSpan.HTMLChanged(Sender: TObject);
begin
  UpdateElementData;
end;

procedure THTMLSpan.SetHTML(const Value: TStrings);
begin
  FHTML.Assign(Value);
end;

procedure THTMLSpan.UpdateElementData;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if (FOldText <> '') or (FHTML.Text <> '') then
      ElementHandle.innerHTML := FHTML.Text;
    FOldText := FHTML.Text;
  end
  else
  begin
    if not IsLinked and (csDesigning in ComponentState) then
      RenderDesigning(ClassName, Container, Self, True);
  end;
end;

{ THTMLAnchor }

function THTMLAnchor.CreateElement: TJSElement;
begin
  Result := document.createElement('A');
end;

procedure THTMLAnchor.CreateInitialize;
begin
  inherited;
  Caption := 'WebHTMLAnchor';
end;

function THTMLAnchor.HandleDoClick(Event: TJSMouseEvent): Boolean;
begin
  if Assigned(OnClick) then
  begin
    Event.preventDefault();
    StopPropagation;
    Result := false;
    OnClick(Self);
  end
  else
    Result := inherited;
end;

procedure THTMLAnchor.SetCaption(const Value: string);
begin
  inherited SetCaption(Value);
  UpdateElementData;
end;

procedure THTMLAnchor.SetHref(const Value: string);
begin
  FHref := Value;
  UpdateElementData;
end;

procedure THTMLAnchor.SetTarget(const Value: string);
begin
  FTarget := Value;
  UpdateElementData;
end;

procedure THTMLAnchor.UpdateElementData;
begin
  inherited;

  if Assigned(ElementHandle) then
  begin
    if Caption <> '' then
     ElementHandle.innerHTML := Caption;
    ElementHandle.setAttribute('href', FHref);
    ElementHandle.setAttribute('target', FTarget);
  end;
end;

{ TURLValidator }

procedure TURLValidator.HandleResult(IsValid: boolean);
begin
  if Assigned(OnValidated) then
    OnValidated(Self, IsValid);
end;

{$HINTS OFF}
procedure TURLValidator.Validate;
var
  s: string;
  ptr: pointer;
begin
  s := URL;
  ptr := @HandleResult;
  asm
    var request = new XMLHttpRequest();
    request.open('GET', s, true);
    request.onreadystatechange = function(){
       if (request.readyState === 4){
           ptr(request.status === 200);
           }
    };
    request.send();
  end;
end;
{$HINTS ON}

{ TGoogleMapsOptions }

procedure TGoogleMapsOptions.Assign(Source: TPersistent);
begin
  inherited;
  FCustomStyle := (Source as TGoogleMapsOptions).CustomStyle;
  FMapStyle := (Source as TGoogleMapsOptions).MapStyle;
  FDefaultLatitude := (Source as TGoogleMapsOptions).DefaultLatitude;
  FDefaultLongitude := (Source as TGoogleMapsOptions).DefaultLongitude;
  FDefaultZoomLevel := (Source as TGoogleMapsOptions).DefaultZoomLevel;
end;

constructor TGoogleMapsOptions.Create(AGoogleMaps: TGoogleMaps);
begin
  inherited Create;
  FCustomStyle := TStringList.Create;
  FMapStyle := mstDefault;
  FDefaultLatitude := -34.397;
  FDefaultLongitude := 150.644;
  FDefaultZoomLevel := 8;
  FOwner := AGoogleMaps;
end;

destructor TGoogleMapsOptions.Destroy;
begin
  FCustomStyle.Free;
  inherited;
end;

procedure TGoogleMapsOptions.SetCustomStyle(const Value: TStringList);
begin
  FCustomStyle.Assign(Value);
end;

procedure TGoogleMapsOptions.SetDefaultLatitude(const Value: Double);
begin
  if FDefaultLatitude <> Value then
  begin
    FDefaultLatitude := Value;
    FOwner.UpdateElement;
    FOwner.PanTo(DefaultLatitude, DefaultLongitude);
  end;
end;

procedure TGoogleMapsOptions.SetDefaultLongitude(const Value: Double);
begin
  if FDefaultLongitude <> Value then
  begin
    FDefaultLongitude := Value;
    FOwner.UpdateElement;
    FOwner.PanTo(DefaultLatitude, DefaultLongitude);
  end;
end;

procedure TGoogleMapsOptions.SetDefaultZoomLevel(const Value: Integer);
begin
  if (FDefaultZoomLevel <> Value) and (Value >= 0) and (Value <= 21) then
  begin
    FDefaultZoomLevel := Value;
    FOwner.UpdateElement;
    FOwner.SetZoom(Value);
  end;
end;


{$HINTS OFF}
procedure TGoogleMapsOptions.SetMapStyle(const Value: TGoogleMapStyle);
var
  map: TJSHTMLElement;
  cstyle: string;
  ostyle: TJSObject;
begin
  FMapStyle := Value;
  map := FOwner.GetMap;

  if not Assigned(map) then
    Exit;

  if FMapStyle = mstNightMode then
  begin
    asm
      var myStyle =
      [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#242f3e"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#746855"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#242f3e"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#263c3f"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#6b9a76"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#38414e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#212a37"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9ca5b3"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#746855"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#1f2835"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#f3d19c"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#2f3948"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#d59563"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#17263c"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#515c6d"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#17263c"
            }
          ]
        }
      ];

      map.setOptions({styles: myStyle});
    end;
  end
  else if (FMapStyle = mstCustom) and (CustomStyle.Text <> '') then
  begin
    cstyle := CustomStyle.Text;
    try
      ostyle := TJSObject(TJSJSON.Parse(cstyle));
      asm
        map.setOptions({styles: ostyle});
      end;
    except

    end;
  end
  else
  begin
    asm
      map.setOptions({styles: []});
    end;
  end;
end;
{$HINTS ON}



{ TShare }

function TShare.CanShareFiles: boolean;
var
  res: boolean;
begin
  res := false;
  asm
    if (navigator.share) {
    try
    {
      res = navigator.canShare;
    }
    catch(err)
    {
    }
    }
  end;
  Result := res;
end;

function TShare.Share(ATitle, AText, AURL: string): boolean;
var
  res: boolean;
begin
  res := false;
  asm
    if (navigator.share) {
      res = true;
      navigator.share({
        title: ATitle,
        text: AText,
        url: AURL });
    }
  end;
  Result := res;
end;

function TShare.Execute: boolean;
begin
  Result := Share(FTitle, FText, FURL);
end;

function TShare.Share(ATitle, AText, AURL: string; AFiles: TJSHTMLFileArray): boolean;
var
  res: boolean;
begin
  res := false;

  asm
  if (navigator.canShare && navigator.canShare({ files: AFiles })) {
    res = true;
    navigator.share({
    files: AFiles,
    title: ATitle,
    text: AText });
   }
  end;
  Result := res;
end;

{ TDeviceOrientation }

{$HINTS OFF}
function TDeviceOrientation.DoHandleOrientationEvent(e: TJSEvent): boolean;
var
  s: double;
  compass: JSValue;
begin
  s := 0.0;
  asm
    compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
    var d = 0.0;
    s = d + compass;
  end;

  if Assigned(OnHeadingChange) then
    OnHeadingChange(Self, s);
end;

function TDeviceOrientation.Enabled: boolean;
begin
  asm
    Result = window.DeviceOrientationEvent != null;
  end;
end;

procedure TDeviceOrientation.Start;
var
  os: TOperatingSystem;
  ptr: pointer;

  procedure HandleDeny;
  begin
    if Assigned(OnError) then
      OnError(Self, oeDenied);
  end;

  procedure HandleError;
  begin
    if Assigned(OnError) then
      OnError(Self, oeNotSupported);
  end;

  procedure HandleSuccess;
  begin
    FStarted := true;
    if Assigned(OnInitialized) then
      OnInitialized(Self);
  end;

begin
  os := GetOperatingSystem;

  FOrientationPtr := @DoHandleOrientationEvent;

  ptr := FOrientationPtr;

  if (os in [osiOS,osmacOS]) then
  begin
    asm
      if (DeviceOrientationEvent==null) return;
      if (DeviceOrientationEvent.requestPermission==null) return;

      DeviceOrientationEvent.requestPermission().then((response) => {
        if (response === "granted") {
          window.addEventListener("deviceorientation", ptr, true);
          HandleSuccess();
        } else {
          HandleDeny();
        }
      })
      .catch(() => HandleError());
    end;
  end
  else
  begin
    asm
      window.addEventListener("deviceorientationabsolute", ptr, true);
      HandleSuccess();
    end;
  end;
end;
{$HINTS ON}


{ TWebSpeechSynthesis }

procedure TSpeechSynthesis.Cancel;
begin
  asm
    window.speechSynthesis.cancel();
  end;
end;

constructor TSpeechSynthesis.Create(AOwner: TComponent);
begin
  inherited;
  FVolume := 1;
  FRate := 1;
  FPitch := 1;
  FVoices := TStringList.Create;

  if Supported then
    GetVoices;
end;

destructor TSpeechSynthesis.Destroy;
begin
  FVoices.Free;
  inherited;
end;

{$HINTS OFF}

function TSpeechSynthesis.GetVoices: TStrings;
var
  sl: TStrings;

  procedure VoicesReady;
  begin
    if Assigned(OnVoicesReady) then
      OnVoicesReady(Self);
  end;

begin
  sl := FVoices;

  if (1<0) then
    VoicesReady;

  asm
    function populateVoiceList()
    {
    	var voices = speechSynthesis.getVoices();
      // console.log(voices);
      // Loop through each of the voices.
     	voices.forEach(function(voice, i) {
        sl.Add(voice.name);
        //console.log(voice.name);
    	});
      if (voices.length > 0) { VoicesReady(); }

   }
   populateVoiceList();
   if (window.speechSynthesis.onvoiceschanged !== undefined) {
     speechSynthesis.onvoiceschanged = populateVoiceList;
   }
  end;
end;

function TSpeechSynthesis.IsSpeaking: boolean;
var
  res: boolean;
begin
  asm
    res = window.speechSynthesis.speaking;
  end;

  Result := res;
end;

procedure TSpeechSynthesis.SetPitch(const Value: single);
begin
  if (Value >=0) and (Value <= 10) then
    FPitch := Value;
end;

procedure TSpeechSynthesis.SetRate(const Value: single);
begin
  if (Value >= 0) and (Value <= 2) then
   FRate := Value;
end;

procedure TSpeechSynthesis.SetVoice(const Value: string);
begin
  FVoice := Value;
end;

procedure TSpeechSynthesis.SetVolume(const Value: single);
begin
  if (Value >= 0) and (Value <= 1) then
    FVolume := Value;
end;

procedure TSpeechSynthesis.Speak(AText: string);
var
  v,r,p: single;
  avoice: string;
begin
  v := FVolume;
  r := FRate;
  p := FPitch;
  avoice := FVoice;
  asm
    // Create a new instance of SpeechSynthesisUtterance.
  	var msg = new SpeechSynthesisUtterance();

    // Set the text.
  	msg.text = AText;

    // Set the attributes.
	  msg.volume = v;
  	msg.rate = r;
  	msg.pitch = p;

    // If a voice has been selected, find the voice and set the
    // utterance instance's voice attribute.
  	if (avoice != "") {
  		msg.voice = speechSynthesis.getVoices().filter(function(voice) { return voice.name == avoice; })[0];
  	}

    // Queue this utterance.
	  window.speechSynthesis.speak(msg);
  end;
end;

function TSpeechSynthesis.Supported: boolean;
var
  res: boolean;
begin
  res := false;
  asm
    if ('speechSynthesis' in window) { res = true; }
  end;
  Result := res;
end;
{$HINTS ON}

{ TConsoleLog }

procedure TConsoleLog.Clear;
begin
  FLog.innerHTML := '';
end;

{$HINTS OFF}
function TConsoleLog.CreateElement: TJSElement;
var
  el,ellog: TJSHTMLElement;
  LAutoScroll: boolean;
begin
  LAutoScroll := FAutoScroll;
  el := TJSHTMLElement(document.createElement('DIV'));
  ellog := TJSHTMLElement(document.createElement('PRE'));
  el.appendChild(ellog);

  Result := el;
  FLog := ellog;

  if (csDesigning in ComponentState) then
    Exit;

  AddControlStyle(
    '.log-warn { color: orange }'+CRLF+
    '.log-error { color: red }'+CRLF+
    '.log-info { color: skyblue }'+CRLF+
    '.log-log { color: silver }'+CRLF+
    '.log-warn, .log-error { font-weight: bold; }'
  );

  AddControlScriptSource(
  '    function unfixLoggingFunc(name) {' + CRLF +
  '        console[name] = console["old" + name];' + CRLF +
  ' }'+CRLF+
  'function unwireLogging() {' + CRLF +
  '    unfixLoggingFunc("log");' + CRLF +
  '    unfixLoggingFunc("debug");' + CRLF +
  '    unfixLoggingFunc("warn");' + CRLF +
  '    unfixLoggingFunc("error");' + CRLF +
  '    unfixLoggingFunc("info");' + CRLF +
  '}' + CRLF +
  'function rewireLoggingToElement(eleLocator, eleOverflowLocator, autoScroll) {' + CRLF +
  '    fixLoggingFunc("log");' + CRLF +
  '    fixLoggingFunc("debug");' + CRLF +
  '    fixLoggingFunc("warn");' + CRLF +
  '    fixLoggingFunc("error");' + CRLF +
  '    fixLoggingFunc("info");' + CRLF +
  '' + CRLF +
  '    function fixLoggingFunc(name) {' + CRLF +
  '        console["old" + name] = console[name];' + CRLF +
  '        console[name] = function(...arguments) {' + CRLF +
  '            const output = produceOutput(name, arguments);' + CRLF +
  '            const eleLog = eleLocator;' + CRLF +
  '' + CRLF +
  '            if (autoScroll) {' + CRLF +
  '                const eleContainerLog = eleOverflowLocator;' + CRLF +
  '                const isScrolledToBottom = eleContainerLog.scrollHeight - eleContainerLog.clientHeight <= eleContainerLog.scrollTop + 1;' + CRLF +
  '                eleLog.innerHTML += output + "<br>";' + CRLF +
  '                if (isScrolledToBottom) {' + CRLF +
  '                    eleContainerLog.scrollTop = eleContainerLog.scrollHeight - eleContainerLog.clientHeight;' + CRLF +
  '                }' + CRLF +
  '            } else {' + CRLF +
  '                eleLog.innerHTML += output + "<br>";' + CRLF +
  '            }' + CRLF +
  '' + CRLF +
  '            console["old" + name].apply(undefined, arguments);' + CRLF +
  '        };' + CRLF +
  '    }' + CRLF +
  '' + CRLF +
  '   function produceOutput(name, args) {' + CRLF +
  '       return args.reduce((output, arg) => {' + CRLF +
  '            return output +' + CRLF +
  '                "<span class=\"log-" + (typeof arg) + " log-" + name + "\">" +' + CRLF +
  '                    (typeof arg === "object" && (JSON || {}).stringify ? JSON.stringify(arg) : arg) +' + CRLF +
  '                "</span>&nbsp;";' + CRLF +
  '        }, "");' + CRLF +
  '    }' + CRLF +
  '}'
  );

  asm
    rewireLoggingToElement(ellog, el, LAutoScroll);
  end;

  FInit := true;
end;
{$HINTS ON}

procedure TConsoleLog.CreateInitialize;
begin
  inherited;
  FAutoScroll := true;
end;

destructor TConsoleLog.Destroy;
begin
  if FInit then
  begin
    asm
      unwireLogging();
    end;
  end;

  inherited;
end;

procedure TConsoleLog.UpdateElement;
begin
  inherited;
  if Assigned(ElementHandle) then
    ElementHandle.style.setProperty('overflow','auto');
end;

{ TLeafletMaps }

{$HINTS OFF}
procedure TLeafletMaps.AddCircle(Lat, Lon: double; Radius: Integer; AFillColor,
  AStrokeColor: TColor; AWidth: Integer; AOpacity: double);
var
  fillcolor,strokecolor: string;
  circle: JSValue;
begin
  fillcolor := ColorToHTML(AFillColor);
  strokecolor := ColorToHTML(AStrokeColor);

  asm
    var center = [Lat, Lon];
    // Create and add the rectangle to the map
    circle = L.circle(center, {radius: Radius, color: strokecolor, weight: AWidth, fillcolor: fillcolor, fillopacity: AOpacity}).addTo(this.FMapObject);
  end;
  FCircles.Add(circle);
end;
{$HINTS ON}

procedure TLeafletMaps.AddMarker(Lat, Lon: Double; ID: string = ''; Title: string = ''; Popup: string = '');
begin
  if Assigned(FMarkergroup) then
  begin

  asm
    var mrkr = L.marker([Lat, Lon]).addTo(this.FMarkerGroup);
    // Optionally, you can add a popup to the marker
    if (Title != "") {
      mrkr.bindTooltip(Title); }
    if (Popup != "") {
      mrkr.bindPopup(Title); }
    mrkr.myCustomId = ID;
    mrkr.on('click', this.FMarkerClickPtr);
  end;
  end;
end;

{$HINTS OFF}
procedure TLeafletMaps.AddPolygon(Points: TJSArray; AFillColor,
  AStrokeColor: TColor; AWidth: Integer; AOpacity: Double);
var
  Fillcolor,Strokecolor: string;
  poly: JSValue;
begin
  Strokecolor := ColorToHTML(AStrokeColor);
  Fillcolor := ColorToHTML(AFillColor);
  asm
    poly = L.polygon(Points, {color: Strokecolor, fillcolor: Fillcolor, fillopacity: AOpacity, weight: AWidth}).addTo(this.FMapObject);
  end;
  FPolygons.Add(poly);
end;

procedure TLeafletMaps.AddPolyLine(Points: TJSArray; AColor: TColor;
  AWidth: integer; AOpacity: Double);
var
  strokecolor: string;
  polyline: JSValue;
begin
  strokecolor := ColorToHTML(AColor);
  asm
    polyline = L.polyline(Points, {color: strokecolor, weight: AWidth}).addTo(this.FMapObject);
  end;
  FPolylines.Add(polyline);
end;

procedure TLeafletMaps.AddRectangle(NorthEastLat, NorthEastLon, SouthWestLat,
  SouthWestLon: double; AFillColor, AStrokeColor: TColor; AWidth: integer;
  AOpacity: double);
var
  Fillcolor,Strokecolor: string;
  rect: JSValue;
begin
  Fillcolor := ColorToHTML(AFillColor);
  Strokecolor := ColorToHTML(AStrokeColor);
  asm
    var bounds = [[NorthEastLat, NorthEastLon], [SouthWestLat, SouthWestLon]];
    // Create and add the rectangle to the map
    rect = L.rectangle(bounds, {color: Strokecolor, weight: AWidth, fillcolor: Fillcolor, fillopacity: AOpacity}).addTo(this.FMapObject);
  end;
  FRectangles.Add(rect);
end;
{$HINTS OFF}

procedure TLeafletMaps.BindEvents;
begin
  inherited;
end;

procedure TLeafletMaps.ClearCircles;
var
  i: integer;
  c: JSValue;
begin
  for i := 0 to FCircles.Count - 1 do
  begin
    c := JSValue(FCircles.Items[i]);
    asm
      this.FMapObject.removeLayer(c);
    end;
  end;
end;

procedure TLeafletMaps.ClearMarkers;
begin
  asm
    this.FMarkerGroup.clearLayers();
  end;
end;

procedure TLeafletMaps.ClearMethodPointers;
begin
  inherited;
  FMapClickPtr := nil;
  FMapDblClickPtr := nil;
  FMapPanPtr := nil;
  FMapZoomPtr := nil;
  FMarkerClickPtr := nil;
end;

procedure TLeafletMaps.ClearPolygons;
var
  i: integer;
  c: JSValue;
begin
  for i := 0 to FPolygons.Count - 1 do
  begin
    c := JSValue(FPolygons.Items[i]);
    asm
      this.FMapObject.removeLayer(c);
    end;
  end;
end;

procedure TLeafletMaps.ClearPolylines;
var
  i: integer;
  c: JSValue;
begin
  for i := 0 to FPolylines.Count - 1 do
  begin
    c := JSValue(FPolylines.Items[i]);
    asm
      this.FMapObject.removeLayer(c);
    end;
  end;
end;

procedure TLeafletMaps.ClearRectangles;
var
  i: integer;
  c: JSValue;
begin
  for i := 0 to FRectangles.Count - 1 do
  begin
    c := JSValue(FRectangles.Items[i]);
    asm
      this.FMapObject.removeLayer(c);
    end;
  end;
end;

function TLeafletMaps.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
  Result.setAttribute('id', GetID);
  FMap := nil;
end;

procedure TLeafletMaps.CreateInitialize;
begin
  inherited;
  FCircles := TList.Create;
  FRectangles := TList.Create;
  FPolygons := TList.Create;
  FPolylines := TList.Create;
  FOptions := TLeafletMapsOptions.Create(Self);
end;

destructor TLeafletMaps.Destroy;
begin
  FCircles.Free;
  FRectangles.Free;
  FPolygons.Free;
  FPolylines.Free;
  FOptions.Free;
  inherited;
end;

function TLeafletMaps.Distance(Lon1, Lat1, Lon2, Lat2: double): double;
begin
  if Assigned(FMapObject) then
  begin
    asm
      var pt1 = L.latLng(Lat1,Lon1);
      var pt2 = L.latLng(Lat2,Lon2);
      Result = pt1.distanceTo(pt2);
    end;
  end
  else
    raise Exception.Create(LMapNotCreated);
end;

procedure TLeafletMaps.DoHandleMapClick(AEvent: JSValue);
var
  Lon,Lat: double;
begin
  asm
    Lat = AEvent.latlng.lat;
    Lon = AEvent.latlng.lng;
  end;

  if Assigned(OnMapClick) then
    OnMapClick(Self,Lon,Lat);
end;

procedure TLeafletMaps.DoHandleMapDblClick(AEvent: JSValue);
var
  Lon,Lat: double;
begin
  asm
    Lat = AEvent.latlng.lat;
    Lon = AEvent.latlng.lng;
  end;

  if Assigned(OnMapDblClick) then
    OnMapDblClick(Self,Lon,Lat);
end;

procedure TLeafletMaps.DoHandleMapPan(AEvent: JSValue);
var
  Lon,Lat: double;
begin
  asm
    var center = this.FMapObject.getCenter();
    Lon = center.lng;
    Lat = center.lat;
  end;

  if Assigned(OnMapPan) then
    OnMapPan(Self,Lat,Lon);
end;

procedure TLeafletMaps.DoHandleMapZoom(AEvent: JSValue);
var
  zoom: integer;
begin
  asm
    zoom = this.FMapObject.getZoom();
  end;

  if Assigned(OnMapZoom) then
    OnMapZoom(Self, zoom);
end;

procedure TLeafletMaps.DoHandleMarkerclick(AEvent: JSValue);
var
  Lon,Lat: double;
  ID: string;

begin
  asm
    Lon = AEvent.latlng.lng;
    Lat = AEvent.latlng.lat;
    ID = AEvent.target.myCustomId;
  end;

  if Assigned(OnMarkerClick) then
    OnMarkerClick(Self, Lon, Lat, ID);
end;

function TLeafletMaps.GetCenter(var Lat, Lon: Double): Boolean;
var
  la,lo: double;
begin
  Result := false;

  if not Assigned(FMapObject) then
    Exit;

  asm
    var center = this.FMapObject.getCenter();
    la = center.lat;
    lo = center.lng;
  end;

  Lat := la;
  Lon := lo;

  Result := true;
end;

function TLeafletMaps.GetMap: TJSHTMLElement;
begin
  Result := FMap;
end;

procedure TLeafletMaps.GetMethodPointers;
begin
  inherited;
  FMapClickPtr := @DoHandleMapClick;
  FMapDblClickPtr := @DoHandleMapDblClick;
  FMapPanPtr := @DoHandleMapPan;
  FMapZoomPtr := @DoHandleMapZoom;
  FMarkerClickPtr := @DoHandleMarkerClick;
end;

function TLeafletMaps.HandleDoMouseDown(Event: TJSMouseEvent): Boolean;
var
  me: TJSMouseEvent;
begin
  asm
    me = Event.originalEvent;
  end;
  if not Assigned(me) then
    inherited HandleDoMouseDown(Event);
end;

function TLeafletMaps.HandleDoMouseMove(Event: TJSMouseEvent): Boolean;
var
  me: TJSMouseEvent;
begin
  asm
    me = Event.originalEvent;
  end;

  if not Assigned(me) then
    inherited HandleDoMouseMove(Event);
end;

function TLeafletMaps.HandleDoMouseUp(Event: TJSMouseEvent): Boolean;
var
  me: TJSMouseEvent;
begin
  asm
    me = Event.originalEvent;
  end;

  if not Assigned(me) then
    inherited HandleDoMouseUp(Event);
end;

procedure TLeafletMaps.InitScript;
var
  id: string;
  el: TJSElement;
begin
  inherited;

  id := GetID;
  el := document.getElementById(id);
  if not Assigned(el) then
    Exit;

  if not Assigned(FMapObject) and Assigned(FMapZoomPtr) and Assigned(FMapPanPtr) and Assigned(FMapClickPtr) and Assigned(FMapDblClickPtr)
     and Assigned(FMarkerClickPtr) then
  begin
     asm
       this.FMapObject = L.map(id, {zoomControl: false}).setView([51.505, -0.09], 13);

       // Add an OpenStreetMap tile layer
       L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '© OpenStreetMap contributors'
       }).addTo(this.FMapObject);

       this.FMapObject.on("click", this.FMapClickPtr);
       this.FMapObject.on("dblclick", this.FMapDblClickPtr);
       this.FMapObject.on("dragend", this.FMapPanPtr);
       this.FMapObject.on("zoomend", this.FMapZoomPtr);
       this.FMarkerGroup = L.layerGroup().addTo(this.FMapObject);
       this.FZoomControl = L.control.zoom({
         position: 'topleft' // You can specify the position as 'topleft', 'topright', 'bottomleft', or 'bottomright'
         }).addTo(this.FMapObject);

       var map = this.FMapObject;

       setTimeout(function() { map.invalidateSize(); }, 200);
     end;
  end;
end;

procedure TLeafletMaps.SetBoundsInt(X, Y, AWidth, AHeight: Integer);
begin
  inherited;
  if Assigned(FMapObject) then
  begin
    asm
      this.FMapObject.invalidateSize();
    end;
  end;
end;

procedure TLeafletMaps.SetCenter(Lat, Lon: Double);
begin
  asm
    this.FMapObject.setView([Lat,Lon]);
    this.FMapObject.invalidateSize();
  end;
end;

procedure TLeafletMaps.SetOptions(const Value: TLeafletMapsOptions);
begin
  FOptions.Assign(Value);
end;

procedure TLeafletMaps.SetZoom(Zoom: integer);
begin
  asm
    this.FMapObject.setZoom(Zoom);
    this.FMapObject.invalidateSize();
  end;
end;

procedure TLeafletMaps.LatLonToXY(Lat, Lon: double; var X, Y: integer);
var
  px,py: integer;
begin
  if Assigned(FMapObject) then
  begin
    asm
      var latLng = L.latLng(Lat,Lon);
      var point = this.FMapObject.latLngToContainerPoint(latLng);
      px = point.x;
      py = point.y;
    end;

    X := px;
    Y := py;
  end
  else
    raise Exception.Create(LMapNotCreated);
end;

procedure TLeafletMaps.XYToLatLon(X, Y: integer; var Lat, Lon: double);
var
  la,lo:double;
begin
  if Assigned(FMapObject) then
  begin
    asm
      var point = L.point(X, Y);
      var latLng = this.FMapObject.containerPointToLatLng(point);
      la = latLng.lat;
      lo = latLng.lng;
    end;

    Lat := la;
    Lon := lo;
  end
  else
    raise Exception.Create(LMapNotCreated);
end;

procedure TLeafletMaps.SetZoomControl(const Value: TLeafletZoom);
var
  s: string;
begin
  if not Assigned(FMapObject) then
    Exit;

  if not Assigned(FZoomControl) then
    Exit;

  case Value of
  lzTopLeft: s := 'topleft';
  lzTopRight: s := 'topright';
  lzBottomLeft: s := 'bottomleft';
  lzBottomRight: s := 'bottomright';
  end;

  asm
    this.FZoomControl.remove();
  end;

  if Value <> lzNone then
  begin
    asm
      this.FZoomControl = L.control.zoom({
        position: s  // Choose: 'topleft', 'topright', 'bottomleft', 'bottomright'
      }).addTo(this.FMapObject);

      this.FMapObject.invalidateSize();
    end;
  end;
end;

function GPXToCoordinates(AGPXData: string): TJSArray;
begin
  Result := nil;
  asm
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(AGPXData, "application/xml");

    var trkpts = xmlDoc.getElementsByTagName("trkpt");
    if (trkpts.length == 0) {
      trkpts = xmlDoc.getElementsByTagName("rtept");
    }

    var coordinates = [];

    for (var i = 0; i < trkpts.length; i++) {
        var trkpt = trkpts[i];
        var lat = trkpt.getAttribute("lat");
        var lon = trkpt.getAttribute("lon");
        var coord = [];
        coord.push(parseFloat(lat));
        coord.push(parseFloat(lon));
        //coordinates.push({ latitude: parseFloat(lat), longitude: parseFloat(lon) });
        coordinates.push(coord);
     }
    return coordinates;
  end;
end;

{ TLeafletMapsOptions }

procedure TLeafletMapsOptions.Assign(Source: TPersistent);
begin
  if (Source is TLeafletMapsOptions) then
  begin
    FDefaultLatitude := (Source as TLeafletMapsOptions).DefaultLatitude;
    FDefaultLongitude := (Source as TLeafletMapsOptions).DefaultLongitude;
    FDefaultZoomLevel := (Source as TLeafletMapsOptions).DefaultZoomLevel;
    FZoomControl := (Source as TLeafletMapsOptions).ZoomControl;
  end;
end;

constructor TLeafletMapsOptions.Create(AOwner: TLeafletMaps);
begin
  FOwner := AOwner;
  FDefaultLatitude := -34.397;
  FDefaultLongitude := 150.644;
  FDefaultZoomLevel := 13;
  FZoomControl := lzTopLeft;
end;

procedure TLeafletMapsOptions.SetDefaultLatitude(const Value: Double);
begin
  FDefaultLatitude := Value;
  if Assigned(FOwner) then
    FOwner.SetCenter(FDefaultLatitude, FDefaultLongitude);
end;

procedure TLeafletMapsOptions.SetDefaultLongitude(const Value: Double);
begin
  FDefaultLongitude := Value;
  if Assigned(FOwner) then
    FOwner.SetCenter(FDefaultLatitude, FDefaultLongitude);
end;

procedure TLeafletMapsOptions.SetDefaultZoomLevel(const Value: Integer);
begin
  FDefaultZoomLevel := Value;
  if Assigned(FOwner) then
    FOwner.SetZoom(FDefaultZoomLevel);
end;

procedure TLeafletMapsOptions.SetZoomControl(const Value: TLeafletZoom);
begin
  FZoomControl := Value;
  if Assigned(FOwner) then
    FOwner.SetZoomControl(Value);
end;

end.
