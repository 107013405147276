unit Datum;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Web,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms,
  XData.Web.Connection, Vcl.Grids, WEBLib.Calendar, Client.Constants;

type
//  TKoledarNacin = (knPrivzeto, knZgodovina);

  TfrmDatum = class(TForm)
    tmrFind: TTimer;
    panFooter: TPanel;
    btnSelect: TButton;
    btnCancel: TButton;
    calMain: TCalendar;
    procedure edtFilterChange(Sender: TObject);
    procedure tblStrankeDblClick(Sender: TObject);
    procedure btnCancelClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    { Private declarations }
  public
    { Public declarations }
    Nacin: TKoledarNacin;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings;

procedure TfrmDatum.btnCancelClick(Sender: TObject);
begin
  frmmain.ShowVozila;
end;

procedure TfrmDatum.edtFilterChange(Sender: TObject);
begin
  tmrFind.Enabled := false;
  tmrFind.Enabled := true;
end;

procedure TfrmDatum.tblStrankeDblClick(Sender: TObject);
begin
(*  if Nacin = knZgodovina then
    modMain.NastaviDatumZgodovina(calMain.SelectedDates[0])
  else
    modMain.NastaviDatum(calMain.SelectedDates[0]);
  frmMain.ShowPrevious;       *)
end;

procedure TfrmDatum.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Action := caFree;
end;

procedure TfrmDatum.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  calMain := TCalendar.Create(Self);
  tmrFind := TTimer.Create(Self);

  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  calMain.BeforeLoadDFMValues;
  tmrFind.BeforeLoadDFMValues;
  try
    Name := 'frmDatum';
    Width := 768;
    Height := 923;
    Align := alClient;
    Color := clBtnFace;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -40;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 803;
    panFooter.Width := 768;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.TabOrder := 0;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 593;
    btnSelect.Top := 5;
    btnSelect.Width := 165;
    btnSelect.Height := 112;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'tblStrankeDblClick');
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 3;
    btnCancel.Width := 111;
    btnCancel.Height := 114;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">close</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    calMain.SetParentComponent(Self);
    calMain.Name := 'calMain';
    calMain.Left := 0;
    calMain.Top := 0;
    calMain.Width := 768;
    calMain.Height := 803;
    calMain.Align := alClient;
    calMain.ChildOrder := 1;
    calMain.TabOrder := 1;
    calMain.SelectedDate := 45397.000000000000000000;
    calMain.Year := 2024;
    calMain.Font.Charset := DEFAULT_CHARSET;
    calMain.Font.Color := clWindowText;
    calMain.Font.Height := -60;
    calMain.Font.Name := 'Tahoma';
    calMain.Font.Style := [];
    calMain.Month := 4;
    calMain.Day := 15;
    calMain.MultiSelect := False;
    calMain.NameOfMonths.January := 'Januar';
    calMain.NameOfMonths.February := 'Februar';
    calMain.NameOfMonths.March := 'Marec';
    calMain.NameOfMonths.April := 'April';
    calMain.NameOfMonths.May := 'Maj';
    calMain.NameOfMonths.June := 'Junij';
    calMain.NameOfMonths.July := 'Julij';
    calMain.NameOfMonths.August := 'Avgust';
    calMain.NameOfMonths.September := 'September';
    calMain.NameOfMonths.October := 'Oktober';
    calMain.NameOfMonths.November := 'November';
    calMain.NameOfMonths.December := 'December';
    calMain.NameOfDays.Sunday := 'Ned';
    calMain.NameOfDays.Monday := 'Pon';
    calMain.NameOfDays.Tuesday := 'Tor';
    calMain.NameOfDays.Wednesday := 'Sre';
    calMain.NameOfDays.Thursday := #268'et';
    calMain.NameOfDays.Friday := 'Pet';
    calMain.NameOfDays.Saturday := 'Sob';
    tmrFind.SetParentComponent(Self);
    tmrFind.Name := 'tmrFind';
    tmrFind.Interval := 100;
    tmrFind.Left := 108;
    tmrFind.Top := 104;
  finally
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    calMain.AfterLoadDFMValues;
    tmrFind.AfterLoadDFMValues;
  end;
end;

end.
                                                                                                                                                                                                 