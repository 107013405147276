unit Nalog;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, WEBLib.DBCtrls, WEBLib.JSON, JS,
  Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Grids, WEBLib.Controls, WEBLib.ExtCtrls,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, WEBLib.Forms,
  XData.Web.Connection, WEBLib.SignatureCapture, Vcl.Grids, Web, System.DateUtils,
  XData.Web.Client;

type

  TfrmNalog = class(TForm)
    dsPodrobno: TDataSource;
    wdsPodrobno: TXDataWebDataSet;
    dsNalog: TDataSource;
    wdsNalog: TXDataWebDataSet;
    panInfo: TPanel;
    WebLabel2: TLabel;
    lblLokacija: TDBLabel;
    WebDBLabel5: TDBLabel;
    panPosode: TPanel;
    gridPodrobno: TDBGrid;
    panSign: TPanel;
    WebLabel6: TLabel;
    signStranka: TSignatureCapture;
    panNotes: TPanel;
    WebLabel5: TLabel;
    memoNotes: TDBMemo;
    panNotesButtons: TGridPanel;
    btnOpomba1: TButton;
    btnOpomba2: TButton;
    btnOpomba3: TButton;
    btnDelNote: TButton;
    panFooter: TPanel;
    btnTransfer: TButton;
    btnCancel: TButton;
    btnNotes: TButton;
    btnSign: TButton;
    wdsNalogid2: TIntegerField;
    WebLabel1: TLabel;
    WebDBLabel2: TDBLabel;
    WebLabel3: TLabel;
    WebDBLabel4: TDBLabel;
    wdsNalogKontejnerji: TXDataWebEntityField;
    wdsNalogStranka: TXDataWebEntityField;
    wdsNalogStrankaLokacije: TXDataWebDatasetField;
    wdsNalogStrankaId: TIntegerField;
    wdsNalogStrankaZunanjaOznaka: TStringField;
    wdsNalogStrankaAktivna: TBooleanField;
    wdsNalogStrankaObracun: TBooleanField;
    wdsNalogStrankaNaziv: TStringField;
    wdsNalogStrankaNaslov: TStringField;
    wdsNalogStrankaPosta: TStringField;
    wdsNalogStrankaKraj: TStringField;
    wdsNalogStrankaDavSt: TStringField;
    wdsNalogStrankaTel: TStringField;
    wdsNalogStrankaOpombe: TStringField;
    wdsNalogStrankaMesecniObracun: TIntegerField;
    wdsNalogStatus: TXDataWebEntityField;
    wdsNalogStatusId: TIntegerField;
    wdsNalogStatusOpis: TStringField;
    wdsNalogVoznikDostava: TXDataWebEntityField;
    wdsNalogVoznikDostavaVloga: TXDataWebEntityField;
    wdsNalogVoznikDostavaVlogaId: TIntegerField;
    wdsNalogVoznikDostavaVlogaOpis: TStringField;
    wdsNalogVoznikDostavaId: TIntegerField;
    wdsNalogVoznikDostavaUporabniskoIme: TStringField;
    wdsNalogVoznikDostavaGeslo: TStringField;
    wdsNalogVoznikDostavaPIN: TStringField;
    wdsNalogVoznikDostavaIme: TStringField;
    wdsNalogVoznikDostavaPriimek: TStringField;
    wdsNalogVoznikDostavaAktiven: TBooleanField;
    wdsNalogVoznikOdvoz: TXDataWebEntityField;
    wdsNalogVoznikOdvozVloga: TXDataWebEntityField;
    wdsNalogVoznikOdvozVlogaId: TIntegerField;
    wdsNalogVoznikOdvozVlogaOpis: TStringField;
    wdsNalogVoznikOdvozId: TIntegerField;
    wdsNalogVoznikOdvozUporabniskoIme: TStringField;
    wdsNalogVoznikOdvozGeslo: TStringField;
    wdsNalogVoznikOdvozPIN: TStringField;
    wdsNalogVoznikOdvozIme: TStringField;
    wdsNalogVoznikOdvozPriimek: TStringField;
    wdsNalogVoznikOdvozAktiven: TBooleanField;
    wdsNalogVozilo: TXDataWebEntityField;
    wdsNalogVoziloTipVozila: TXDataWebEntityField;
    wdsNalogVoziloTipVozilaId: TIntegerField;
    wdsNalogVoziloTipVozilaOpis: TStringField;
    wdsNalogVoziloVrstaOdvoza: TXDataWebEntityField;
    wdsNalogVoziloVrstaOdvozaId: TIntegerField;
    wdsNalogVoziloVrstaOdvozaOpis: TStringField;
    wdsNalogVoziloVrstaOdvozaParams: TBlobField;
    wdsNalogVoziloVrstaOdvozaKolicina: TIntegerField;
    wdsNalogVoziloVrstaOdvozaVolumen: TIntegerField;
    wdsNalogVoziloVrstaOdvozaTeza: TIntegerField;
    wdsNalogVoziloOdvozi: TXDataWebDatasetField;
    wdsNalogVoziloId: TIntegerField;
    wdsNalogVoziloRegistrskaSt: TStringField;
    wdsNalogVoziloAktivno: TBooleanField;
    wdsNalogVoziloLetnik: TIntegerField;
    wdsNalogVoziloPrvaRegistracija: TDateTimeField;
    wdsNalogVoziloZnamka: TStringField;
    wdsNalogVoziloTip: TStringField;
    wdsNalogVoziloMocKW: TIntegerField;
    wdsNalogVoziloProstornina: TIntegerField;
    wdsNalogVoziloStSasije: TStringField;
    wdsNalogVoziloMasaVozila: TIntegerField;
    wdsNalogVoziloMasaNajvecja: TIntegerField;
    wdsNalogVoziloPrtljaznikVolM3: TIntegerField;
    wdsNalogVoziloOpombe: TStringField;
    wdsNalogVoziloStanjeKm: TIntegerField;
    wdsNalogId: TIntegerField;
    wdsNalogLokacijaOpis: TStringField;
    wdsNalogCasVpisa: TDateTimeField;
    wdsNalogDatumDostave: TDateTimeField;
    wdsNalogDatumOdvoza: TDateTimeField;
    wdsNalogPodpisStranke: TBlobField;
    wdsNalogMesecniObracun: TIntegerField;
    wdsNalogOpombe: TStringField;
    wdsNalogLat: TFloatField;
    wdsNalogLon: TFloatField;
    wdsNalogTipNaloga: TXDataWebEntityField;
    wdsNalogTipNalogaId: TIntegerField;
    wdsNalogTipNalogaOpis: TStringField;
    wdsNalogOpombeVoznik: TStringField;
    tmrRefresh: TTimer;
    WebLabel4: TLabel;
    WebDBLabel1: TDBLabel;
    btnLock: TButton;
    btnMap: TButton;
    procedure btnCancelClick(Sender: TObject);
    procedure FormCreate(Sender: TObject);
    procedure ApplyNote(Sender: TObject);
    procedure ClearNotes(Sender: TObject);
    procedure gridPodrobnoGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
    procedure btnNotesClick(Sender: TObject);
    procedure btnSignClick(Sender: TObject);
    procedure gridPodrobnoClickCell(Sender: TObject; ACol, ARow: Integer);
    procedure wdsNalogPostError(DataSet: TDataSet; E: EDatabaseError; var Action: TDataAction);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnTransferClick(Sender: TObject);
    procedure wdsNalogAfterOpen(DataSet: TDataSet);
    procedure tmrRefreshTimer(Sender: TObject);
    procedure wdsPodrobnoAfterOpen(DataSet: TDataSet);
    procedure btnLockClick(Sender: TObject);
    procedure btnMapClick(Sender: TObject);
  private
    { Private declarations }
    FNalogId: integer; //Št odvoza, 0 za nove odvoze
    RedniNalog, IzredniNalog, InterniNalog, OdvozNalog: boolean;

    FSelRow: integer;
    FPendingUpdates: boolean;
    procedure RefreshPodrobno(NalogId: integer);
    function Pop(Id: integer): integer;
    procedure PripraviNalog(NalogId: integer);

    procedure TehtajClick(event: TJSEvent);
    procedure RazloziClick(event: TJSEvent);
    procedure NaloziClick(event: TJSEvent);
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings, Client.Constants;

procedure TfrmNalog.FormCreate(Sender: TObject);
begin
  FNalogId := modMain.NalogId;

  RedniNalog   := modMain.TipNalogaId = Pop(Ord(ntRedni));
  IzredniNalog := modMain.TipNalogaId = Pop(Ord(ntIzredni));
  InterniNalog := modMain.TipNalogaId = Pop(Ord(ntInterni));
  OdvozNalog   := modMain.TipNalogaId = Pop(Ord(ntOdvoz));

  PripraviNalog(FNalogId);

  {$REGION 'Priprava gumbov za opombe'}
  btnSign.Visible := RedniNalog or IzredniNalog;
  btnlock.Visible := InterniNalog;
  btnTransfer.Visible := false;  // Za zdaj vedno skrito
  {$ENDREGION}

  {$REGION 'Priprava gumbov za opombe'}
  btnOpomba1.Caption := AppSettings.Note1;
  btnOpomba2.Caption := AppSettings.Note2;
  btnOpomba3.Caption := AppSettings.Note3;

  if Length(btnOpomba1.Caption) > 30 then
    btnOpomba1.Caption := Copy(btnOpomba1.Caption, 1, 30)+ '...';

  btnOpomba2.Caption := AppSettings.Note2;
  btnOpomba3.Caption := AppSettings.Note3;

  btnOpomba1.Visible := btnOpomba1.Caption <> '';
  btnOpomba2.Visible := btnOpomba2.Caption <> '';
  btnOpomba3.Visible := btnOpomba3.Caption <> '';
  {$ENDREGION}

//  gridPodrobno.Columns[2].Width := 0;
//  gridPodrobno.Columns[3].Width := 0;

//  if not AppSettings.DevMode then begin
    gridPodrobno.ColWidths[2] := 0;
    gridPodrobno.ColWidths[3] := 0;
//  end;
end;

function TfrmNalog.Pop(Id: integer): integer;
begin
  Result := Id+1;
end;

procedure TfrmNalog.PripraviNalog(NalogId: integer);
begin
  // Pripravi nov nalog
  wdsNalog.Close;
  wdsNalog.QueryString := '$filter=Id eq '+NalogId.ToString+'&$expand=Stranka,Vozilo';
  wdsNalog.Load;
end;

procedure TfrmNalog.RefreshPodrobno(NalogId: integer);
begin
  modMain.Log('RefreshoPosdrobno za NalogiId='+NalogId.ToString);
  wdsPodrobno.Close;
  wdsPodrobno.QueryString := '$filter=Nalog/Id eq '+NalogId.ToString+' and Status/Id ne '+(Ord(ksZakljucen)+1).ToString+'&$orderby=Id&$expand=Kontejner,Frakcija,Status';

  modMain.Log(wdsPodrobno.QueryString);
  wdsPodrobno.Load;
end;

procedure TfrmNalog.wdsNalogAfterOpen(DataSet: TDataSet);
begin
  // Zaključen - preusmeri na seznam
  if wdsNalog.FieldByName('Status.Id').AsInteger = Ord(nsZakljucen) then begin
    frmMain.ShowNalogi;
  end else begin
    RefreshPodrobno(FNalogId);
  end;
end;

procedure TfrmNalog.wdsNalogPostError(DataSet: TDataSet; E: EDatabaseError; var Action: TDataAction);
begin
  modMain.log(E.Message);
end;

procedure TfrmNalog.wdsPodrobnoAfterOpen(DataSet: TDataSet);
begin
  // Ni več CNT, preusmeri
  if wdsPodrobno.RecordCount=0 then begin
    frmMain.ShowNalogi;
  end;
end;

procedure TfrmNalog.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Action := caFree;
end;

procedure TfrmNalog.gridPodrobnoClickCell(Sender: TObject; ACol, ARow: Integer);
begin
  modMain.Log('ARow='+ARow.ToString+', ACol='+ACol.ToString);
  FSelRow := ACol;        // ? Miožno,da je obratno..?
end;

procedure TfrmNalog.tmrRefreshTimer(Sender: TObject);
begin
  tmrRefresh.Enabled := false;
  RefreshPodrobno(FNalogId);
end;

procedure TfrmNalog.RazloziClick(event: TJSEvent);
begin
  FSelRow := StrToIntDef(TJSHTMLElement(event.target)['data'], FSelRow);
  wdsPodrobno.RecNo := FSelRow+1;

  modMain.RazloziCnt(wdsPodrobno.FieldByName('Id').AsInteger);

  if (modMain.TipNalogaId = Pop(Ord(ntIzredni))) or (modMain.TipNalogaId = Pop(Ord(ntRedni))) or (modMain.TipNalogaId = Pop(Ord(ntOdvoz))) then begin
    modMain.Log('Dostava');
    modMain.NastaviVoznikDostava(wdsPodrobno.FieldByName('Id').AsInteger, modMain.VoznikId);
  end else begin
    modMain.Log('Odvoz');
    modMain.NastaviVoznikOdvoz(wdsPodrobno.FieldByName('Id').AsInteger, modMain.VoznikId);
  end;

  tmrRefresh.Enabled := true;
end;

procedure TfrmNalog.NaloziClick(event: TJSEvent);
begin
  FSelRow := StrToIntDef(TJSHTMLElement(event.target)['data'], FSelRow);
  wdsPodrobno.RecNo := FSelRow+1;

  modMain.NaloziCnt(wdsPodrobno.FieldByName('Id').AsInteger);

  if (modMain.TipNalogaId = Pop(Ord(ntIzredni))) or (modMain.TipNalogaId = Pop(Ord(ntRedni))) or (modMain.TipNalogaId = Pop(Ord(ntOdvoz))) then begin
    modMain.Log('Odvoz');
    modMain.NastaviVoznikOdvoz(wdsPodrobno.FieldByName('Id').AsInteger, modMain.VoznikId);
  end else begin
    modMain.Log('Dostava');
    modMain.NastaviVoznikDostava(wdsPodrobno.FieldByName('Id').AsInteger, modMain.VoznikId);
  end;

  tmrRefresh.Enabled := true;
end;

procedure TfrmNalog.TehtajClick(event: TJSEvent);
begin
  FSelRow := StrToIntDef(TJSHTMLElement(event.target)['data'], FSelRow);
  wdsPodrobno.RecNo := FSelRow+1;

  modMain.Log('modMain.TipNalogaId: '+modMain.TipNalogaId.ToString);

  if modMain.TipNalogaId = Pop(Ord(ntOdvoz)) then begin
    frmMain.ShowTeza(wdsPodrobno.FieldByName('Id').AsInteger);
  end else begin
    modMain.TehtajCnt(wdsPodrobno.FieldByName('Id').AsInteger);
    tmrRefresh.Enabled := true;
  end;
end;

procedure TfrmNalog.gridPodrobnoGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; AElement: TJSHTMLElementRecord);
var el: TJSHTMLElement;
    cntStatus: integer;
    Razlozi, Nalozi, Stehtaj, Zakljuci: boolean;
    Vpisan, PriStranki, Nalozen, Stehtan, Zakljucen: boolean;
begin
  if ACol = 0 then
    Exit;

  if (ACol <> gridPodrobno.Columns.Count-1) then
     exit;

  cntStatus := StrToIntDef(gridPodrobno.Cells[ACol-1, ARow],0);

  Vpisan := cntStatus = Pop(Ord(ksVpisan));
  PriStranki := cntStatus = Pop(Ord(ksPriStranki));
  Nalozen := cntStatus = Pop(Ord(ksNalozen));
  Stehtan := cntStatus = Pop(Ord(ksStehtan));
  Zakljucen := cntStatus = Pop(Ord(ksZakljucen));

  // Redni: Naloži - Stehtaj - Razloži - Zaklkjuči
  // Izredni: Razloži - Naloži - Stehtaj
  // Interni: Naloži - Stehtaj - Razloži - Zaklkjuči
  // Odvoz: Naloži - Stehtaj - Razloži

  Razlozi := (IzredniNalog and Vpisan) or Stehtan;
  Nalozi  := (not IzredniNalog and Vpisan) or (PriStranki);
  Stehtaj := Nalozen;

  {$REGION 'Izris gumba'}
  if Stehtaj then begin
    el := TJSHTMLElement(document.createElement('INPUT'));
    el['type'] := 'BUTTON';
    el['value'] := 'Tehtaj';
    el['data'] := ARow.ToString;
    el.addEventListener('click',@TehtajClick);
    el.style.setProperty('width','110px');
    el.style.setProperty('height','50px');
    el.style.setProperty('font-size','x-large');
    el.className := 'btn btn-xxl btn-warning';
    AElement.element.appendChild(el);
  end;

  if Razlozi then begin
    el := TJSHTMLElement(document.createElement('INPUT'));
    el['type'] := 'BUTTON';
    el['value'] := 'Razloži';
    el['data'] := ARow.ToString;
    el.addEventListener('click',@RazloziClick);
    el.style.setProperty('width','110px');
    el.style.setProperty('height','50px');
    el.style.setProperty('font-size','x-large');
    el.className := 'btn btn-xxl btn-primary';
    AElement.element.appendChild(el);
  end;

  if Nalozi then begin
    el := TJSHTMLElement(document.createElement('INPUT'));
    el['type'] := 'BUTTON';
    el['value'] := 'Naloži';
    el['data'] := ARow.ToString;
    el.addEventListener('click',@NaloziClick);
    el.style.setProperty('width','110px');
    el.style.setProperty('height','50px');
    el.style.setProperty('font-size','x-large');
    el.className := 'btn btn-xxl btn-success';
    AElement.element.appendChild(el);
  end;

  if Zakljuci then begin
    el := TJSHTMLElement(document.createElement('INPUT'));
    el['type'] := 'BUTTON';
    el['value'] := 'Zaključi';
    el['data'] := ARow.ToString;
    el.addEventListener('click',@RazloziClick);
    el.style.setProperty('width','110px');
    el.style.setProperty('height','50px');
    el.style.setProperty('font-size','x-large');
    el.className := 'btn btn-xxl btn-danger';
    AElement.element.appendChild(el);
  end;
  {$ENDREGION}
end;

procedure TfrmNalog.ClearNotes(Sender: TObject);
begin
  memoNotes.Clear;
end;

procedure TfrmNalog.ApplyNote(Sender: TObject);
begin
  wdsNalog.Edit;

  if Sender = btnOpomba1 then
    wdsNalog.FieldByName('OpombeVoznik').AsString := AppSettings.Note1;

  if Sender = btnOpomba2 then
    wdsNalog.FieldByName('OpombeVoznik').AsString := AppSettings.Note2;

  if Sender = btnOpomba3 then
    wdsNalog.FieldByName('OpombeVoznik').AsString := AppSettings.Note3;

  wdsNalog.Post;
  wdsNalog.ApplyUpdates;
end;

procedure TfrmNalog.btnCancelClick(Sender: TObject);
begin
  frmMain.ShowNalogi;
end;

procedure TfrmNalog.btnLockClick(Sender: TObject);
begin
  // Zaključi nalog
  frmMain.ShowNalogi;
end;

procedure TfrmNalog.btnMapClick(Sender: TObject);
begin
  frmMain.ShowZemljevid(FNalogId, wdsNalog.FieldByName('Lat').AsFloat, wdsNalog.FieldByName('Lon').AsFloat);
end;

procedure TfrmNalog.btnNotesClick(Sender: TObject);
begin
  panSign.Hide;
  panNotes.Visible := not panNotes.Visible;
  if not panNotes.Visible then begin
    wdsNalog.ApplyUpdates;
  end;
end;

procedure TfrmNalog.btnTransferClick(Sender: TObject);
begin
  frmMain.ShowKontejner(FNalogId);
end;

procedure TfrmNalog.btnSignClick(Sender: TObject);
begin
  panNotes.Hide;
  panSign.Visible := not panSign.Visible;
  if panSign.Visible then begin
    //signStranka.  wdsNalog.FieldByName('PodpisStranke').AsString := signStranka.GetAsBase64Image;
  end else begin
    wdsNalog.Edit;
    wdsNalog.FieldByName('PodpisStranke').AsString := signStranka.GetAsBase64Image;
    wdsNalog.Post;
    wdsNalog.ApplyUpdates;
  end;
end;

procedure TfrmNalog.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panInfo := TPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  lblLokacija := TDBLabel.Create(Self);
  WebDBLabel5 := TDBLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebDBLabel4 := TDBLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebDBLabel1 := TDBLabel.Create(Self);
  panPosode := TPanel.Create(Self);
  gridPodrobno := TDBGrid.Create(Self);
  panSign := TPanel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  signStranka := TSignatureCapture.Create(Self);
  panNotes := TPanel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  memoNotes := TDBMemo.Create(Self);
  panNotesButtons := TGridPanel.Create(Self);
  btnOpomba1 := TButton.Create(Self);
  btnOpomba2 := TButton.Create(Self);
  btnOpomba3 := TButton.Create(Self);
  btnDelNote := TButton.Create(Self);
  panFooter := TPanel.Create(Self);
  btnTransfer := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  btnNotes := TButton.Create(Self);
  btnSign := TButton.Create(Self);
  btnLock := TButton.Create(Self);
  btnMap := TButton.Create(Self);
  dsPodrobno := TDataSource.Create(Self);
  wdsPodrobno := TXDataWebDataSet.Create(Self);
  dsNalog := TDataSource.Create(Self);
  wdsNalog := TXDataWebDataSet.Create(Self);
  wdsNalogid2 := TIntegerField.Create(Self);
  wdsNalogKontejnerji := TXDataWebEntityField.Create(Self);
  wdsNalogStranka := TXDataWebEntityField.Create(Self);
  wdsNalogStrankaLokacije := TXDataWebDatasetField.Create(Self);
  wdsNalogStrankaId := TIntegerField.Create(Self);
  wdsNalogStrankaZunanjaOznaka := TStringField.Create(Self);
  wdsNalogStrankaAktivna := TBooleanField.Create(Self);
  wdsNalogStrankaObracun := TBooleanField.Create(Self);
  wdsNalogStrankaNaziv := TStringField.Create(Self);
  wdsNalogStrankaNaslov := TStringField.Create(Self);
  wdsNalogStrankaPosta := TStringField.Create(Self);
  wdsNalogStrankaKraj := TStringField.Create(Self);
  wdsNalogStrankaDavSt := TStringField.Create(Self);
  wdsNalogStrankaTel := TStringField.Create(Self);
  wdsNalogStrankaOpombe := TStringField.Create(Self);
  wdsNalogStrankaMesecniObracun := TIntegerField.Create(Self);
  wdsNalogStatus := TXDataWebEntityField.Create(Self);
  wdsNalogStatusId := TIntegerField.Create(Self);
  wdsNalogStatusOpis := TStringField.Create(Self);
  wdsNalogVoznikDostava := TXDataWebEntityField.Create(Self);
  wdsNalogVoznikDostavaVloga := TXDataWebEntityField.Create(Self);
  wdsNalogVoznikDostavaVlogaId := TIntegerField.Create(Self);
  wdsNalogVoznikDostavaVlogaOpis := TStringField.Create(Self);
  wdsNalogVoznikDostavaId := TIntegerField.Create(Self);
  wdsNalogVoznikDostavaUporabniskoIme := TStringField.Create(Self);
  wdsNalogVoznikDostavaGeslo := TStringField.Create(Self);
  wdsNalogVoznikDostavaPIN := TStringField.Create(Self);
  wdsNalogVoznikDostavaIme := TStringField.Create(Self);
  wdsNalogVoznikDostavaPriimek := TStringField.Create(Self);
  wdsNalogVoznikDostavaAktiven := TBooleanField.Create(Self);
  wdsNalogVoznikOdvoz := TXDataWebEntityField.Create(Self);
  wdsNalogVoznikOdvozVloga := TXDataWebEntityField.Create(Self);
  wdsNalogVoznikOdvozVlogaId := TIntegerField.Create(Self);
  wdsNalogVoznikOdvozVlogaOpis := TStringField.Create(Self);
  wdsNalogVoznikOdvozId := TIntegerField.Create(Self);
  wdsNalogVoznikOdvozUporabniskoIme := TStringField.Create(Self);
  wdsNalogVoznikOdvozGeslo := TStringField.Create(Self);
  wdsNalogVoznikOdvozPIN := TStringField.Create(Self);
  wdsNalogVoznikOdvozIme := TStringField.Create(Self);
  wdsNalogVoznikOdvozPriimek := TStringField.Create(Self);
  wdsNalogVoznikOdvozAktiven := TBooleanField.Create(Self);
  wdsNalogVozilo := TXDataWebEntityField.Create(Self);
  wdsNalogVoziloTipVozila := TXDataWebEntityField.Create(Self);
  wdsNalogVoziloTipVozilaId := TIntegerField.Create(Self);
  wdsNalogVoziloTipVozilaOpis := TStringField.Create(Self);
  wdsNalogVoziloVrstaOdvoza := TXDataWebEntityField.Create(Self);
  wdsNalogVoziloVrstaOdvozaId := TIntegerField.Create(Self);
  wdsNalogVoziloVrstaOdvozaOpis := TStringField.Create(Self);
  wdsNalogVoziloVrstaOdvozaParams := TBlobField.Create(Self);
  wdsNalogVoziloVrstaOdvozaKolicina := TIntegerField.Create(Self);
  wdsNalogVoziloVrstaOdvozaVolumen := TIntegerField.Create(Self);
  wdsNalogVoziloVrstaOdvozaTeza := TIntegerField.Create(Self);
  wdsNalogVoziloOdvozi := TXDataWebDatasetField.Create(Self);
  wdsNalogVoziloId := TIntegerField.Create(Self);
  wdsNalogVoziloRegistrskaSt := TStringField.Create(Self);
  wdsNalogVoziloAktivno := TBooleanField.Create(Self);
  wdsNalogVoziloLetnik := TIntegerField.Create(Self);
  wdsNalogVoziloPrvaRegistracija := TDateTimeField.Create(Self);
  wdsNalogVoziloZnamka := TStringField.Create(Self);
  wdsNalogVoziloTip := TStringField.Create(Self);
  wdsNalogVoziloMocKW := TIntegerField.Create(Self);
  wdsNalogVoziloProstornina := TIntegerField.Create(Self);
  wdsNalogVoziloStSasije := TStringField.Create(Self);
  wdsNalogVoziloMasaVozila := TIntegerField.Create(Self);
  wdsNalogVoziloMasaNajvecja := TIntegerField.Create(Self);
  wdsNalogVoziloPrtljaznikVolM3 := TIntegerField.Create(Self);
  wdsNalogVoziloOpombe := TStringField.Create(Self);
  wdsNalogVoziloStanjeKm := TIntegerField.Create(Self);
  wdsNalogId := TIntegerField.Create(Self);
  wdsNalogLokacijaOpis := TStringField.Create(Self);
  wdsNalogCasVpisa := TDateTimeField.Create(Self);
  wdsNalogDatumDostave := TDateTimeField.Create(Self);
  wdsNalogDatumOdvoza := TDateTimeField.Create(Self);
  wdsNalogPodpisStranke := TBlobField.Create(Self);
  wdsNalogMesecniObracun := TIntegerField.Create(Self);
  wdsNalogOpombe := TStringField.Create(Self);
  wdsNalogOpombeVoznik := TStringField.Create(Self);
  wdsNalogLat := TFloatField.Create(Self);
  wdsNalogLon := TFloatField.Create(Self);
  wdsNalogTipNaloga := TXDataWebEntityField.Create(Self);
  wdsNalogTipNalogaId := TIntegerField.Create(Self);
  wdsNalogTipNalogaOpis := TStringField.Create(Self);
  tmrRefresh := TTimer.Create(Self);

  panInfo.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  lblLokacija.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebDBLabel4.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  panPosode.BeforeLoadDFMValues;
  gridPodrobno.BeforeLoadDFMValues;
  panSign.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  signStranka.BeforeLoadDFMValues;
  panNotes.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  memoNotes.BeforeLoadDFMValues;
  panNotesButtons.BeforeLoadDFMValues;
  btnOpomba1.BeforeLoadDFMValues;
  btnOpomba2.BeforeLoadDFMValues;
  btnOpomba3.BeforeLoadDFMValues;
  btnDelNote.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnTransfer.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  btnNotes.BeforeLoadDFMValues;
  btnSign.BeforeLoadDFMValues;
  btnLock.BeforeLoadDFMValues;
  btnMap.BeforeLoadDFMValues;
  dsPodrobno.BeforeLoadDFMValues;
  wdsPodrobno.BeforeLoadDFMValues;
  dsNalog.BeforeLoadDFMValues;
  wdsNalog.BeforeLoadDFMValues;
  wdsNalogid2.BeforeLoadDFMValues;
  wdsNalogKontejnerji.BeforeLoadDFMValues;
  wdsNalogStranka.BeforeLoadDFMValues;
  wdsNalogStrankaLokacije.BeforeLoadDFMValues;
  wdsNalogStrankaId.BeforeLoadDFMValues;
  wdsNalogStrankaZunanjaOznaka.BeforeLoadDFMValues;
  wdsNalogStrankaAktivna.BeforeLoadDFMValues;
  wdsNalogStrankaObracun.BeforeLoadDFMValues;
  wdsNalogStrankaNaziv.BeforeLoadDFMValues;
  wdsNalogStrankaNaslov.BeforeLoadDFMValues;
  wdsNalogStrankaPosta.BeforeLoadDFMValues;
  wdsNalogStrankaKraj.BeforeLoadDFMValues;
  wdsNalogStrankaDavSt.BeforeLoadDFMValues;
  wdsNalogStrankaTel.BeforeLoadDFMValues;
  wdsNalogStrankaOpombe.BeforeLoadDFMValues;
  wdsNalogStrankaMesecniObracun.BeforeLoadDFMValues;
  wdsNalogStatus.BeforeLoadDFMValues;
  wdsNalogStatusId.BeforeLoadDFMValues;
  wdsNalogStatusOpis.BeforeLoadDFMValues;
  wdsNalogVoznikDostava.BeforeLoadDFMValues;
  wdsNalogVoznikDostavaVloga.BeforeLoadDFMValues;
  wdsNalogVoznikDostavaVlogaId.BeforeLoadDFMValues;
  wdsNalogVoznikDostavaVlogaOpis.BeforeLoadDFMValues;
  wdsNalogVoznikDostavaId.BeforeLoadDFMValues;
  wdsNalogVoznikDostavaUporabniskoIme.BeforeLoadDFMValues;
  wdsNalogVoznikDostavaGeslo.BeforeLoadDFMValues;
  wdsNalogVoznikDostavaPIN.BeforeLoadDFMValues;
  wdsNalogVoznikDostavaIme.BeforeLoadDFMValues;
  wdsNalogVoznikDostavaPriimek.BeforeLoadDFMValues;
  wdsNalogVoznikDostavaAktiven.BeforeLoadDFMValues;
  wdsNalogVoznikOdvoz.BeforeLoadDFMValues;
  wdsNalogVoznikOdvozVloga.BeforeLoadDFMValues;
  wdsNalogVoznikOdvozVlogaId.BeforeLoadDFMValues;
  wdsNalogVoznikOdvozVlogaOpis.BeforeLoadDFMValues;
  wdsNalogVoznikOdvozId.BeforeLoadDFMValues;
  wdsNalogVoznikOdvozUporabniskoIme.BeforeLoadDFMValues;
  wdsNalogVoznikOdvozGeslo.BeforeLoadDFMValues;
  wdsNalogVoznikOdvozPIN.BeforeLoadDFMValues;
  wdsNalogVoznikOdvozIme.BeforeLoadDFMValues;
  wdsNalogVoznikOdvozPriimek.BeforeLoadDFMValues;
  wdsNalogVoznikOdvozAktiven.BeforeLoadDFMValues;
  wdsNalogVozilo.BeforeLoadDFMValues;
  wdsNalogVoziloTipVozila.BeforeLoadDFMValues;
  wdsNalogVoziloTipVozilaId.BeforeLoadDFMValues;
  wdsNalogVoziloTipVozilaOpis.BeforeLoadDFMValues;
  wdsNalogVoziloVrstaOdvoza.BeforeLoadDFMValues;
  wdsNalogVoziloVrstaOdvozaId.BeforeLoadDFMValues;
  wdsNalogVoziloVrstaOdvozaOpis.BeforeLoadDFMValues;
  wdsNalogVoziloVrstaOdvozaParams.BeforeLoadDFMValues;
  wdsNalogVoziloVrstaOdvozaKolicina.BeforeLoadDFMValues;
  wdsNalogVoziloVrstaOdvozaVolumen.BeforeLoadDFMValues;
  wdsNalogVoziloVrstaOdvozaTeza.BeforeLoadDFMValues;
  wdsNalogVoziloOdvozi.BeforeLoadDFMValues;
  wdsNalogVoziloId.BeforeLoadDFMValues;
  wdsNalogVoziloRegistrskaSt.BeforeLoadDFMValues;
  wdsNalogVoziloAktivno.BeforeLoadDFMValues;
  wdsNalogVoziloLetnik.BeforeLoadDFMValues;
  wdsNalogVoziloPrvaRegistracija.BeforeLoadDFMValues;
  wdsNalogVoziloZnamka.BeforeLoadDFMValues;
  wdsNalogVoziloTip.BeforeLoadDFMValues;
  wdsNalogVoziloMocKW.BeforeLoadDFMValues;
  wdsNalogVoziloProstornina.BeforeLoadDFMValues;
  wdsNalogVoziloStSasije.BeforeLoadDFMValues;
  wdsNalogVoziloMasaVozila.BeforeLoadDFMValues;
  wdsNalogVoziloMasaNajvecja.BeforeLoadDFMValues;
  wdsNalogVoziloPrtljaznikVolM3.BeforeLoadDFMValues;
  wdsNalogVoziloOpombe.BeforeLoadDFMValues;
  wdsNalogVoziloStanjeKm.BeforeLoadDFMValues;
  wdsNalogId.BeforeLoadDFMValues;
  wdsNalogLokacijaOpis.BeforeLoadDFMValues;
  wdsNalogCasVpisa.BeforeLoadDFMValues;
  wdsNalogDatumDostave.BeforeLoadDFMValues;
  wdsNalogDatumOdvoza.BeforeLoadDFMValues;
  wdsNalogPodpisStranke.BeforeLoadDFMValues;
  wdsNalogMesecniObracun.BeforeLoadDFMValues;
  wdsNalogOpombe.BeforeLoadDFMValues;
  wdsNalogOpombeVoznik.BeforeLoadDFMValues;
  wdsNalogLat.BeforeLoadDFMValues;
  wdsNalogLon.BeforeLoadDFMValues;
  wdsNalogTipNaloga.BeforeLoadDFMValues;
  wdsNalogTipNalogaId.BeforeLoadDFMValues;
  wdsNalogTipNalogaOpis.BeforeLoadDFMValues;
  tmrRefresh.BeforeLoadDFMValues;
  try
    Name := 'frmNalog';
    Width := 903;
    Height := 984;
    Color := clBtnFace;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'FormCreate');
    panInfo.SetParentComponent(Self);
    panInfo.Name := 'panInfo';
    panInfo.Left := 0;
    panInfo.Top := 0;
    panInfo.Width := 903;
    panInfo.Height := 169;
    panInfo.Align := alTop;
    panInfo.BorderStyle := bsNone;
    panInfo.ChildOrder := 3;
    panInfo.Color := clWhite;
    panInfo.TabOrder := 0;
    WebLabel2.SetParentComponent(panInfo);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 3;
    WebLabel2.Top := 43;
    WebLabel2.Width := 81;
    WebLabel2.Height := 25;
    WebLabel2.Caption := 'Lokacija:';
    WebLabel2.ElementClassName := 'h4';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -21;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    lblLokacija.SetParentComponent(panInfo);
    lblLokacija.Name := 'lblLokacija';
    lblLokacija.Left := 142;
    lblLokacija.Top := 43;
    lblLokacija.Width := 96;
    lblLokacija.Height := 25;
    lblLokacija.Caption := 'lblLokacija';
    lblLokacija.ElementClassName := 'h4';
    lblLokacija.ElementFont := efCSS;
    lblLokacija.Font.Charset := DEFAULT_CHARSET;
    lblLokacija.Font.Color := clWindowText;
    lblLokacija.Font.Height := -21;
    lblLokacija.Font.Name := 'Tahoma';
    lblLokacija.Font.Style := [];
    lblLokacija.HeightPercent := 100.000000000000000000;
    lblLokacija.ParentFont := False;
    lblLokacija.WidthPercent := 100.000000000000000000;
    lblLokacija.DataField := 'LokacijaOpis';
    lblLokacija.DataSource := dsNalog;
    WebDBLabel5.SetParentComponent(panInfo);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 3;
    WebDBLabel5.Top := -3;
    WebDBLabel5.Width := 143;
    WebDBLabel5.Height := 40;
    WebDBLabel5.Caption := 'lblStranka';
    WebDBLabel5.ElementClassName := 'h2';
    WebDBLabel5.ElementFont := efCSS;
    WebDBLabel5.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel5.Font.Color := clWindowText;
    WebDBLabel5.Font.Height := -33;
    WebDBLabel5.Font.Name := 'Tahoma';
    WebDBLabel5.Font.Style := [];
    WebDBLabel5.HeightPercent := 100.000000000000000000;
    WebDBLabel5.ParentFont := False;
    WebDBLabel5.WidthPercent := 100.000000000000000000;
    WebDBLabel5.DataField := 'Stranka.Naziv';
    WebDBLabel5.DataSource := dsNalog;
    WebLabel1.SetParentComponent(panInfo);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 3;
    WebLabel1.Top := 74;
    WebLabel1.Width := 80;
    WebLabel1.Height := 25;
    WebLabel1.Caption := 'Dostava:';
    WebLabel1.ElementClassName := 'h4';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -21;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel2.SetParentComponent(panInfo);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 142;
    WebDBLabel2.Top := 74;
    WebDBLabel2.Width := 100;
    WebDBLabel2.Height := 25;
    WebDBLabel2.Caption := '01.01.2000';
    WebDBLabel2.ElementClassName := 'h4';
    WebDBLabel2.ElementFont := efCSS;
    WebDBLabel2.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel2.Font.Color := clWindowText;
    WebDBLabel2.Font.Height := -21;
    WebDBLabel2.Font.Name := 'Tahoma';
    WebDBLabel2.Font.Style := [];
    WebDBLabel2.HeightStyle := ssAuto;
    WebDBLabel2.HeightPercent := 100.000000000000000000;
    WebDBLabel2.ParentFont := False;
    WebDBLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel2.DataField := 'DatumDostave';
    WebDBLabel2.DataSource := dsNalog;
    WebLabel3.SetParentComponent(panInfo);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 2;
    WebLabel3.Top := 105;
    WebLabel3.Width := 64;
    WebLabel3.Height := 25;
    WebLabel3.Caption := 'Odvoz:';
    WebLabel3.ElementClassName := 'h4';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -21;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel4.SetParentComponent(panInfo);
    WebDBLabel4.Name := 'WebDBLabel4';
    WebDBLabel4.Left := 141;
    WebDBLabel4.Top := 105;
    WebDBLabel4.Width := 100;
    WebDBLabel4.Height := 25;
    WebDBLabel4.Caption := '01.01.2000';
    WebDBLabel4.ElementClassName := 'h4';
    WebDBLabel4.ElementFont := efCSS;
    WebDBLabel4.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel4.Font.Color := clWindowText;
    WebDBLabel4.Font.Height := -21;
    WebDBLabel4.Font.Name := 'Tahoma';
    WebDBLabel4.Font.Style := [];
    WebDBLabel4.HeightStyle := ssAuto;
    WebDBLabel4.HeightPercent := 100.000000000000000000;
    WebDBLabel4.ParentFont := False;
    WebDBLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel4.DataField := 'DatumOdvoza';
    WebDBLabel4.DataSource := dsNalog;
    WebLabel4.SetParentComponent(panInfo);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 3;
    WebLabel4.Top := 136;
    WebLabel4.Width := 86;
    WebLabel4.Height := 25;
    WebLabel4.Caption := 'Opombe:';
    WebLabel4.ElementClassName := 'h4';
    WebLabel4.ElementFont := efCSS;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -21;
    WebLabel4.Font.Name := 'Tahoma';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightStyle := ssAuto;
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel1.SetParentComponent(panInfo);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 142;
    WebDBLabel1.Top := 136;
    WebDBLabel1.Width := 731;
    WebDBLabel1.Height := 25;
    WebDBLabel1.Anchors := [akLeft,akTop,akRight];
    WebDBLabel1.AutoSize := False;
    WebDBLabel1.ElementClassName := 'h4';
    WebDBLabel1.ElementFont := efCSS;
    WebDBLabel1.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel1.Font.Color := clWindowText;
    WebDBLabel1.Font.Height := -21;
    WebDBLabel1.Font.Name := 'Tahoma';
    WebDBLabel1.Font.Style := [];
    WebDBLabel1.HeightStyle := ssAuto;
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.ParentFont := False;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'Opombe';
    WebDBLabel1.DataSource := dsNalog;
    panPosode.SetParentComponent(Self);
    panPosode.Name := 'panPosode';
    panPosode.Left := 0;
    panPosode.Top := 169;
    panPosode.Width := 903;
    panPosode.Height := 97;
    panPosode.Align := alClient;
    panPosode.BorderStyle := bsNone;
    panPosode.ChildOrder := 3;
    panPosode.Color := clWhite;
    panPosode.TabOrder := 1;
    gridPodrobno.SetParentComponent(panPosode);
    gridPodrobno.Name := 'gridPodrobno';
    gridPodrobno.Left := 0;
    gridPodrobno.Top := 0;
    gridPodrobno.Width := 903;
    gridPodrobno.Height := 97;
    gridPodrobno.Align := alClient;
    gridPodrobno.Color := clWhite;
    gridPodrobno.Columns.Clear;
    with gridPodrobno.Columns.Add do
    begin
      DataField := 'Kontejner.Opis';
      Title := 'Posoda';
      Width := 240;
    end;
    with gridPodrobno.Columns.Add do
    begin
      ElementClassName := 'text-wrap';
      DataField := 'Frakcija.Opis';
      Title := 'Frakcija';
      Width := 245;
    end;
    with gridPodrobno.Columns.Add do
    begin
      DataField := 'Status.Opis';
      Title := 'Status';
      Width := 250;
    end;
    with gridPodrobno.Columns.Add do
    begin
      DataField := 'Status.Id';
      Title := 'Status';
      Width := 50;
    end;
    with gridPodrobno.Columns.Add do
    begin
      Editor := geNone;
      Title := 'Akcija';
      Width := 115;
    end;
    gridPodrobno.DataSource := dsPodrobno;
    gridPodrobno.ElementFont := efCSS;
    gridPodrobno.ElementClassName := 'display-4';
    gridPodrobno.FixedFont.Charset := DEFAULT_CHARSET;
    gridPodrobno.FixedFont.Color := clWindowText;
    gridPodrobno.FixedFont.Height := -12;
    gridPodrobno.FixedFont.Name := 'Segoe UI';
    gridPodrobno.FixedFont.Style := [];
    gridPodrobno.FixedCols := 0;
    gridPodrobno.FixedRows := 0;
    gridPodrobno.Font.Charset := DEFAULT_CHARSET;
    gridPodrobno.Font.Color := clWindowText;
    gridPodrobno.Font.Height := -80;
    gridPodrobno.Font.Name := 'Tahoma';
    gridPodrobno.Font.Style := [];
    gridPodrobno.Options := [goRowSelect];
    gridPodrobno.ParentFont := False;
    gridPodrobno.TabOrder := 0;
    gridPodrobno.HeightPercent := 100.000000000000000000;
    gridPodrobno.SelectionColor := clWhite;
    gridPodrobno.SelectionTextColor := clBlack;
    gridPodrobno.ShowIndicator := False;
    gridPodrobno.WidthPercent := 100.000000000000000000;
    SetEvent(gridPodrobno, Self, 'OnClickCell', 'gridPodrobnoClickCell');
    SetEvent(gridPodrobno, Self, 'OnGetCellChildren', 'gridPodrobnoGetCellChildren');
    gridPodrobno.ColWidths[0] := 240;
    gridPodrobno.ColWidths[1] := 245;
    gridPodrobno.ColWidths[2] := 250;
    gridPodrobno.ColWidths[3] := 50;
    gridPodrobno.ColWidths[4] := 115;
    panSign.SetParentComponent(Self);
    panSign.Name := 'panSign';
    panSign.Left := 0;
    panSign.Top := 565;
    panSign.Width := 903;
    panSign.Height := 299;
    panSign.Align := alBottom;
    panSign.BorderStyle := bsNone;
    panSign.ChildOrder := 8;
    panSign.TabOrder := 2;
    panSign.Visible := False;
    WebLabel6.SetParentComponent(panSign);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 0;
    WebLabel6.Top := 0;
    WebLabel6.Width := 903;
    WebLabel6.Height := 33;
    WebLabel6.Align := alTop;
    WebLabel6.Caption := 'Podpis stranke';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -27;
    WebLabel6.Font.Name := 'Tahoma';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    signStranka.SetParentComponent(panSign);
    signStranka.Name := 'signStranka';
    signStranka.Left := 0;
    signStranka.Top := 33;
    signStranka.Width := 903;
    signStranka.Height := 266;
    signStranka.Align := alClient;
    signStranka.ChildOrder := 2;
    signStranka.TabOrder := 0;
    signStranka.TextPosition.Line.Color := clGray;
    signStranka.Text := 'Podpis stranke';
    panNotes.SetParentComponent(Self);
    panNotes.Name := 'panNotes';
    panNotes.Left := 0;
    panNotes.Top := 266;
    panNotes.Width := 903;
    panNotes.Height := 299;
    panNotes.Align := alBottom;
    panNotes.Caption := 'panNotes';
    panNotes.ChildOrder := 8;
    panNotes.TabOrder := 3;
    panNotes.Visible := False;
    WebLabel5.SetParentComponent(panNotes);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 0;
    WebLabel5.Top := 0;
    WebLabel5.Width := 903;
    WebLabel5.Height := 33;
    WebLabel5.Align := alTop;
    WebLabel5.Caption := 'Opombe';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -27;
    WebLabel5.Font.Name := 'Tahoma';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    memoNotes.SetParentComponent(panNotes);
    memoNotes.Name := 'memoNotes';
    memoNotes.Left := 0;
    memoNotes.Top := 33;
    memoNotes.Width := 903;
    memoNotes.Height := 166;
    memoNotes.Align := alClient;
    memoNotes.Font.Charset := DEFAULT_CHARSET;
    memoNotes.Font.Color := clWindowText;
    memoNotes.Font.Height := -27;
    memoNotes.Font.Name := 'Tahoma';
    memoNotes.Font.Style := [];
    memoNotes.HeightPercent := 100.000000000000000000;
    memoNotes.Lines.BeginUpdate;
    try
      memoNotes.Lines.Clear;
      memoNotes.Lines.Add('memoNotes');
    finally
      memoNotes.Lines.EndUpdate;
    end;
    memoNotes.ParentFont := False;
    memoNotes.SelLength := 0;
    memoNotes.SelStart := 0;
    memoNotes.WidthPercent := 100.000000000000000000;
    memoNotes.DataField := 'OpombeVoznik';
    memoNotes.DataSource := dsNalog;
    panNotesButtons.SetParentComponent(panNotes);
    panNotesButtons.Name := 'panNotesButtons';
    panNotesButtons.Left := 0;
    panNotesButtons.Top := 199;
    panNotesButtons.Width := 903;
    panNotesButtons.Height := 100;
    panNotesButtons.Align := alBottom;
    panNotesButtons.ChildOrder := 4;
    panNotesButtons.ColumnCollection.Clear;
    with panNotesButtons.ColumnCollection.Add do
    begin
      MarginLeft := 3;
      MarginRight := 3;
      Value := 33;
    end;
    with panNotesButtons.ColumnCollection.Add do
    begin
      MarginLeft := 3;
      MarginRight := 3;
      Value := 33;
    end;
    with panNotesButtons.ColumnCollection.Add do
    begin
      MarginLeft := 3;
      MarginRight := 3;
      Value := 33;
    end;
    panNotesButtons.ControlCollection.Clear;
    with panNotesButtons.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btnOpomba1;
    end;
    with panNotesButtons.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btnOpomba2;
    end;
    with panNotesButtons.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btnOpomba3;
    end;
    panNotesButtons.Color := clWhite;
    panNotesButtons.GridLineColor := clBlack;
    panNotesButtons.RowCollection.Clear;
    with panNotesButtons.RowCollection.Add do
    begin
      Value := 100;
    end;
    btnOpomba1.SetParentComponent(panNotesButtons);
    btnOpomba1.Name := 'btnOpomba1';
    btnOpomba1.AlignWithMargins := True;
    btnOpomba1.Left := 3;
    btnOpomba1.Top := 3;
    btnOpomba1.Width := 897;
    btnOpomba1.Height := 94;
    btnOpomba1.Align := alClient;
    btnOpomba1.Caption := 'Opomba 1';
    btnOpomba1.ChildOrder := 2;
    btnOpomba1.ElementClassName := 'btn btn-xxl btn-primary shadow d-inline-block text-truncate';
    btnOpomba1.ElementFont := efCSS;
    btnOpomba1.HeightPercent := 100.000000000000000000;
    btnOpomba1.WidthPercent := 100.000000000000000000;
    SetEvent(btnOpomba1, Self, 'OnClick', 'ApplyNote');
    btnOpomba2.SetParentComponent(panNotesButtons);
    btnOpomba2.Name := 'btnOpomba2';
    btnOpomba2.AlignWithMargins := True;
    btnOpomba2.Left := 3;
    btnOpomba2.Top := 3;
    btnOpomba2.Width := 897;
    btnOpomba2.Height := 94;
    btnOpomba2.Align := alClient;
    btnOpomba2.Caption := 'Opomba 2';
    btnOpomba2.ChildOrder := 2;
    btnOpomba2.ElementClassName := 'btn btn-xxl btn-primary shadow';
    btnOpomba2.ElementFont := efCSS;
    btnOpomba2.HeightPercent := 100.000000000000000000;
    btnOpomba2.WidthPercent := 100.000000000000000000;
    SetEvent(btnOpomba2, Self, 'OnClick', 'ApplyNote');
    btnOpomba3.SetParentComponent(panNotesButtons);
    btnOpomba3.Name := 'btnOpomba3';
    btnOpomba3.AlignWithMargins := True;
    btnOpomba3.Left := 3;
    btnOpomba3.Top := 3;
    btnOpomba3.Width := 897;
    btnOpomba3.Height := 94;
    btnOpomba3.Align := alClient;
    btnOpomba3.Caption := 'Opomba 3';
    btnOpomba3.ChildOrder := 2;
    btnOpomba3.ElementClassName := 'btn btn-xxl btn-primary shadow';
    btnOpomba3.ElementFont := efCSS;
    btnOpomba3.HeightPercent := 100.000000000000000000;
    btnOpomba3.WidthPercent := 100.000000000000000000;
    SetEvent(btnOpomba3, Self, 'OnClick', 'ApplyNote');
    btnDelNote.SetParentComponent(panNotes);
    btnDelNote.Name := 'btnDelNote';
    btnDelNote.AlignWithMargins := True;
    btnDelNote.Left := 834;
    btnDelNote.Top := 39;
    btnDelNote.Width := 59;
    btnDelNote.Height := 62;
    btnDelNote.Margins.Right := 10;
    btnDelNote.Anchors := [akTop,akRight];
    btnDelNote.Caption := '<i class="material-icons">backspace</i>';
    btnDelNote.ChildOrder := 2;
    btnDelNote.ElementClassName := 'btn btn-xxl btn-primary shadow';
    btnDelNote.ElementFont := efCSS;
    btnDelNote.HeightPercent := 100.000000000000000000;
    btnDelNote.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelNote, Self, 'OnClick', 'ClearNotes');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 864;
    panFooter.Width := 903;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderColor := clNone;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.TabOrder := 4;
    btnTransfer.SetParentComponent(panFooter);
    btnTransfer.Name := 'btnTransfer';
    btnTransfer.AlignWithMargins := True;
    btnTransfer.Left := 793;
    btnTransfer.Top := 3;
    btnTransfer.Width := 100;
    btnTransfer.Height := 114;
    btnTransfer.Margins.Right := 10;
    btnTransfer.Align := alRight;
    btnTransfer.Caption := '<i class="material-icons" style="font-size:40px;">move_up</i>';
    btnTransfer.ElementClassName := 'btn btn-success';
    btnTransfer.ElementFont := efCSS;
    btnTransfer.HeightPercent := 100.000000000000000000;
    btnTransfer.WidthPercent := 100.000000000000000000;
    SetEvent(btnTransfer, Self, 'OnClick', 'btnTransferClick');
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 3;
    btnCancel.Width := 100;
    btnCancel.Height := 114;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">keyboard_backspace</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    btnNotes.SetParentComponent(panFooter);
    btnNotes.Name := 'btnNotes';
    btnNotes.AlignWithMargins := True;
    btnNotes.Left := 680;
    btnNotes.Top := 3;
    btnNotes.Width := 100;
    btnNotes.Height := 114;
    btnNotes.Margins.Right := 10;
    btnNotes.Align := alRight;
    btnNotes.Caption := '<i class="material-icons" style="font-size:40px;">notes</i>';
    btnNotes.ElementClassName := 'btn btn-primary';
    btnNotes.ElementFont := efCSS;
    btnNotes.HeightPercent := 100.000000000000000000;
    btnNotes.WidthPercent := 100.000000000000000000;
    SetEvent(btnNotes, Self, 'OnClick', 'btnNotesClick');
    btnSign.SetParentComponent(panFooter);
    btnSign.Name := 'btnSign';
    btnSign.AlignWithMargins := True;
    btnSign.Left := 567;
    btnSign.Top := 3;
    btnSign.Width := 100;
    btnSign.Height := 114;
    btnSign.Margins.Right := 10;
    btnSign.Align := alRight;
    btnSign.Caption := '<i class="material-icons" style="font-size:40px;">person</i>';
    btnSign.ElementClassName := 'btn btn-primary';
    btnSign.ElementFont := efCSS;
    btnSign.HeightPercent := 100.000000000000000000;
    btnSign.WidthPercent := 100.000000000000000000;
    SetEvent(btnSign, Self, 'OnClick', 'btnSignClick');
    btnLock.SetParentComponent(panFooter);
    btnLock.Name := 'btnLock';
    btnLock.AlignWithMargins := True;
    btnLock.Left := 341;
    btnLock.Top := 3;
    btnLock.Width := 100;
    btnLock.Height := 114;
    btnLock.Margins.Left := 10;
    btnLock.Margins.Right := 10;
    btnLock.Align := alRight;
    btnLock.Caption := '<i class="material-icons" style="font-size:40px;">lock</i>';
    btnLock.ChildOrder := 1;
    btnLock.ElementClassName := 'btn btn-danger';
    btnLock.ElementFont := efCSS;
    btnLock.HeightPercent := 100.000000000000000000;
    btnLock.WidthPercent := 100.000000000000000000;
    SetEvent(btnLock, Self, 'OnClick', 'btnLockClick');
    btnMap.SetParentComponent(panFooter);
    btnMap.Name := 'btnMap';
    btnMap.AlignWithMargins := True;
    btnMap.Left := 454;
    btnMap.Top := 3;
    btnMap.Width := 100;
    btnMap.Height := 114;
    btnMap.Margins.Right := 10;
    btnMap.Align := alRight;
    btnMap.Caption := '<i class="material-icons" style="font-size:40px;">map</i>';
    btnMap.ChildOrder := 5;
    btnMap.ElementClassName := 'btn btn-primary';
    btnMap.ElementFont := efCSS;
    btnMap.HeightPercent := 100.000000000000000000;
    btnMap.WidthPercent := 100.000000000000000000;
    SetEvent(btnMap, Self, 'OnClick', 'btnMapClick');
    dsPodrobno.SetParentComponent(Self);
    dsPodrobno.Name := 'dsPodrobno';
    dsPodrobno.DataSet := wdsPodrobno;
    dsPodrobno.Left := 592;
    dsPodrobno.Top := 104;
    wdsPodrobno.SetParentComponent(Self);
    wdsPodrobno.Name := 'wdsPodrobno';
    wdsPodrobno.AfterOpen := wdsPodrobnoAfterOpen;
    SetEvent(wdsPodrobno, Self, 'OnEditError', 'wdsNalogPostError');
    SetEvent(wdsPodrobno, Self, 'OnPostError', 'wdsNalogPostError');
    wdsPodrobno.EntitySetName := 'PrevozniNalogPodrobno';
    wdsPodrobno.Connection := modMain.connServer;
    wdsPodrobno.SubpropsDepth := 1;
    wdsPodrobno.Left := 592;
    wdsPodrobno.Top := 48;
    dsNalog.SetParentComponent(Self);
    dsNalog.Name := 'dsNalog';
    dsNalog.DataSet := wdsNalog;
    dsNalog.Left := 496;
    dsNalog.Top := 104;
    wdsNalog.SetParentComponent(Self);
    wdsNalog.Name := 'wdsNalog';
    wdsNalog.AfterOpen := wdsNalogAfterOpen;
    SetEvent(wdsNalog, Self, 'OnEditError', 'wdsNalogPostError');
    SetEvent(wdsNalog, Self, 'OnPostError', 'wdsNalogPostError');
    wdsNalog.EntitySetName := 'PrevozniNalog';
    wdsNalog.Connection := modMain.connServer;
    wdsNalog.SubpropsDepth := 1;
    wdsNalog.Left := 496;
    wdsNalog.Top := 48;
    wdsNalogid2.SetParentComponent(wdsNalog);
    wdsNalogid2.Name := 'wdsNalogid2';
    wdsNalogid2.FieldName := '$id';
    wdsNalogKontejnerji.SetParentComponent(wdsNalog);
    wdsNalogKontejnerji.Name := 'wdsNalogKontejnerji';
    wdsNalogKontejnerji.FieldName := 'Kontejnerji';
    wdsNalogStranka.SetParentComponent(wdsNalog);
    wdsNalogStranka.Name := 'wdsNalogStranka';
    wdsNalogStranka.FieldName := 'Stranka';
    wdsNalogStrankaLokacije.SetParentComponent(wdsNalog);
    wdsNalogStrankaLokacije.Name := 'wdsNalogStrankaLokacije';
    wdsNalogStrankaLokacije.FieldName := 'Stranka.Lokacije';
    wdsNalogStrankaLokacije.ReadOnly := True;
    wdsNalogStrankaId.SetParentComponent(wdsNalog);
    wdsNalogStrankaId.Name := 'wdsNalogStrankaId';
    wdsNalogStrankaId.FieldName := 'Stranka.Id';
    wdsNalogStrankaZunanjaOznaka.SetParentComponent(wdsNalog);
    wdsNalogStrankaZunanjaOznaka.Name := 'wdsNalogStrankaZunanjaOznaka';
    wdsNalogStrankaZunanjaOznaka.FieldName := 'Stranka.ZunanjaOznaka';
    wdsNalogStrankaAktivna.SetParentComponent(wdsNalog);
    wdsNalogStrankaAktivna.Name := 'wdsNalogStrankaAktivna';
    wdsNalogStrankaAktivna.FieldName := 'Stranka.Aktivna';
    wdsNalogStrankaObracun.SetParentComponent(wdsNalog);
    wdsNalogStrankaObracun.Name := 'wdsNalogStrankaObracun';
    wdsNalogStrankaObracun.FieldName := 'Stranka.Obracun';
    wdsNalogStrankaNaziv.SetParentComponent(wdsNalog);
    wdsNalogStrankaNaziv.Name := 'wdsNalogStrankaNaziv';
    wdsNalogStrankaNaziv.FieldName := 'Stranka.Naziv';
    wdsNalogStrankaNaziv.Size := 50;
    wdsNalogStrankaNaslov.SetParentComponent(wdsNalog);
    wdsNalogStrankaNaslov.Name := 'wdsNalogStrankaNaslov';
    wdsNalogStrankaNaslov.FieldName := 'Stranka.Naslov';
    wdsNalogStrankaNaslov.Size := 50;
    wdsNalogStrankaPosta.SetParentComponent(wdsNalog);
    wdsNalogStrankaPosta.Name := 'wdsNalogStrankaPosta';
    wdsNalogStrankaPosta.FieldName := 'Stranka.Posta';
    wdsNalogStrankaPosta.Size := 15;
    wdsNalogStrankaKraj.SetParentComponent(wdsNalog);
    wdsNalogStrankaKraj.Name := 'wdsNalogStrankaKraj';
    wdsNalogStrankaKraj.FieldName := 'Stranka.Kraj';
    wdsNalogStrankaKraj.Size := 50;
    wdsNalogStrankaDavSt.SetParentComponent(wdsNalog);
    wdsNalogStrankaDavSt.Name := 'wdsNalogStrankaDavSt';
    wdsNalogStrankaDavSt.FieldName := 'Stranka.DavSt';
    wdsNalogStrankaDavSt.Size := 15;
    wdsNalogStrankaTel.SetParentComponent(wdsNalog);
    wdsNalogStrankaTel.Name := 'wdsNalogStrankaTel';
    wdsNalogStrankaTel.FieldName := 'Stranka.Tel';
    wdsNalogStrankaOpombe.SetParentComponent(wdsNalog);
    wdsNalogStrankaOpombe.Name := 'wdsNalogStrankaOpombe';
    wdsNalogStrankaOpombe.FieldName := 'Stranka.Opombe';
    wdsNalogStrankaOpombe.Size := 250;
    wdsNalogStrankaMesecniObracun.SetParentComponent(wdsNalog);
    wdsNalogStrankaMesecniObracun.Name := 'wdsNalogStrankaMesecniObracun';
    wdsNalogStrankaMesecniObracun.FieldName := 'Stranka.MesecniObracun';
    wdsNalogStatus.SetParentComponent(wdsNalog);
    wdsNalogStatus.Name := 'wdsNalogStatus';
    wdsNalogStatus.FieldName := 'Status';
    wdsNalogStatusId.SetParentComponent(wdsNalog);
    wdsNalogStatusId.Name := 'wdsNalogStatusId';
    wdsNalogStatusId.FieldName := 'Status.Id';
    wdsNalogStatusOpis.SetParentComponent(wdsNalog);
    wdsNalogStatusOpis.Name := 'wdsNalogStatusOpis';
    wdsNalogStatusOpis.FieldName := 'Status.Opis';
    wdsNalogStatusOpis.Size := 15;
    wdsNalogVoznikDostava.SetParentComponent(wdsNalog);
    wdsNalogVoznikDostava.Name := 'wdsNalogVoznikDostava';
    wdsNalogVoznikDostava.FieldName := 'VoznikDostava';
    wdsNalogVoznikDostavaVloga.SetParentComponent(wdsNalog);
    wdsNalogVoznikDostavaVloga.Name := 'wdsNalogVoznikDostavaVloga';
    wdsNalogVoznikDostavaVloga.FieldName := 'VoznikDostava.Vloga';
    wdsNalogVoznikDostavaVlogaId.SetParentComponent(wdsNalog);
    wdsNalogVoznikDostavaVlogaId.Name := 'wdsNalogVoznikDostavaVlogaId';
    wdsNalogVoznikDostavaVlogaId.FieldName := 'VoznikDostava.Vloga.Id';
    wdsNalogVoznikDostavaVlogaOpis.SetParentComponent(wdsNalog);
    wdsNalogVoznikDostavaVlogaOpis.Name := 'wdsNalogVoznikDostavaVlogaOpis';
    wdsNalogVoznikDostavaVlogaOpis.FieldName := 'VoznikDostava.Vloga.Opis';
    wdsNalogVoznikDostavaVlogaOpis.Size := 50;
    wdsNalogVoznikDostavaId.SetParentComponent(wdsNalog);
    wdsNalogVoznikDostavaId.Name := 'wdsNalogVoznikDostavaId';
    wdsNalogVoznikDostavaId.FieldName := 'VoznikDostava.Id';
    wdsNalogVoznikDostavaUporabniskoIme.SetParentComponent(wdsNalog);
    wdsNalogVoznikDostavaUporabniskoIme.Name := 'wdsNalogVoznikDostavaUporabniskoIme';
    wdsNalogVoznikDostavaUporabniskoIme.FieldName := 'VoznikDostava.UporabniskoIme';
    wdsNalogVoznikDostavaUporabniskoIme.Size := 50;
    wdsNalogVoznikDostavaGeslo.SetParentComponent(wdsNalog);
    wdsNalogVoznikDostavaGeslo.Name := 'wdsNalogVoznikDostavaGeslo';
    wdsNalogVoznikDostavaGeslo.FieldName := 'VoznikDostava.Geslo';
    wdsNalogVoznikDostavaGeslo.Size := 50;
    wdsNalogVoznikDostavaPIN.SetParentComponent(wdsNalog);
    wdsNalogVoznikDostavaPIN.Name := 'wdsNalogVoznikDostavaPIN';
    wdsNalogVoznikDostavaPIN.FieldName := 'VoznikDostava.PIN';
    wdsNalogVoznikDostavaPIN.Size := 10;
    wdsNalogVoznikDostavaIme.SetParentComponent(wdsNalog);
    wdsNalogVoznikDostavaIme.Name := 'wdsNalogVoznikDostavaIme';
    wdsNalogVoznikDostavaIme.FieldName := 'VoznikDostava.Ime';
    wdsNalogVoznikDostavaIme.Size := 50;
    wdsNalogVoznikDostavaPriimek.SetParentComponent(wdsNalog);
    wdsNalogVoznikDostavaPriimek.Name := 'wdsNalogVoznikDostavaPriimek';
    wdsNalogVoznikDostavaPriimek.FieldName := 'VoznikDostava.Priimek';
    wdsNalogVoznikDostavaPriimek.Size := 50;
    wdsNalogVoznikDostavaAktiven.SetParentComponent(wdsNalog);
    wdsNalogVoznikDostavaAktiven.Name := 'wdsNalogVoznikDostavaAktiven';
    wdsNalogVoznikDostavaAktiven.FieldName := 'VoznikDostava.Aktiven';
    wdsNalogVoznikOdvoz.SetParentComponent(wdsNalog);
    wdsNalogVoznikOdvoz.Name := 'wdsNalogVoznikOdvoz';
    wdsNalogVoznikOdvoz.FieldName := 'VoznikOdvoz';
    wdsNalogVoznikOdvozVloga.SetParentComponent(wdsNalog);
    wdsNalogVoznikOdvozVloga.Name := 'wdsNalogVoznikOdvozVloga';
    wdsNalogVoznikOdvozVloga.FieldName := 'VoznikOdvoz.Vloga';
    wdsNalogVoznikOdvozVlogaId.SetParentComponent(wdsNalog);
    wdsNalogVoznikOdvozVlogaId.Name := 'wdsNalogVoznikOdvozVlogaId';
    wdsNalogVoznikOdvozVlogaId.FieldName := 'VoznikOdvoz.Vloga.Id';
    wdsNalogVoznikOdvozVlogaOpis.SetParentComponent(wdsNalog);
    wdsNalogVoznikOdvozVlogaOpis.Name := 'wdsNalogVoznikOdvozVlogaOpis';
    wdsNalogVoznikOdvozVlogaOpis.FieldName := 'VoznikOdvoz.Vloga.Opis';
    wdsNalogVoznikOdvozVlogaOpis.Size := 50;
    wdsNalogVoznikOdvozId.SetParentComponent(wdsNalog);
    wdsNalogVoznikOdvozId.Name := 'wdsNalogVoznikOdvozId';
    wdsNalogVoznikOdvozId.FieldName := 'VoznikOdvoz.Id';
    wdsNalogVoznikOdvozUporabniskoIme.SetParentComponent(wdsNalog);
    wdsNalogVoznikOdvozUporabniskoIme.Name := 'wdsNalogVoznikOdvozUporabniskoIme';
    wdsNalogVoznikOdvozUporabniskoIme.FieldName := 'VoznikOdvoz.UporabniskoIme';
    wdsNalogVoznikOdvozUporabniskoIme.Size := 50;
    wdsNalogVoznikOdvozGeslo.SetParentComponent(wdsNalog);
    wdsNalogVoznikOdvozGeslo.Name := 'wdsNalogVoznikOdvozGeslo';
    wdsNalogVoznikOdvozGeslo.FieldName := 'VoznikOdvoz.Geslo';
    wdsNalogVoznikOdvozGeslo.Size := 50;
    wdsNalogVoznikOdvozPIN.SetParentComponent(wdsNalog);
    wdsNalogVoznikOdvozPIN.Name := 'wdsNalogVoznikOdvozPIN';
    wdsNalogVoznikOdvozPIN.FieldName := 'VoznikOdvoz.PIN';
    wdsNalogVoznikOdvozPIN.Size := 10;
    wdsNalogVoznikOdvozIme.SetParentComponent(wdsNalog);
    wdsNalogVoznikOdvozIme.Name := 'wdsNalogVoznikOdvozIme';
    wdsNalogVoznikOdvozIme.FieldName := 'VoznikOdvoz.Ime';
    wdsNalogVoznikOdvozIme.Size := 50;
    wdsNalogVoznikOdvozPriimek.SetParentComponent(wdsNalog);
    wdsNalogVoznikOdvozPriimek.Name := 'wdsNalogVoznikOdvozPriimek';
    wdsNalogVoznikOdvozPriimek.FieldName := 'VoznikOdvoz.Priimek';
    wdsNalogVoznikOdvozPriimek.Size := 50;
    wdsNalogVoznikOdvozAktiven.SetParentComponent(wdsNalog);
    wdsNalogVoznikOdvozAktiven.Name := 'wdsNalogVoznikOdvozAktiven';
    wdsNalogVoznikOdvozAktiven.FieldName := 'VoznikOdvoz.Aktiven';
    wdsNalogVozilo.SetParentComponent(wdsNalog);
    wdsNalogVozilo.Name := 'wdsNalogVozilo';
    wdsNalogVozilo.FieldName := 'Vozilo';
    wdsNalogVozilo.Required := True;
    wdsNalogVoziloTipVozila.SetParentComponent(wdsNalog);
    wdsNalogVoziloTipVozila.Name := 'wdsNalogVoziloTipVozila';
    wdsNalogVoziloTipVozila.FieldName := 'Vozilo.TipVozila';
    wdsNalogVoziloTipVozilaId.SetParentComponent(wdsNalog);
    wdsNalogVoziloTipVozilaId.Name := 'wdsNalogVoziloTipVozilaId';
    wdsNalogVoziloTipVozilaId.FieldName := 'Vozilo.TipVozila.Id';
    wdsNalogVoziloTipVozilaOpis.SetParentComponent(wdsNalog);
    wdsNalogVoziloTipVozilaOpis.Name := 'wdsNalogVoziloTipVozilaOpis';
    wdsNalogVoziloTipVozilaOpis.FieldName := 'Vozilo.TipVozila.Opis';
    wdsNalogVoziloTipVozilaOpis.Size := 50;
    wdsNalogVoziloVrstaOdvoza.SetParentComponent(wdsNalog);
    wdsNalogVoziloVrstaOdvoza.Name := 'wdsNalogVoziloVrstaOdvoza';
    wdsNalogVoziloVrstaOdvoza.FieldName := 'Vozilo.VrstaOdvoza';
    wdsNalogVoziloVrstaOdvozaId.SetParentComponent(wdsNalog);
    wdsNalogVoziloVrstaOdvozaId.Name := 'wdsNalogVoziloVrstaOdvozaId';
    wdsNalogVoziloVrstaOdvozaId.FieldName := 'Vozilo.VrstaOdvoza.Id';
    wdsNalogVoziloVrstaOdvozaOpis.SetParentComponent(wdsNalog);
    wdsNalogVoziloVrstaOdvozaOpis.Name := 'wdsNalogVoziloVrstaOdvozaOpis';
    wdsNalogVoziloVrstaOdvozaOpis.FieldName := 'Vozilo.VrstaOdvoza.Opis';
    wdsNalogVoziloVrstaOdvozaOpis.Size := 50;
    wdsNalogVoziloVrstaOdvozaParams.SetParentComponent(wdsNalog);
    wdsNalogVoziloVrstaOdvozaParams.Name := 'wdsNalogVoziloVrstaOdvozaParams';
    wdsNalogVoziloVrstaOdvozaParams.FieldName := 'Vozilo.VrstaOdvoza.Params';
    wdsNalogVoziloVrstaOdvozaParams.Size := 250;
    wdsNalogVoziloVrstaOdvozaKolicina.SetParentComponent(wdsNalog);
    wdsNalogVoziloVrstaOdvozaKolicina.Name := 'wdsNalogVoziloVrstaOdvozaKolicina';
    wdsNalogVoziloVrstaOdvozaKolicina.FieldName := 'Vozilo.VrstaOdvoza.Kolicina';
    wdsNalogVoziloVrstaOdvozaVolumen.SetParentComponent(wdsNalog);
    wdsNalogVoziloVrstaOdvozaVolumen.Name := 'wdsNalogVoziloVrstaOdvozaVolumen';
    wdsNalogVoziloVrstaOdvozaVolumen.FieldName := 'Vozilo.VrstaOdvoza.Volumen';
    wdsNalogVoziloVrstaOdvozaTeza.SetParentComponent(wdsNalog);
    wdsNalogVoziloVrstaOdvozaTeza.Name := 'wdsNalogVoziloVrstaOdvozaTeza';
    wdsNalogVoziloVrstaOdvozaTeza.FieldName := 'Vozilo.VrstaOdvoza.Teza';
    wdsNalogVoziloOdvozi.SetParentComponent(wdsNalog);
    wdsNalogVoziloOdvozi.Name := 'wdsNalogVoziloOdvozi';
    wdsNalogVoziloOdvozi.FieldName := 'Vozilo.Odvozi';
    wdsNalogVoziloOdvozi.ReadOnly := True;
    wdsNalogVoziloId.SetParentComponent(wdsNalog);
    wdsNalogVoziloId.Name := 'wdsNalogVoziloId';
    wdsNalogVoziloId.FieldName := 'Vozilo.Id';
    wdsNalogVoziloRegistrskaSt.SetParentComponent(wdsNalog);
    wdsNalogVoziloRegistrskaSt.Name := 'wdsNalogVoziloRegistrskaSt';
    wdsNalogVoziloRegistrskaSt.FieldName := 'Vozilo.RegistrskaSt';
    wdsNalogVoziloRegistrskaSt.Size := 50;
    wdsNalogVoziloAktivno.SetParentComponent(wdsNalog);
    wdsNalogVoziloAktivno.Name := 'wdsNalogVoziloAktivno';
    wdsNalogVoziloAktivno.FieldName := 'Vozilo.Aktivno';
    wdsNalogVoziloLetnik.SetParentComponent(wdsNalog);
    wdsNalogVoziloLetnik.Name := 'wdsNalogVoziloLetnik';
    wdsNalogVoziloLetnik.FieldName := 'Vozilo.Letnik';
    wdsNalogVoziloPrvaRegistracija.SetParentComponent(wdsNalog);
    wdsNalogVoziloPrvaRegistracija.Name := 'wdsNalogVoziloPrvaRegistracija';
    wdsNalogVoziloPrvaRegistracija.FieldName := 'Vozilo.PrvaRegistracija';
    wdsNalogVoziloZnamka.SetParentComponent(wdsNalog);
    wdsNalogVoziloZnamka.Name := 'wdsNalogVoziloZnamka';
    wdsNalogVoziloZnamka.FieldName := 'Vozilo.Znamka';
    wdsNalogVoziloZnamka.Size := 50;
    wdsNalogVoziloTip.SetParentComponent(wdsNalog);
    wdsNalogVoziloTip.Name := 'wdsNalogVoziloTip';
    wdsNalogVoziloTip.FieldName := 'Vozilo.Tip';
    wdsNalogVoziloTip.Size := 50;
    wdsNalogVoziloMocKW.SetParentComponent(wdsNalog);
    wdsNalogVoziloMocKW.Name := 'wdsNalogVoziloMocKW';
    wdsNalogVoziloMocKW.FieldName := 'Vozilo.MocKW';
    wdsNalogVoziloProstornina.SetParentComponent(wdsNalog);
    wdsNalogVoziloProstornina.Name := 'wdsNalogVoziloProstornina';
    wdsNalogVoziloProstornina.FieldName := 'Vozilo.Prostornina';
    wdsNalogVoziloStSasije.SetParentComponent(wdsNalog);
    wdsNalogVoziloStSasije.Name := 'wdsNalogVoziloStSasije';
    wdsNalogVoziloStSasije.FieldName := 'Vozilo.StSasije';
    wdsNalogVoziloStSasije.Size := 50;
    wdsNalogVoziloMasaVozila.SetParentComponent(wdsNalog);
    wdsNalogVoziloMasaVozila.Name := 'wdsNalogVoziloMasaVozila';
    wdsNalogVoziloMasaVozila.FieldName := 'Vozilo.MasaVozila';
    wdsNalogVoziloMasaNajvecja.SetParentComponent(wdsNalog);
    wdsNalogVoziloMasaNajvecja.Name := 'wdsNalogVoziloMasaNajvecja';
    wdsNalogVoziloMasaNajvecja.FieldName := 'Vozilo.MasaNajvecja';
    wdsNalogVoziloPrtljaznikVolM3.SetParentComponent(wdsNalog);
    wdsNalogVoziloPrtljaznikVolM3.Name := 'wdsNalogVoziloPrtljaznikVolM3';
    wdsNalogVoziloPrtljaznikVolM3.FieldName := 'Vozilo.PrtljaznikVolM3';
    wdsNalogVoziloOpombe.SetParentComponent(wdsNalog);
    wdsNalogVoziloOpombe.Name := 'wdsNalogVoziloOpombe';
    wdsNalogVoziloOpombe.FieldName := 'Vozilo.Opombe';
    wdsNalogVoziloOpombe.Size := 255;
    wdsNalogVoziloStanjeKm.SetParentComponent(wdsNalog);
    wdsNalogVoziloStanjeKm.Name := 'wdsNalogVoziloStanjeKm';
    wdsNalogVoziloStanjeKm.FieldName := 'Vozilo.StanjeKm';
    wdsNalogId.SetParentComponent(wdsNalog);
    wdsNalogId.Name := 'wdsNalogId';
    wdsNalogId.FieldName := 'Id';
    wdsNalogId.Required := True;
    wdsNalogLokacijaOpis.SetParentComponent(wdsNalog);
    wdsNalogLokacijaOpis.Name := 'wdsNalogLokacijaOpis';
    wdsNalogLokacijaOpis.FieldName := 'LokacijaOpis';
    wdsNalogLokacijaOpis.Required := True;
    wdsNalogLokacijaOpis.Size := 250;
    wdsNalogCasVpisa.SetParentComponent(wdsNalog);
    wdsNalogCasVpisa.Name := 'wdsNalogCasVpisa';
    wdsNalogCasVpisa.FieldName := 'CasVpisa';
    wdsNalogDatumDostave.SetParentComponent(wdsNalog);
    wdsNalogDatumDostave.Name := 'wdsNalogDatumDostave';
    wdsNalogDatumDostave.FieldName := 'DatumDostave';
    wdsNalogDatumDostave.Required := True;
    wdsNalogDatumOdvoza.SetParentComponent(wdsNalog);
    wdsNalogDatumOdvoza.Name := 'wdsNalogDatumOdvoza';
    wdsNalogDatumOdvoza.FieldName := 'DatumOdvoza';
    wdsNalogDatumOdvoza.Required := True;
    wdsNalogPodpisStranke.SetParentComponent(wdsNalog);
    wdsNalogPodpisStranke.Name := 'wdsNalogPodpisStranke';
    wdsNalogPodpisStranke.FieldName := 'PodpisStranke';
    wdsNalogMesecniObracun.SetParentComponent(wdsNalog);
    wdsNalogMesecniObracun.Name := 'wdsNalogMesecniObracun';
    wdsNalogMesecniObracun.FieldName := 'MesecniObracun';
    wdsNalogMesecniObracun.Required := True;
    wdsNalogOpombe.SetParentComponent(wdsNalog);
    wdsNalogOpombe.Name := 'wdsNalogOpombe';
    wdsNalogOpombe.FieldName := 'Opombe';
    wdsNalogOpombe.Size := 50;
    wdsNalogOpombeVoznik.SetParentComponent(wdsNalog);
    wdsNalogOpombeVoznik.Name := 'wdsNalogOpombeVoznik';
    wdsNalogOpombeVoznik.FieldName := 'OpombeVoznik';
    wdsNalogOpombeVoznik.Size := 200;
    wdsNalogLat.SetParentComponent(wdsNalog);
    wdsNalogLat.Name := 'wdsNalogLat';
    wdsNalogLat.FieldName := 'Lat';
    wdsNalogLon.SetParentComponent(wdsNalog);
    wdsNalogLon.Name := 'wdsNalogLon';
    wdsNalogLon.FieldName := 'Lon';
    wdsNalogTipNaloga.SetParentComponent(wdsNalog);
    wdsNalogTipNaloga.Name := 'wdsNalogTipNaloga';
    wdsNalogTipNaloga.FieldName := 'TipNaloga';
    wdsNalogTipNalogaId.SetParentComponent(wdsNalog);
    wdsNalogTipNalogaId.Name := 'wdsNalogTipNalogaId';
    wdsNalogTipNalogaId.FieldName := 'TipNaloga.Id';
    wdsNalogTipNalogaOpis.SetParentComponent(wdsNalog);
    wdsNalogTipNalogaOpis.Name := 'wdsNalogTipNalogaOpis';
    wdsNalogTipNalogaOpis.FieldName := 'TipNaloga.Opis';
    wdsNalogTipNalogaOpis.Size := 50;
    tmrRefresh.SetParentComponent(Self);
    tmrRefresh.Name := 'tmrRefresh';
    tmrRefresh.Enabled := False;
    tmrRefresh.Interval := 200;
    SetEvent(tmrRefresh, Self, 'OnTimer', 'tmrRefreshTimer');
    tmrRefresh.Left := 688;
    tmrRefresh.Top := 48;
  finally
    panInfo.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    lblLokacija.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebDBLabel4.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    panPosode.AfterLoadDFMValues;
    gridPodrobno.AfterLoadDFMValues;
    panSign.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    signStranka.AfterLoadDFMValues;
    panNotes.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    memoNotes.AfterLoadDFMValues;
    panNotesButtons.AfterLoadDFMValues;
    btnOpomba1.AfterLoadDFMValues;
    btnOpomba2.AfterLoadDFMValues;
    btnOpomba3.AfterLoadDFMValues;
    btnDelNote.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnTransfer.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    btnNotes.AfterLoadDFMValues;
    btnSign.AfterLoadDFMValues;
    btnLock.AfterLoadDFMValues;
    btnMap.AfterLoadDFMValues;
    dsPodrobno.AfterLoadDFMValues;
    wdsPodrobno.AfterLoadDFMValues;
    dsNalog.AfterLoadDFMValues;
    wdsNalog.AfterLoadDFMValues;
    wdsNalogid2.AfterLoadDFMValues;
    wdsNalogKontejnerji.AfterLoadDFMValues;
    wdsNalogStranka.AfterLoadDFMValues;
    wdsNalogStrankaLokacije.AfterLoadDFMValues;
    wdsNalogStrankaId.AfterLoadDFMValues;
    wdsNalogStrankaZunanjaOznaka.AfterLoadDFMValues;
    wdsNalogStrankaAktivna.AfterLoadDFMValues;
    wdsNalogStrankaObracun.AfterLoadDFMValues;
    wdsNalogStrankaNaziv.AfterLoadDFMValues;
    wdsNalogStrankaNaslov.AfterLoadDFMValues;
    wdsNalogStrankaPosta.AfterLoadDFMValues;
    wdsNalogStrankaKraj.AfterLoadDFMValues;
    wdsNalogStrankaDavSt.AfterLoadDFMValues;
    wdsNalogStrankaTel.AfterLoadDFMValues;
    wdsNalogStrankaOpombe.AfterLoadDFMValues;
    wdsNalogStrankaMesecniObracun.AfterLoadDFMValues;
    wdsNalogStatus.AfterLoadDFMValues;
    wdsNalogStatusId.AfterLoadDFMValues;
    wdsNalogStatusOpis.AfterLoadDFMValues;
    wdsNalogVoznikDostava.AfterLoadDFMValues;
    wdsNalogVoznikDostavaVloga.AfterLoadDFMValues;
    wdsNalogVoznikDostavaVlogaId.AfterLoadDFMValues;
    wdsNalogVoznikDostavaVlogaOpis.AfterLoadDFMValues;
    wdsNalogVoznikDostavaId.AfterLoadDFMValues;
    wdsNalogVoznikDostavaUporabniskoIme.AfterLoadDFMValues;
    wdsNalogVoznikDostavaGeslo.AfterLoadDFMValues;
    wdsNalogVoznikDostavaPIN.AfterLoadDFMValues;
    wdsNalogVoznikDostavaIme.AfterLoadDFMValues;
    wdsNalogVoznikDostavaPriimek.AfterLoadDFMValues;
    wdsNalogVoznikDostavaAktiven.AfterLoadDFMValues;
    wdsNalogVoznikOdvoz.AfterLoadDFMValues;
    wdsNalogVoznikOdvozVloga.AfterLoadDFMValues;
    wdsNalogVoznikOdvozVlogaId.AfterLoadDFMValues;
    wdsNalogVoznikOdvozVlogaOpis.AfterLoadDFMValues;
    wdsNalogVoznikOdvozId.AfterLoadDFMValues;
    wdsNalogVoznikOdvozUporabniskoIme.AfterLoadDFMValues;
    wdsNalogVoznikOdvozGeslo.AfterLoadDFMValues;
    wdsNalogVoznikOdvozPIN.AfterLoadDFMValues;
    wdsNalogVoznikOdvozIme.AfterLoadDFMValues;
    wdsNalogVoznikOdvozPriimek.AfterLoadDFMValues;
    wdsNalogVoznikOdvozAktiven.AfterLoadDFMValues;
    wdsNalogVozilo.AfterLoadDFMValues;
    wdsNalogVoziloTipVozila.AfterLoadDFMValues;
    wdsNalogVoziloTipVozilaId.AfterLoadDFMValues;
    wdsNalogVoziloTipVozilaOpis.AfterLoadDFMValues;
    wdsNalogVoziloVrstaOdvoza.AfterLoadDFMValues;
    wdsNalogVoziloVrstaOdvozaId.AfterLoadDFMValues;
    wdsNalogVoziloVrstaOdvozaOpis.AfterLoadDFMValues;
    wdsNalogVoziloVrstaOdvozaParams.AfterLoadDFMValues;
    wdsNalogVoziloVrstaOdvozaKolicina.AfterLoadDFMValues;
    wdsNalogVoziloVrstaOdvozaVolumen.AfterLoadDFMValues;
    wdsNalogVoziloVrstaOdvozaTeza.AfterLoadDFMValues;
    wdsNalogVoziloOdvozi.AfterLoadDFMValues;
    wdsNalogVoziloId.AfterLoadDFMValues;
    wdsNalogVoziloRegistrskaSt.AfterLoadDFMValues;
    wdsNalogVoziloAktivno.AfterLoadDFMValues;
    wdsNalogVoziloLetnik.AfterLoadDFMValues;
    wdsNalogVoziloPrvaRegistracija.AfterLoadDFMValues;
    wdsNalogVoziloZnamka.AfterLoadDFMValues;
    wdsNalogVoziloTip.AfterLoadDFMValues;
    wdsNalogVoziloMocKW.AfterLoadDFMValues;
    wdsNalogVoziloProstornina.AfterLoadDFMValues;
    wdsNalogVoziloStSasije.AfterLoadDFMValues;
    wdsNalogVoziloMasaVozila.AfterLoadDFMValues;
    wdsNalogVoziloMasaNajvecja.AfterLoadDFMValues;
    wdsNalogVoziloPrtljaznikVolM3.AfterLoadDFMValues;
    wdsNalogVoziloOpombe.AfterLoadDFMValues;
    wdsNalogVoziloStanjeKm.AfterLoadDFMValues;
    wdsNalogId.AfterLoadDFMValues;
    wdsNalogLokacijaOpis.AfterLoadDFMValues;
    wdsNalogCasVpisa.AfterLoadDFMValues;
    wdsNalogDatumDostave.AfterLoadDFMValues;
    wdsNalogDatumOdvoza.AfterLoadDFMValues;
    wdsNalogPodpisStranke.AfterLoadDFMValues;
    wdsNalogMesecniObracun.AfterLoadDFMValues;
    wdsNalogOpombe.AfterLoadDFMValues;
    wdsNalogOpombeVoznik.AfterLoadDFMValues;
    wdsNalogLat.AfterLoadDFMValues;
    wdsNalogLon.AfterLoadDFMValues;
    wdsNalogTipNaloga.AfterLoadDFMValues;
    wdsNalogTipNalogaId.AfterLoadDFMValues;
    wdsNalogTipNalogaOpis.AfterLoadDFMValues;
    tmrRefresh.AfterLoadDFMValues;
  end;
end;

end.
        