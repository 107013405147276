unit Client.Constants;

interface

uses System.SysUtils;

type
  TKoledarNacin = (knPrivzeto, knZgodovina);

  // Eanko kot v Common.Constants, samo da se začne z 0, ker Pas2JS ne podpira začetek z 1 ...
  TNalogTip = (ntRedni, ntIzredni, ntInterni, ntOdvoz);
  TNalogStatus = (nsVpisan, nsVObdelavi, nsZakljucen);
  TKontejnerStatus = (ksVpisan, ksPriStranki, ksNalozen, ksStehtan, ksZakljucen);

var
  Fmt, FmtDisp, FmtDispDT: TFormatSettings;

implementation

initialization

  Fmt := TFormatSettings.Create('en-us');
  Fmt.ShortDateFormat := 'yyyy-mm-dd';

  FmtDisp := TFormatSettings.Create('sl-SI');
  FmtDisp.ShortDateFormat := 'dd.mm.yyyy';

  FmtDispDT := TFormatSettings.Create('sl-SI');
  FmtDispDT.ShortDateFormat := 'dd.mm.yyyy hh:nn';

end.
