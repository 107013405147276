unit Nalogi;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Web, System.Dateutils,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms,
  XData.Web.Connection, Vcl.Grids, WEBLib.Buttons, WEBLib.WebCtrls;

type
  TfrmNalogi = class(TForm)
    tmrFind: TTimer;
    wdsNalogi: TXDataWebDataSet;
    dsNalogi: TDataSource;
    panHeader: TPanel;
    edtFilter: TEdit;
    panFooter: TPanel;
    btnSelect: TButton;
    gridView: TDBGrid;
    btnDelNote: TButton;
    lblIsci: TLabel;
    splitMap: TSplitter;
    btnMove: TButton;
    wdsNalogiDatumDostave: TDateField;
    wdsNalogiStrankaNaziv: TStringField;
    wdsNalogiId: TIntegerField;
    wdsNalogiLokacijaOpis: TStringField;
    wdsNalogiTipNalogaId: TIntegerField;
    procedure tmrFindTimer(Sender: TObject);
    procedure edtFilterChange(Sender: TObject);
    procedure tblStrankeDblClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure wdsNalogiAfterOpen(DataSet: TDataSet);
    procedure WebFormShow(Sender: TObject);
    procedure btnDelNoteClick(Sender: TObject);
    procedure wdsNalogiAfterScroll(DataSet: TDataSet);
    procedure gridViewGetCellClass(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; var AClassName: string);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnMoveClick(Sender: TObject);  // Klic pride iz frmMain
  private
    { Private declarations }
    Starting: boolean;
    datToday: string;

    procedure VrniLokacije(Flt, Srt: string);
    function VrniPozicijo(LatLon: double; PremikM: integer): double;
    procedure ToggleButtons;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings, Client.Constants;

procedure TfrmNalogi.btnDelNoteClick(Sender: TObject);
begin
  edtFilter.Clear;
  edtFilter.SetFocus;
  tmrFindTimer(Sender)
end;

procedure TfrmNalogi.btnMoveClick(Sender: TObject);
begin
  // izberi vozilo, kamor se prenese nalog
  modMain.NastaviNalog(wdsNalogi.FieldByName('Id').AsInteger);
  modMain.NastaviTipNalog(wdsNalogi.FieldByName('TipNaloga.Id').AsInteger);

  frmMain.ShowMenjava;
end;

procedure TfrmNalogi.ToggleButtons;
begin
  btnSelect.Enabled := wdsNalogi.RecordCount > 0;
  btnMove.Enabled := btnSelect.Enabled;
end;

procedure TfrmNalogi.edtFilterChange(Sender: TObject);
begin
  tmrFind.Enabled := false;
  tmrFind.Enabled := true;
end;

(*procedure TfrmNalogi.GetOdvozInfo(Sender: TObject);
begin
  if wdsNalogi.RecordCount<1 then
    exit;

  modMain.NastaviNalog(wdsNalogi.FieldByName('Id').AsInteger);

  modMain.LokacijaLastRow := wdsNalogi.RecNo;

  frmMain.ShowOdvozInfo;
end; *)

procedure TfrmNalogi.tblStrankeDblClick(Sender: TObject);
begin
  if wdsNalogi.RecordCount<1 then
    exit;

  modMain.NastaviNalog(wdsNalogi.FieldByName('Id').AsInteger);
//  modMain.LokacijaLastRow := wdsNalogi.RecNo;

  frmMain.ShowNalog;
end;

procedure TfrmNalogi.tmrFindTimer(Sender: TObject);
var Flt, Srt: string;
    F: TStringList;

  function Filter(FL: TStringList): string;
  var I: integer;
  begin
    Result := '';
    for I := 0 to FL.Count-1 do begin
      if I > 0 then
        Result := Result + ' and ';

      Result := Result + FL[I];
    end;
  end;

begin
  tmrFind.Enabled := false;

  Flt := '';
//  Srt := 'idx';
  Srt := 'DatumDostave,stranka/naziv';

  F := TStringList.Create;
//  F.Add('lokacija/aktivna eq 1');
//  F.Add('vozilo/id eq '+modMain.VoziloId.ToString);
  F.Add('(status/id eq 1 or status/id eq 2)');

  if edtFilter.Text<>'' then begin
    F.Add('(contains(stranka/naziv,%27'+edtFilter.Text+'%27) or contains(lokacijaopis,%27'+edtFilter.Text+'%27) or contains(opombe,%27'+edtFilter.Text+'%27))');
    Srt := 'DatumDostave,stranka/naziv';
  end;
  Flt := Filter(F);
  F.Free;

  modMain.Log(Flt);

  VrniLokacije(Flt, Srt);
  ToggleButtons;
end;

// Klic pride iz frmMain
procedure TfrmNalogi.gridViewGetCellClass(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string; var AClassName: string);
var
  datRow: string;
begin

  datRow   := gridView.Cells[2,ARow];  // npr. 2024-04-16

  if datRow = datToday then begin
    AClassName := 'done';
    modMain.Log(datRow+' = '+datToday);
  end;
end;

procedure TfrmNalogi.VrniLokacije(Flt, Srt: string);
var Qry: string;
begin
  if Flt<>'' then
    Flt := Flt + ' and ';

//  Flt := Flt + 'lokacija/aktivna eq 1 and pot/frakcija eq '+modMain.FrakcijaId.ToString+' and pot/vozilo/id eq '+modMain.VoziloId.ToString+'and pot/danvtednu eq '+modMain.IzbranDan.ToString;
  Flt := Flt + 'vozilo/id eq '+modMain.VoziloId.ToString;
  Qry := '$filter='+Flt+'&$orderby='+Srt+'&$expand=stranka,tipnaloga';

  modMain.Log('Qry='+Qry);

  wdsNalogi.Close;
  wdsNalogi.QueryString := Qry;
  wdsNalogi.Load;
end;

function TfrmNalogi.VrniPozicijo(LatLon: double; PremikM: integer): double;
begin
end;

procedure TfrmNalogi.wdsNalogiAfterOpen(DataSet: TDataSet);
var poz: integer;
    vozilo, title: string;
    RowSelected: integer;
begin
  ToggleButtons;

  // Nastavi naslednjo lokacijo
  if Starting then begin
    if (wdsNalogi.RecordCount >= modMain.NalogLastRow) and (modMain.NalogLastRow > 0) then begin
      wdsNalogi.RecNo := modMain.NalogLastRow;
    end else
      wdsNalogi.Last;
  end;

  // Po osve�itvi daj na isto vrstico
(*  if AppSettings.LocationEnabled then begin
    modMain.log('Locate...');
    RowSelected := StrToIntDef(modMain.GetVar('Pot.Selected'), 1);
    wdsNalogi.Locate('Id', RowSelected, []);
  end; *)

  Starting := false;
end;

procedure TfrmNalogi.wdsNalogiAfterScroll(DataSet: TDataSet);
begin
  modMain.SetVar('Nalog.Selected', wdsNalogi.FieldByName('Id').AsString);
  modMain.NastaviNalog(wdsNalogi.FieldByName('Id').AsInteger);
  modMain.NastaviTipNalog(wdsNalogi.FieldByName('TipNaloga.Id').AsInteger);
end;

procedure TfrmNalogi.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Action := caFree;
end;

procedure TfrmNalogi.WebFormCreate(Sender: TObject);
begin
  Starting := true;

  datToday := DateToStr(modMain.IzbranDatum, Fmt);
  modMain.Log(datToday);

  //tmrFindTimer(nil);
  tmrFind.Enabled := true;

  modMain.Log('Fmt='+Fmt.ShortDateFormat);
end;

procedure TfrmNalogi.WebFormShow(Sender: TObject);
begin
//  gridView.ColWidths[0] := Round(gridView.Width * 0.5)-2;
//  gridView.ColWidths[1] := Round(gridView.Width * 0.5)-2;

end;

procedure TfrmNalogi.LoadDFMValues;
begin
  inherited LoadDFMValues;

  panHeader := TPanel.Create(Self);
  lblIsci := TLabel.Create(Self);
  edtFilter := TEdit.Create(Self);
  btnDelNote := TButton.Create(Self);
  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnMove := TButton.Create(Self);
  gridView := TDBGrid.Create(Self);
  splitMap := TSplitter.Create(Self);
  tmrFind := TTimer.Create(Self);
  wdsNalogi := TXDataWebDataSet.Create(Self);
  wdsNalogiId := TIntegerField.Create(Self);
  wdsNalogiDatumDostave := TDateField.Create(Self);
  wdsNalogiStrankaNaziv := TStringField.Create(Self);
  wdsNalogiLokacijaOpis := TStringField.Create(Self);
  wdsNalogiTipNalogaId := TIntegerField.Create(Self);
  dsNalogi := TDataSource.Create(Self);

  panHeader.BeforeLoadDFMValues;
  lblIsci.BeforeLoadDFMValues;
  edtFilter.BeforeLoadDFMValues;
  btnDelNote.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnMove.BeforeLoadDFMValues;
  gridView.BeforeLoadDFMValues;
  splitMap.BeforeLoadDFMValues;
  tmrFind.BeforeLoadDFMValues;
  wdsNalogi.BeforeLoadDFMValues;
  wdsNalogiId.BeforeLoadDFMValues;
  wdsNalogiDatumDostave.BeforeLoadDFMValues;
  wdsNalogiStrankaNaziv.BeforeLoadDFMValues;
  wdsNalogiLokacijaOpis.BeforeLoadDFMValues;
  wdsNalogiTipNalogaId.BeforeLoadDFMValues;
  dsNalogi.BeforeLoadDFMValues;
  try
    Name := 'frmNalogi';
    Width := 847;
    Height := 865;
    Align := alClient;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    panHeader.SetParentComponent(Self);
    panHeader.Name := 'panHeader';
    panHeader.Left := 0;
    panHeader.Top := 0;
    panHeader.Width := 847;
    panHeader.Height := 91;
    panHeader.Align := alTop;
    panHeader.BorderStyle := bsNone;
    panHeader.ChildOrder := 3;
    panHeader.Color := clWhite;
    panHeader.ElementBodyClassName := 'text';
    panHeader.Padding.Left := 5;
    panHeader.Padding.Top := 5;
    panHeader.Padding.Right := 5;
    panHeader.Padding.Bottom := 5;
    panHeader.TabOrder := 1;
    lblIsci.SetParentComponent(panHeader);
    lblIsci.Name := 'lblIsci';
    lblIsci.Left := 5;
    lblIsci.Top := 5;
    lblIsci.Width := 60;
    lblIsci.Height := 81;
    lblIsci.Align := alLeft;
    lblIsci.AutoSize := False;
    lblIsci.Caption := 'I'#353#269'i';
    lblIsci.ElementClassName := 'fs-1';
    lblIsci.ElementFont := efCSS;
    lblIsci.Font.Charset := DEFAULT_CHARSET;
    lblIsci.Font.Color := clWindowText;
    lblIsci.Font.Height := -21;
    lblIsci.Font.Name := 'Tahoma';
    lblIsci.Font.Style := [];
    lblIsci.HeightPercent := 100.000000000000000000;
    lblIsci.Layout := tlCenter;
    lblIsci.ParentFont := False;
    lblIsci.WidthPercent := 100.000000000000000000;
    edtFilter.SetParentComponent(panHeader);
    edtFilter.Name := 'edtFilter';
    edtFilter.AlignWithMargins := True;
    edtFilter.Left := 68;
    edtFilter.Top := 10;
    edtFilter.Width := 678;
    edtFilter.Height := 71;
    edtFilter.Margins.Top := 5;
    edtFilter.Margins.Bottom := 5;
    edtFilter.Align := alClient;
    edtFilter.CharCase := wecUpperCase;
    edtFilter.ChildOrder := 3;
    edtFilter.ElementClassName := 'fs-1';
    edtFilter.ElementFont := efCSS;
    edtFilter.HeightPercent := 100.000000000000000000;
    edtFilter.RequiredText := 'I'#353#269'i...';
    edtFilter.WidthPercent := 100.000000000000000000;
    SetEvent(edtFilter, Self, 'OnChange', 'edtFilterChange');
    btnDelNote.SetParentComponent(panHeader);
    btnDelNote.Name := 'btnDelNote';
    btnDelNote.AlignWithMargins := True;
    btnDelNote.Left := 752;
    btnDelNote.Top := 8;
    btnDelNote.Width := 80;
    btnDelNote.Height := 75;
    btnDelNote.Margins.Right := 10;
    btnDelNote.Align := alRight;
    btnDelNote.Caption := '<i class="material-icons">backspace</i>';
    btnDelNote.ChildOrder := 2;
    btnDelNote.ElementClassName := 'btn btn-xxl btn-primary';
    btnDelNote.ElementFont := efCSS;
    btnDelNote.HeightPercent := 100.000000000000000000;
    btnDelNote.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelNote, Self, 'OnClick', 'btnDelNoteClick');
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 745;
    panFooter.Width := 847;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    panFooter.Padding.Top := 5;
    panFooter.TabOrder := 2;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 672;
    btnSelect.Top := 10;
    btnSelect.Width := 165;
    btnSelect.Height := 107;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-xxl btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.Enabled := False;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'tblStrankeDblClick');
    btnMove.SetParentComponent(panFooter);
    btnMove.Name := 'btnMove';
    btnMove.AlignWithMargins := True;
    btnMove.Left := 10;
    btnMove.Top := 8;
    btnMove.Width := 111;
    btnMove.Height := 109;
    btnMove.Margins.Left := 10;
    btnMove.Margins.Right := 10;
    btnMove.Align := alLeft;
    btnMove.Caption := '<i class="material-icons" style="font-size:40px;">move_up</i>';
    btnMove.ChildOrder := 1;
    btnMove.ElementClassName := 'btn btn-primary';
    btnMove.ElementFont := efCSS;
    btnMove.HeightPercent := 100.000000000000000000;
    btnMove.WidthPercent := 100.000000000000000000;
    SetEvent(btnMove, Self, 'OnClick', 'btnMoveClick');
    gridView.SetParentComponent(Self);
    gridView.Name := 'gridView';
    gridView.Left := 0;
    gridView.Top := 91;
    gridView.Width := 847;
    gridView.Height := 649;
    gridView.Align := alClient;
    gridView.Color := clWhite;
    gridView.Columns.Clear;
    with gridView.Columns.Add do
    begin
      DataField := 'DatumDostave';
      Width := 165;
    end;
    with gridView.Columns.Add do
    begin
      ElementClassName := 'text-wrap';
      DataField := 'Stranka.Naziv';
      Title := 'Stranka';
      Width := 245;
    end;
    with gridView.Columns.Add do
    begin
      ElementClassName := 'text-wrap';
      DataField := 'LokacijaOpis';
      Title := 'Lokacija';
      Width := 280;
    end;
    gridView.DataSource := dsNalogi;
    gridView.ElementFont := efCSS;
    gridView.ElementClassName := 'fs-2';
    gridView.FixedFont.Charset := DEFAULT_CHARSET;
    gridView.FixedFont.Color := clWindowText;
    gridView.FixedFont.Height := -12;
    gridView.FixedFont.Name := 'Segoe UI';
    gridView.FixedFont.Style := [];
    gridView.FixedCols := 0;
    gridView.FixedRows := 0;
    gridView.Font.Charset := DEFAULT_CHARSET;
    gridView.Font.Color := clWindowText;
    gridView.Font.Height := -48;
    gridView.Font.Name := 'Tahoma';
    gridView.Font.Style := [];
    gridView.Options := [goHorzLine,goRowSelect];
    gridView.ParentFont := False;
    gridView.TabOrder := 0;
    gridView.HeightPercent := 100.000000000000000000;
    gridView.ShowIndicator := False;
    gridView.WidthPercent := 100.000000000000000000;
    SetEvent(gridView, Self, 'OnDblClick', 'tblStrankeDblClick');
    SetEvent(gridView, Self, 'OnGetCellClass', 'gridViewGetCellClass');
    gridView.ColWidths[0] := 165;
    gridView.ColWidths[1] := 245;
    gridView.ColWidths[2] := 280;
    splitMap.SetParentComponent(Self);
    splitMap.Name := 'splitMap';
    splitMap.Left := 0;
    splitMap.Top := 740;
    splitMap.Width := 847;
    splitMap.Height := 5;
    splitMap.Align := alBottom;
    splitMap.ChildOrder := 4;
    splitMap.Color := clBtnFace;
    tmrFind.SetParentComponent(Self);
    tmrFind.Name := 'tmrFind';
    tmrFind.Enabled := False;
    tmrFind.Interval := 500;
    SetEvent(tmrFind, Self, 'OnTimer', 'tmrFindTimer');
    tmrFind.Left := 100;
    tmrFind.Top := 328;
    wdsNalogi.SetParentComponent(Self);
    wdsNalogi.Name := 'wdsNalogi';
    wdsNalogi.AfterOpen := wdsNalogiAfterOpen;
    wdsNalogi.AfterScroll := wdsNalogiAfterScroll;
    wdsNalogi.EntitySetName := 'PrevozniNalog';
    wdsNalogi.Connection := modMain.connServer;
    wdsNalogi.SubpropsDepth := 1;
    wdsNalogi.QueryString := 'filter=Aktivna%20ne%200&$orderby=Stranka/Naziv,PrevzemnoMesto&$expand=stranka';
    wdsNalogi.Left := 104;
    wdsNalogi.Top := 216;
    wdsNalogiId.SetParentComponent(wdsNalogi);
    wdsNalogiId.Name := 'wdsNalogiId';
    wdsNalogiId.FieldName := 'Id';
    wdsNalogiDatumDostave.SetParentComponent(wdsNalogi);
    wdsNalogiDatumDostave.Name := 'wdsNalogiDatumDostave';
    wdsNalogiDatumDostave.FieldName := 'DatumDostave';
    wdsNalogiDatumDostave.DisplayFormat := 'dd.mm.yyyy';
    wdsNalogiStrankaNaziv.SetParentComponent(wdsNalogi);
    wdsNalogiStrankaNaziv.Name := 'wdsNalogiStrankaNaziv';
    wdsNalogiStrankaNaziv.FieldName := 'Stranka.Naziv';
    wdsNalogiStrankaNaziv.Size := 50;
    wdsNalogiLokacijaOpis.SetParentComponent(wdsNalogi);
    wdsNalogiLokacijaOpis.Name := 'wdsNalogiLokacijaOpis';
    wdsNalogiLokacijaOpis.FieldName := 'LokacijaOpis';
    wdsNalogiLokacijaOpis.Size := 50;
    wdsNalogiTipNalogaId.SetParentComponent(wdsNalogi);
    wdsNalogiTipNalogaId.Name := 'wdsNalogiTipNalogaId';
    wdsNalogiTipNalogaId.FieldName := 'TipNaloga.Id';
    dsNalogi.SetParentComponent(Self);
    dsNalogi.Name := 'dsNalogi';
    dsNalogi.DataSet := wdsNalogi;
    dsNalogi.Left := 104;
    dsNalogi.Top := 272;
  finally
    panHeader.AfterLoadDFMValues;
    lblIsci.AfterLoadDFMValues;
    edtFilter.AfterLoadDFMValues;
    btnDelNote.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnMove.AfterLoadDFMValues;
    gridView.AfterLoadDFMValues;
    splitMap.AfterLoadDFMValues;
    tmrFind.AfterLoadDFMValues;
    wdsNalogi.AfterLoadDFMValues;
    wdsNalogiId.AfterLoadDFMValues;
    wdsNalogiDatumDostave.AfterLoadDFMValues;
    wdsNalogiStrankaNaziv.AfterLoadDFMValues;
    wdsNalogiLokacijaOpis.AfterLoadDFMValues;
    wdsNalogiTipNalogaId.AfterLoadDFMValues;
    dsNalogi.AfterLoadDFMValues;
  end;
end;

end.                                                                                                                                                                                                                                                          