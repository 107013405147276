program TerminalCnt;



uses
  Vcl.Forms,
  WEBLib.Forms,
  Auth.Service in 'Auth.Service.pas',
  Client.Settings in 'Client.Settings.pas' {*.html},
  Client.Core in 'Client.Core.pas',
  Client.Constants in 'Client.Constants.pas',
  Main in 'Main.pas'{*.html},
  Login in 'Frames\Login.pas'{*.html},
  Nalog in 'Frames\Nalog.pas'{*.html},
  Nalogi in 'Frames\Nalogi.pas'{*.html},
  Vozilo in 'Frames\Vozilo.pas'{*.html},
  VoziloMenjava in 'Frames\VoziloMenjava.pas'{*.html},
  Datum in 'Frames\Datum.pas'{*.html},
  Settings in 'Frames\Settings.pas'{*.html},
  Zemljevid in 'Frames\Zemljevid.pas'{*.html},
  KontejnerPredaja in 'Frames\KontejnerPredaja.pas'{*.html},
  Kilometri in 'Frames\Kilometri.pas'{*.html},
  Teza in 'Frames\Teza.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TmodMain, modMain);
  Application.CreateForm(TfrmMain, frmMain);
  Application.Run;
end.
