unit Client.Core;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, XData.Web.Connection,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client, DateUtils,
  WEBLib.Dialogs, WEBLib.Forms, Auth.Service, WEBLib.Cookies, WEBLIB.Storage, WEBLib.ExtCtrls;

type
  TmodMain = class(TDataModule)
    connServer: TXDataWebConnection;
    wdsVozila: TXDataWebDataSet;
    wdsVozniki: TXDataWebDataSet;
    wdsFrakcije: TXDataWebDataSet;
    wdsFrakcijeId: TIntegerField;
    wdsFrakcijeOpis: TStringField;
    wdsFrakcijeAktivno: TIntegerField;
    wdsVoznikiId: TIntegerField;
    wdsVoznikiUporabniskoIme: TStringField;
    wdsVoznikiGeslo: TStringField;
    wdsVoznikiPIN: TStringField;
    wdsVoznikiIme: TStringField;
    wdsVoznikiPriimek: TStringField;
    wdsVoznikiAktiven: TIntegerField;
    wdsPoti: TXDataWebDataSet;
    wdsLokacije: TXDataWebDataSet;
    wdsPredaja: TXDataWebDataSet;
    wdsDnevi: TXDataWebDataSet;
    cliMain: TXDataWebClient;
    tmrPoz: TTimer;
    geoLoc: TGeoLocation;
    procedure WebDataModuleCreate(Sender: TObject);
    procedure wdsVozilaAfterOpen(DataSet: TDataSet);
    procedure wdsVoznikiAfterOpen(DataSet: TDataSet);
    procedure wdsFrakcijeAfterOpen(DataSet: TDataSet);
    procedure wdsLokacijeAfterOpen(DataSet: TDataSet);
    procedure wdsPredajaAfterOpen(DataSet: TDataSet);
    procedure wdsDneviAfterOpen(DataSet: TDataSet);
    procedure connServerRequest(Args: TXDataWebConnectionRequest);
    procedure tmrPozTimer(Sender: TObject);
    procedure geoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
    procedure connServerConnect(Sender: TObject);

    procedure ZazeniSledenje;
    procedure UstaviSledenje;
    procedure AzurirajPozicijo;
    procedure SporociLokacijo(AVoziloId: integer; Lat, Lon: double);

    procedure connServerError(Error: TXDataWebConnectionError);
    procedure geoLocGeolocationError(Sender: TObject; ACode: Integer; AMessage: string);
    procedure cliMainError(Error: TXDataClientError);
    procedure cliMainRequest(Request: TXDataClientRequest);
  private
    { Private declarations }
  public
    { Public declarations }

    StrankaId: integer;
    Stranka: TObject;

    LokacijaId: integer;

    VoziloId: integer;
    Vozilo: TObject;
    VoziloStanjeKm: integer;
    VoziloMenjavaId: integer;
    VoziloRegSt: string;

    VoznikId: integer;
    FrakcijaId: integer;
    NalogId: integer;
    TipNalogaId: integer;

    NalogLastRow: integer;
//    OdvozLastRow: integer;

    IzbranDan: integer;
    IzbranDatum: TDate;
    IzbranDanIme: string;

    LokacijaLat: double;
    LokacijaLon: double;

    AuthService: TAuthService;

    procedure NastaviVoznika(Pin: string);
    procedure NastaviVozilo(Id: integer);
    procedure NastaviVoziloPeedaja(Id: integer);
    procedure NastaviDatum(ADatum: TDateTime);
    procedure NastaviFrakcijo(Id: integer);
    procedure NastaviLokacijo(Id: integer);
    procedure NastaviNalog(ANalogId: integer);
    procedure NastaviTipNalog(TipNalogId: integer);

    procedure PrenesiNalog(NalogId, VoziloId: integer);
    procedure NastaviStevecKm(VoziloId, StevecKm: integer);

    procedure NastaviVoznikDostava(NalogPodrobnoId, VoznikId: integer);
    procedure NastaviVoznikOdvoz(NalogPodrobnoId, VoznikId: integer);
    procedure PosljiZahtevekTehtanje(NalogPodrobnoId: integer);

    procedure TehtajCnt(NalogPodrobnoId: integer);
    procedure VpisiTezoCnt(NalogPodrobnoId, TezaKg: integer);
    procedure RazloziCnt(NalogPodrobnoId: integer);
    [async]
    procedure NaloziCnt(NalogPodrobnoId: integer); async;

    function GetVar(TokenName: string): string;
    procedure SetVar(TokenName, Value: string);

    function VrniDanVTednu(Datum: TDateTime): integer;
    procedure Log(Txt: string);
  protected procedure LoadDFMValues; override; end;

var
  modMain: TmodMain;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

uses Client.Settings, Main;

{$R *.dfm}

procedure TmodMain.WebDataModuleCreate(Sender: TObject);
begin
  AppSettings := TSettings.Create;

  AuthService := TAuthService.Create(connServer);

  IzbranDan := VrniDanVTednu(Today);

  connServer.URL := AppSettings.ServerURL;
  connServer.Connected := true;

  // JTW avtentikacija
//  AuthToken := Client.Service<IKamijoncinService>.Login('kamijoncin', 'nSOp06TES2d0pXdck7PpvUKxPB1VgD2NGlZiEtLI');
end;

procedure TmodMain.ZazeniSledenje;
begin
//  Log('Starting position timer ('+AppSettings.LocationInterval.ToString+' s) ...');
//  tmrPoz.Interval := 1000 * AppSettings.LocationInterval;
//  tmrPoz.Enabled := true;
end;

procedure TmodMain.AzurirajPozicijo;
begin
  Log('Refreshing location...');
  tmrPozTimer(nil);
end;

procedure TmodMain.cliMainError(Error: TXDataClientError);
begin
  Log(Error.ErrorCode+': '+Error.ErrorMessage);
end;

procedure TmodMain.cliMainRequest(Request: TXDataClientRequest);
begin
  Log(Request.Request.Uri);
end;

procedure TmodMain.connServerConnect(Sender: TObject);

  procedure LoginSuccess;
  begin
    //FLoginProc;
  end;

  procedure LoginError(AMsg: string);
  begin
    //ShowNotification('Login Error: ' + AMsg);
  end;

begin
(*  AuthService.Login(
    'kamijoncin', 'nSOp06TES2d0pXdck7PpvUKxPB1VgD2NGlZiEtLI',
    @LoginSuccess,
    @LoginError
  ); *)
end;

procedure TmodMain.connServerError(Error: TXDataWebConnectionError);
begin
  Log(Error.ErrorMessage);
end;

procedure TmodMain.connServerRequest(Args: TXDataWebConnectionRequest);
begin
  if AuthService.Authenticated then
    Args.Request.Headers.SetValue('Authorization', 'Bearer ' + AuthService.GetToken);
end;

procedure TmodMain.NastaviVoznika(Pin: string);
begin
  VoznikId := 0;
  wdsVozniki.Close;
  wdsVozniki.QueryString := '$filter=Pin eq '''+Pin+'''';
  wdsVozniki.Load;
end;

procedure TmodMain.PosljiZahtevekTehtanje(NalogPodrobnoId: integer);
begin
  try
    Log('Executing PosljiZahtevekTehtanje: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    cliMain.RawInvoke('IKamijoncinService.PosljiZahtevekTehtanje', [NalogPodrobnoId]);
  except
     // Pač, ni ratalo.. :)
     on E:Exception do begin
       Log('PosljiZahtevekTehtanje error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.PrenesiNalog(NalogId, VoziloId: integer);
begin
  try
    Log('Executing PrenesiNalog: NalogId: '+ NalogId.ToString +', VoziloId: '+VoziloId.ToString);
    cliMain.RawInvoke('IKamijoncinService.PrenesiNalog', [NalogId, VoziloId]);
  except
     // Pač, ni ratalo.. :)
     on E:Exception do begin
       Log('PrenesiNalog error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.NastaviStevecKm(VoziloId, StevecKm: integer);
begin
  try
    Log('Executing NastaviStevecKm: StevecKm: '+ StevecKm.ToString +', VoziloId: '+VoziloId.ToString);
    cliMain.RawInvoke('IKamijoncinService.NastaviKm', [VoziloId, StevecKm]);
  except
     // Pač, ni ratalo.. :)
     on E:Exception do begin
       Log('NastaviStevecKm error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.wdsVoznikiAfterOpen(DataSet: TDataSet);
begin
  if DataSet.RecordCount>0 then begin
    VoznikId := DataSet.FieldByName('Id').AsInteger;
    //Voznik :=  TObject(TXDataWebDataSet(DataSet).CurrentData);
    frmMain.SetVoznikLabel(DataSet.FieldByName('Ime').AsString+' '+DataSet.FieldByName('Priimek').AsString);
  end else begin
    Log('Voznik: Iskanje ni uspelo.');
  end;
end;

procedure TmodMain.NastaviVozilo(Id: integer);
begin
  wdsVozila.Close;
  wdsVozila.QueryString := '$filter=Id eq '''+Id.ToString+'''&$expand=tipvozila,vrstaodvoza';
  wdsVozila.Load;
end;

procedure TmodMain.wdsVozilaAfterOpen(DataSet: TDataSet);
begin
  if DataSet.RecordCount>0 then begin
    VoziloId := DataSet.FieldByName('Id').AsInteger;
    //Vozilo :=  TObject(TXDataWebDataSet(DataSet).CurrentData);

    Log('VoziloId='+VoziloId.ToString);
    //Log(TJSJSON.stringify(TJSObject(Vozilo)));

    SetVar('VoziloId', VoziloId.ToString);
    VoziloStanjeKm := DataSet.FieldByName('StanjeKm').AsInteger;
    VoziloRegSt := DataSet.FieldByName('RegistrskaSt').AsString;

    frmMain.SetVoziloLabel(DataSet.FieldByName('RegistrskaSt').AsString);
  end else begin
    Log('VoziloId: Iskanje ni uspelo za ID='+VoziloId.ToString);
  end;
end;

procedure TmodMain.NastaviDatum(ADatum: TDateTime);
begin
  IzbranDatum := ADatum;
  Log('IzbranDatum='+DateToStr(IzbranDatum));
  IzbranDan := VrniDanVTednu(ADatum); // Pozor, začne z nedeljo
  Log('IzbranDan='+IntToStr(IzbranDan));

  wdsDnevi.Close;
  wdsDnevi.QueryString := '$filter=Id eq '''+IzbranDan.ToString+'''';
  wdsDnevi.Load;
end;

(*procedure TmodMain.NastaviDatumZgodovina(ADatum: TDateTime);
begin
  ZgodovinaDatum := ADatum;
  Log('ZgodovinaDan='+DateToStr(ZgodovinaDatum));
//  IzbranDan := VrniDanVTednu(ADatum); // Pozor, začne z nedeljo
//  Log('ZgodovinaDan='+IntToStr(IzbranDan));
end; *)

procedure TmodMain.wdsDneviAfterOpen(DataSet: TDataSet);
begin
  IzbranDanIme := DataSet.FieldByName('Ime').AsString;
  Log('IzbranDanIme='+IzbranDanIme);
  frmMain.SetDanLabel(IzbranDanIme, IzbranDatum);
end;

procedure TmodMain.NastaviFrakcijo(Id: integer);
begin
  FrakcijaId := Id;
  SetVar('FrakcijaId', FrakcijaId.ToString);
  wdsFrakcije.Close;
  wdsFrakcije.QueryString := '$filter=Id eq '''+Id.ToString+'''';
  wdsFrakcije.Load;
end;

procedure TmodMain.wdsFrakcijeAfterOpen(DataSet: TDataSet);
begin
  if DataSet.RecordCount>0 then begin
    FrakcijaId := DataSet.FieldByName('Id').AsInteger;
    //Frakcija := TObject(TXDataWebDataSet(DataSet).CurrentData);
//    frmMain.SetFrakcijaLabel(DataSet.FieldByName('Opis').AsString);
  end else begin
    Log('FrakcijaId: Iskanje ni uspelo za ID='+FrakcijaId.ToString);
  end;
end;

procedure TmodMain.NastaviLokacijo(Id: integer);
begin
  LokacijaId := Id;
  wdsLokacije.Close;
  wdsLokacije.QueryString := '$filter=Id eq '''+Id.ToString+'''&$expand=stranka';
  wdsLokacije.Load;
end;

procedure TmodMain.NastaviVoziloPeedaja(Id: integer);
begin
  wdsPredaja.Close;
  wdsPredaja.QueryString := '$filter=Id eq '+Id.ToString;
  wdsPredaja.Load;
end;

procedure TmodMain.wdsLokacijeAfterOpen(DataSet: TDataSet);
begin
  if DataSet.RecordCount>0 then begin
    LokacijaId := DataSet.FieldByName('Id').AsInteger;
    //Lokacija := TObject(TXDataWebDataSet(DataSet).CurrentData);
    StrankaId := DataSet.FieldByName('Stranka.Id').AsInteger;
  end else begin
    Log('LokacijaId: Iskanje ni uspelo za ID='+LokacijaId.ToString);
  end;
end;

procedure TmodMain.wdsPredajaAfterOpen(DataSet: TDataSet);
begin
  if DataSet.RecordCount>0 then begin
    VoziloMenjavaId := DataSet.FieldByName('Id').AsInteger;
    //VoziloMenjava := TObject(TXDataWebDataSet(DataSet).CurrentData);
    frmMain.lblVozilo.Caption := frmMain.lblVozilo.Caption + '*';
  end else begin
    Log('VoziloMenjavaId: Iskanje ni uspelo za ID='+VoziloId.ToString);
  end;
end;

procedure TmodMain.NastaviNalog(ANalogId: integer);
begin
  NalogId := ANalogId;
  Log('NalogId:'+NalogId.ToString);
end;

procedure TmodMain.NastaviTipNalog(TipNalogId: integer);
begin
  TipNalogaId := TipNalogId;
  Log('TipNalogaId:'+TipNalogId.ToString);
end;

procedure TmodMain.geoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
begin
//  Log('Setting location...');

  self.LokacijaLat := Lat;
  self.LokacijaLon := Lon;

  frmMain.ProcessLokacija;

  if VoziloId>0 then
    SporociLokacijo(VoziloId, Lat, Lon);
end;

procedure TmodMain.geoLocGeolocationError(Sender: TObject; ACode: Integer; AMessage: string);
begin
  Log('(geoLocGeolocationError) '+ACode.ToString+' '+AMessage);
end;

function TmodMain.GetVar(TokenName: string): string;
var
  ls: TLocalStorage;
begin
  Result := '';
  ls := TLocalStorage.Create(self);
  try
    Result := ls.GetValue(TokenName);
  finally
    ls.free;
  end;
end;

procedure TmodMain.Log(Txt: string);
begin
  if AppSettings.DevMode then
    Console.Log(Txt);
end;

procedure TmodMain.SetVar(TokenName, Value: string);
var
  ls: TLocalStorage;
begin
//  ClearMyToken; // clear it first - not strictly necessary
  ls := TLocalStorage.Create(self);
  try
    ls.SetValue(TokenName, Value);
  finally
    ls.free;
  end;
end;

procedure TmodMain.SporociLokacijo(AVoziloId: integer; Lat, Lon: double);
begin
  exit; // -> začasno, da ne smeti po konzoli...
  try
    Log('Executing SporociLokacijo');
    cliMain.RawInvoke('IKamijoncinService.JaviPozicijo', [VoziloId, Lat, Lon]);
  except
     // Pač, ni ratalo.. :)
     on E:Exception do begin
       Log('SporociLokacijo error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.NastaviVoznikDostava(NalogPodrobnoId, VoznikId: integer);
begin
  try
    Log('Executing TehtajCnt: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    cliMain.RawInvoke('IKamijoncinService.NastaviVoznikDostava', [NalogPodrobnoId, VoznikId]);
  except
     // Pač, ni ratalo.. :)
     on E:Exception do begin
       Log('TehtajCnt error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.NastaviVoznikOdvoz(NalogPodrobnoId, VoznikId: integer);
begin
  try
    Log('Executing TehtajCnt: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    cliMain.RawInvoke('IKamijoncinService.NastaviVoznikOdvoz', [NalogPodrobnoId, VoznikId]);
  except
     // Pač, ni ratalo.. :)
     on E:Exception do begin
       Log('TehtajCnt error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.VpisiTezoCnt(NalogPodrobnoId, TezaKg: integer);
begin
  try
    Log('Executing VpisoTezoCnt: NalogPodrobnoId: '+ NalogPodrobnoId.ToString+', TezaKg: '+TezaKg.ToString);
    cliMain.RawInvoke('IKamijoncinService.VpisoTezoCnt', [NalogPodrobnoId, TezaKg]);
  except
     // Pač, ni ratalo.. :)
     on E:Exception do begin
       Log('VpisoTezoCnt error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.RazloziCnt(NalogPodrobnoId: integer);
begin
  try
    Log('Executing RazloziCnt: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    cliMain.RawInvoke('IKamijoncinService.RazloziCnt', [NalogPodrobnoId]);
  except
     // Pač, ni ratalo.. :)
     on E:Exception do begin
       Log('RazloziCnt error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.NaloziCnt(NalogPodrobnoId: integer);
begin
  try
    Log('Executing NaloziCnt: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    await(cliMain.RawInvoke('IKamijoncinService.NaloziCnt', [NalogPodrobnoId]));
  except
     // Pač, ni ratalo.. :)
     on E:Exception do begin
       Log('NaloziCnt error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.TehtajCnt(NalogPodrobnoId: integer);
begin
  try
    Log('Executing TehtajCnt: NalogPodrobnoId: '+ NalogPodrobnoId.ToString);
    cliMain.RawInvoke('IKamijoncinService.TehtajCnt', [NalogPodrobnoId]);
  except
     // Pač, ni ratalo.. :)
     on E:Exception do begin
       Log('TehtajCnt error: '+E.Message);
     end;
  end;
end;

procedure TmodMain.tmrPozTimer(Sender: TObject);
begin
//  modMain.log('Refreshing geoloction...');
  geoLoc.GetGeolocation;
end;

procedure TmodMain.UstaviSledenje;
begin
  Log('Stopping position timer...');
  tmrPoz.Enabled := false;
end;

function TmodMain.VrniDanVTednu(Datum: TDateTime): integer;
begin
//  Result := ((DayOfTheWeek(Datum)) mod 7) + 1;
  Result := DayOfTheWeek(Datum) + 1;

  if Result > 7 then
    Result := 1;

  Log('DayOfTheWeek(Datum)='+DayOfTheWeek(Datum).ToString);
  Log('VrniDanVTednu, Result='+Result.ToString);
end;

procedure TmodMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  connServer := TXDataWebConnection.Create(Self);
  wdsVozila := TXDataWebDataSet.Create(Self);
  wdsVozniki := TXDataWebDataSet.Create(Self);
  wdsVoznikiId := TIntegerField.Create(Self);
  wdsVoznikiUporabniskoIme := TStringField.Create(Self);
  wdsVoznikiGeslo := TStringField.Create(Self);
  wdsVoznikiPIN := TStringField.Create(Self);
  wdsVoznikiIme := TStringField.Create(Self);
  wdsVoznikiPriimek := TStringField.Create(Self);
  wdsVoznikiAktiven := TIntegerField.Create(Self);
  wdsFrakcije := TXDataWebDataSet.Create(Self);
  wdsFrakcijeId := TIntegerField.Create(Self);
  wdsFrakcijeOpis := TStringField.Create(Self);
  wdsFrakcijeAktivno := TIntegerField.Create(Self);
  wdsPoti := TXDataWebDataSet.Create(Self);
  wdsLokacije := TXDataWebDataSet.Create(Self);
  wdsPredaja := TXDataWebDataSet.Create(Self);
  wdsDnevi := TXDataWebDataSet.Create(Self);
  cliMain := TXDataWebClient.Create(Self);
  tmrPoz := TTimer.Create(Self);
  geoLoc := TGeoLocation.Create(Self);

  connServer.BeforeLoadDFMValues;
  wdsVozila.BeforeLoadDFMValues;
  wdsVozniki.BeforeLoadDFMValues;
  wdsVoznikiId.BeforeLoadDFMValues;
  wdsVoznikiUporabniskoIme.BeforeLoadDFMValues;
  wdsVoznikiGeslo.BeforeLoadDFMValues;
  wdsVoznikiPIN.BeforeLoadDFMValues;
  wdsVoznikiIme.BeforeLoadDFMValues;
  wdsVoznikiPriimek.BeforeLoadDFMValues;
  wdsVoznikiAktiven.BeforeLoadDFMValues;
  wdsFrakcije.BeforeLoadDFMValues;
  wdsFrakcijeId.BeforeLoadDFMValues;
  wdsFrakcijeOpis.BeforeLoadDFMValues;
  wdsFrakcijeAktivno.BeforeLoadDFMValues;
  wdsPoti.BeforeLoadDFMValues;
  wdsLokacije.BeforeLoadDFMValues;
  wdsPredaja.BeforeLoadDFMValues;
  wdsDnevi.BeforeLoadDFMValues;
  cliMain.BeforeLoadDFMValues;
  tmrPoz.BeforeLoadDFMValues;
  geoLoc.BeforeLoadDFMValues;
  try
    Name := 'modMain';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    Height := 423;
    Width := 594;
    connServer.SetParentComponent(Self);
    connServer.Name := 'connServer';
    connServer.URL := 'http://192.168.66.14:2002/kamijoncin';
    SetEvent(connServer, Self, 'OnConnect', 'connServerConnect');
    SetEvent(connServer, Self, 'OnError', 'connServerError');
    SetEvent(connServer, Self, 'OnRequest', 'connServerRequest');
    connServer.Left := 32;
    connServer.Top := 8;
    wdsVozila.SetParentComponent(Self);
    wdsVozila.Name := 'wdsVozila';
    wdsVozila.AfterOpen := wdsVozilaAfterOpen;
    wdsVozila.EntitySetName := 'Vozilo';
    wdsVozila.Connection := connServer;
    wdsVozila.SubpropsDepth := 1;
    wdsVozila.Left := 194;
    wdsVozila.Top := 64;
    wdsVozniki.SetParentComponent(Self);
    wdsVozniki.Name := 'wdsVozniki';
    wdsVozniki.AfterOpen := wdsVoznikiAfterOpen;
    wdsVozniki.EntitySetName := 'Uporabnik';
    wdsVozniki.Connection := connServer;
    wdsVozniki.QueryTop := 1;
    wdsVozniki.Left := 114;
    wdsVozniki.Top := 8;
    wdsVoznikiId.SetParentComponent(wdsVozniki);
    wdsVoznikiId.Name := 'wdsVoznikiId';
    wdsVoznikiId.FieldName := 'Id';
    wdsVoznikiId.Required := True;
    wdsVoznikiUporabniskoIme.SetParentComponent(wdsVozniki);
    wdsVoznikiUporabniskoIme.Name := 'wdsVoznikiUporabniskoIme';
    wdsVoznikiUporabniskoIme.FieldName := 'UporabniskoIme';
    wdsVoznikiUporabniskoIme.Required := True;
    wdsVoznikiUporabniskoIme.Size := 50;
    wdsVoznikiGeslo.SetParentComponent(wdsVozniki);
    wdsVoznikiGeslo.Name := 'wdsVoznikiGeslo';
    wdsVoznikiGeslo.FieldName := 'Geslo';
    wdsVoznikiGeslo.Required := True;
    wdsVoznikiGeslo.Size := 50;
    wdsVoznikiPIN.SetParentComponent(wdsVozniki);
    wdsVoznikiPIN.Name := 'wdsVoznikiPIN';
    wdsVoznikiPIN.FieldName := 'PIN';
    wdsVoznikiPIN.Size := 10;
    wdsVoznikiIme.SetParentComponent(wdsVozniki);
    wdsVoznikiIme.Name := 'wdsVoznikiIme';
    wdsVoznikiIme.FieldName := 'Ime';
    wdsVoznikiIme.Required := True;
    wdsVoznikiIme.Size := 50;
    wdsVoznikiPriimek.SetParentComponent(wdsVozniki);
    wdsVoznikiPriimek.Name := 'wdsVoznikiPriimek';
    wdsVoznikiPriimek.FieldName := 'Priimek';
    wdsVoznikiPriimek.Required := True;
    wdsVoznikiPriimek.Size := 50;
    wdsVoznikiAktiven.SetParentComponent(wdsVozniki);
    wdsVoznikiAktiven.Name := 'wdsVoznikiAktiven';
    wdsVoznikiAktiven.FieldName := 'Aktiven';
    wdsVoznikiAktiven.Required := True;
    wdsFrakcije.SetParentComponent(Self);
    wdsFrakcije.Name := 'wdsFrakcije';
    wdsFrakcije.AfterOpen := wdsFrakcijeAfterOpen;
    wdsFrakcije.EntitySetName := 'Frakcija';
    wdsFrakcije.Connection := connServer;
    wdsFrakcije.Left := 112;
    wdsFrakcije.Top := 64;
    wdsFrakcijeId.SetParentComponent(wdsFrakcije);
    wdsFrakcijeId.Name := 'wdsFrakcijeId';
    wdsFrakcijeId.FieldName := 'Id';
    wdsFrakcijeId.Required := True;
    wdsFrakcijeOpis.SetParentComponent(wdsFrakcije);
    wdsFrakcijeOpis.Name := 'wdsFrakcijeOpis';
    wdsFrakcijeOpis.FieldName := 'Opis';
    wdsFrakcijeOpis.Required := True;
    wdsFrakcijeOpis.Size := 50;
    wdsFrakcijeAktivno.SetParentComponent(wdsFrakcije);
    wdsFrakcijeAktivno.Name := 'wdsFrakcijeAktivno';
    wdsFrakcijeAktivno.FieldName := 'Aktivno';
    wdsFrakcijeAktivno.Required := True;
    wdsPoti.SetParentComponent(Self);
    wdsPoti.Name := 'wdsPoti';
    wdsPoti.EntitySetName := 'Pot';
    wdsPoti.Connection := connServer;
    wdsPoti.Left := 264;
    wdsPoti.Top := 8;
    wdsLokacije.SetParentComponent(Self);
    wdsLokacije.Name := 'wdsLokacije';
    wdsLokacije.AfterOpen := wdsLokacijeAfterOpen;
    wdsLokacije.EntitySetName := 'Lokacija';
    wdsLokacije.Connection := connServer;
    wdsLokacije.SubpropsDepth := 1;
    wdsLokacije.Left := 192;
    wdsLokacije.Top := 8;
    wdsPredaja.SetParentComponent(Self);
    wdsPredaja.Name := 'wdsPredaja';
    wdsPredaja.AfterOpen := wdsPredajaAfterOpen;
    wdsPredaja.EntitySetName := 'Vozilo';
    wdsPredaja.Connection := connServer;
    wdsPredaja.SubpropsDepth := 1;
    wdsPredaja.Left := 266;
    wdsPredaja.Top := 64;
    wdsDnevi.SetParentComponent(Self);
    wdsDnevi.Name := 'wdsDnevi';
    wdsDnevi.AfterOpen := wdsDneviAfterOpen;
    wdsDnevi.EntitySetName := 'Dan';
    wdsDnevi.Connection := connServer;
    wdsDnevi.SubpropsDepth := 1;
    wdsDnevi.Left := 346;
    wdsDnevi.Top := 8;
    cliMain.SetParentComponent(Self);
    cliMain.Name := 'cliMain';
    cliMain.Connection := connServer;
    SetEvent(cliMain, Self, 'OnError', 'cliMainError');
    SetEvent(cliMain, Self, 'OnRequest', 'cliMainRequest');
    cliMain.Left := 32;
    cliMain.Top := 62;
    tmrPoz.SetParentComponent(Self);
    tmrPoz.Name := 'tmrPoz';
    tmrPoz.Interval := 10000;
    SetEvent(tmrPoz, Self, 'OnTimer', 'tmrPozTimer');
    tmrPoz.Left := 32;
    tmrPoz.Top := 128;
    geoLoc.SetParentComponent(Self);
    geoLoc.Name := 'geoLoc';
    geoLoc.HighPrecision := True;
    geoLoc.TimeOut := 4000;
    SetEvent(geoLoc, Self, 'OnGeolocation', 'geoLocGeolocation');
    SetEvent(geoLoc, Self, 'OnGeolocationError', 'geoLocGeolocationError');
    geoLoc.Left := 32;
    geoLoc.Top := 183;
  finally
    connServer.AfterLoadDFMValues;
    wdsVozila.AfterLoadDFMValues;
    wdsVozniki.AfterLoadDFMValues;
    wdsVoznikiId.AfterLoadDFMValues;
    wdsVoznikiUporabniskoIme.AfterLoadDFMValues;
    wdsVoznikiGeslo.AfterLoadDFMValues;
    wdsVoznikiPIN.AfterLoadDFMValues;
    wdsVoznikiIme.AfterLoadDFMValues;
    wdsVoznikiPriimek.AfterLoadDFMValues;
    wdsVoznikiAktiven.AfterLoadDFMValues;
    wdsFrakcije.AfterLoadDFMValues;
    wdsFrakcijeId.AfterLoadDFMValues;
    wdsFrakcijeOpis.AfterLoadDFMValues;
    wdsFrakcijeAktivno.AfterLoadDFMValues;
    wdsPoti.AfterLoadDFMValues;
    wdsLokacije.AfterLoadDFMValues;
    wdsPredaja.AfterLoadDFMValues;
    wdsDnevi.AfterLoadDFMValues;
    cliMain.AfterLoadDFMValues;
    tmrPoz.AfterLoadDFMValues;
    geoLoc.AfterLoadDFMValues;
  end;
end;

end.
